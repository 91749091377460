import * as Apollo from 'apollo-angular';
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
};

export type ActivityCourse = {
  __typename?: 'ActivityCourse';
  _id: Scalars['String']['output'];
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  units: Array<ActivityUnit>;
};

export type ActivityLink = {
  __typename?: 'ActivityLink';
  _id: Scalars['ID']['output'];
  btnType: ButtonType;
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ActivityRating = {
  __typename?: 'ActivityRating';
  _id: Scalars['String']['output'];
  activityId?: Maybe<Scalars['String']['output']>;
  activityName: Scalars['String']['output'];
  course: Scalars['String']['output'];
  dateSubmitted: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  districtName: Scalars['String']['output'];
  easeOfUse: Scalars['Int']['output'];
  effectiveness: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gradeAppropriate: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  state: Scalars['String']['output'];
  studentEngagement: Scalars['Int']['output'];
  unit: Scalars['String']['output'];
};

export type ActivityUnit = {
  __typename?: 'ActivityUnit';
  _id: Scalars['String']['output'];
  activities: Array<StudentActivity>;
  code: Scalars['String']['output'];
  courses: Array<ActivityCourse>;
  inProgress: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pacingGuide: Scalars['String']['output'];
  stateStandards: Array<StateStandard>;
};

export type AnalyticsSnapshot = {
  __typename?: 'AnalyticsSnapshot';
  creationDate: Scalars['DateTime']['output'];
  lastLogin: Scalars['DateTime']['output'];
  numLogins: Scalars['Float']['output'];
};

export type Assignment = {
  __typename?: 'Assignment';
  _id: Scalars['ID']['output'];
  aId: Scalars['String']['output'];
  availableActions: Array<AssignmentAvailableAction>;
  awsLink: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  iconUrl: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isAutoGradable: Scalars['Boolean']['output'];
  isBrotliCompressed?: Maybe<Scalars['Boolean']['output']>;
  isStandardReview: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  questions: Array<AssignmentQuestion>;
  specialRequirement?: Maybe<AssignmentSpecialRequirement>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  type: AssignmentType;
  version: AssignmentVersion;
};

export type AssignmentAnswer = {
  __typename?: 'AssignmentAnswer';
  _id: Scalars['ID']['output'];
  answer: Scalars['JSON']['output'];
  answerType?: Maybe<AssignmentAnswerType>;
  grade: Scalars['Float']['output'];
  needsReview: Scalars['Boolean']['output'];
  question: AssignmentQuestion;
};

export enum AssignmentAnswerType {
  Coordinates = 'COORDINATES',
  MetaData = 'META_DATA',
  OrderedStringArray = 'ORDERED_STRING_ARRAY',
  String = 'STRING',
  StringArray = 'STRING_ARRAY',
  VideoProgress = 'VIDEO_PROGRESS',
}

export enum AssignmentAvailableAction {
  Progress = 'PROGRESS',
  Results = 'RESULTS',
  Resume = 'RESUME',
  Retake = 'RETAKE',
  Start = 'START',
  Submit = 'SUBMIT',
  View = 'VIEW',
}

export type AssignmentControls = {
  __typename?: 'AssignmentControls';
  allowLate: Scalars['Boolean']['output'];
  dueDate: Scalars['DateTime']['output'];
  maxAttempts: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type AssignmentControlsDto = {
  allowLate: Scalars['Boolean']['input'];
  dueDate: Scalars['DateTime']['input'];
  maxAttempts: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type AssignmentManager = {
  __typename?: 'AssignmentManager';
  _id: Scalars['ID']['output'];
  assignment: Assignment;
  classroom: Classroom;
  creationDate: Scalars['DateTime']['output'];
  defaults: AssignmentControls;
  isClosed: Scalars['Boolean']['output'];
  submissions: Array<SubmissionManager>;
};

export type AssignmentQuestion = {
  __typename?: 'AssignmentQuestion';
  acceptedAnswers: Scalars['JSON']['output'];
  answerType?: Maybe<AssignmentAnswerType>;
  number: Scalars['Int']['output'];
  possibleAnswerChoices?: Maybe<Array<Scalars['String']['output']>>;
  qId: Scalars['String']['output'];
  screenshotUrl: Scalars['String']['output'];
  section: AssignmentSection;
  standards: Array<AssignmentStandard>;
  text: Scalars['String']['output'];
  type: AssignmentQuestionType;
  weight: Scalars['Float']['output'];
};

export type AssignmentQuestionDto = {
  acceptedAnswers: Scalars['JSON']['input'];
  answerType?: InputMaybe<AssignmentAnswerType>;
  number: Scalars['Int']['input'];
  possibleAnswerChoices?: InputMaybe<Array<Scalars['String']['input']>>;
  qId: Scalars['String']['input'];
  screenshotUrl?: InputMaybe<Scalars['String']['input']>;
  section: AssignmentSectionDto;
  standards: Array<AssignmentStandardDto>;
  text: Scalars['String']['input'];
  type: AssignmentQuestionType;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export enum AssignmentQuestionType {
  Coordinates = 'COORDINATES',
  KeyPad = 'KEY_PAD',
  MetaData = 'META_DATA',
  Multiple = 'MULTIPLE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleDrop = 'MULTIPLE_DROP',
  ShortAnswer = 'SHORT_ANSWER',
  Single = 'SINGLE',
  SingleDrop = 'SINGLE_DROP',
  SingleSelect = 'SINGLE_SELECT',
  TrueOrFalse = 'TRUE_OR_FALSE',
  VideoWatched = 'VIDEO_WATCHED',
  WireMatching = 'WIRE_MATCHING',
}

export type AssignmentSection = {
  __typename?: 'AssignmentSection';
  name: Scalars['String']['output'];
  sId: Scalars['String']['output'];
  sectionType?: Maybe<AssignmentSectionType>;
  weight: Scalars['Float']['output'];
};

export type AssignmentSectionDto = {
  name: Scalars['String']['input'];
  sId: Scalars['String']['input'];
  sectionType?: InputMaybe<AssignmentSectionType>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export enum AssignmentSectionType {
  DefaultSection = 'DEFAULT_SECTION',
  WireMatchingGroup = 'WIRE_MATCHING_GROUP',
}

export enum AssignmentSpecialRequirement {
  StandardReview = 'STANDARD_REVIEW',
}

export type AssignmentStandard = {
  __typename?: 'AssignmentStandard';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type AssignmentStandardDto = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type AssignmentSubmission = {
  __typename?: 'AssignmentSubmission';
  _id: Scalars['ID']['output'];
  answers: Array<AssignmentAnswer>;
  assignment: Assignment;
  attempt: Scalars['Int']['output'];
  completionDate?: Maybe<Scalars['DateTime']['output']>;
  creationDate: Scalars['DateTime']['output'];
  grade: Scalars['Float']['output'];
  isComplete: Scalars['Boolean']['output'];
  needsReview: Scalars['Boolean']['output'];
  submissionManager: SubmissionManager;
};

export enum AssignmentType {
  Fillablepdf = 'FILLABLEPDF',
  Phaser = 'PHASER',
  Unity = 'UNITY',
  Video = 'VIDEO',
}

export type AssignmentVersion = {
  __typename?: 'AssignmentVersion';
  hotfix: Scalars['Int']['output'];
  major: Scalars['Int']['output'];
  minor: Scalars['Int']['output'];
  releaseDate: Scalars['DateTime']['output'];
};

export type AssignmentVersionDto = {
  hotfix: Scalars['Int']['input'];
  major: Scalars['Int']['input'];
  minor: Scalars['Int']['input'];
  releaseDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum ButtonType {
  Answer = 'ANSWER',
  Google = 'GOOGLE',
  GoogleAnswer = 'GOOGLE_ANSWER',
  Media = 'MEDIA',
  Microsoft = 'MICROSOFT',
  MicrosoftAnswer = 'MICROSOFT_ANSWER',
  Pdf = 'PDF',
  Print = 'PRINT',
  Youtube = 'YOUTUBE',
}

export type CalendarTimeWindow = {
  __typename?: 'CalendarTimeWindow';
  lengthOfTime: Scalars['Int']['output'];
  measurementOfTime: MeasurementOfTimeType;
};

export type Career = {
  __typename?: 'Career';
  careerKey: Scalars['String']['output'];
};

export type CharacterItem = {
  __typename?: 'CharacterItem';
  defaultData: Scalars['Float']['output'];
  gear: Scalars['String']['output'];
  selData: Scalars['Float']['output'];
};

export type CharacterUpgrade = {
  __typename?: 'CharacterUpgrade';
  defaultIndex: Scalars['Float']['output'];
  gear: Scalars['String']['output'];
  group: Scalars['String']['output'];
  index: Scalars['Float']['output'];
  max: Scalars['Float']['output'];
  upgradeKey: Scalars['String']['output'];
};

export type ClassLinkDistrictSemester = {
  __typename?: 'ClassLinkDistrictSemester';
  endDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['DateTime']['output']>;
  schoolYear?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ClassLinkGroup = {
  __typename?: 'ClassLinkGroup';
  accessToken?: Maybe<Scalars['String']['output']>;
  appId?: Maybe<Scalars['String']['output']>;
  lastSync?: Maybe<Scalars['DateTime']['output']>;
  sourcedId?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type ClassLinkSchool = {
  __typename?: 'ClassLinkSchool';
  dateLastModified?: Maybe<Scalars['DateTime']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sourcedId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Classroom = {
  __typename?: 'Classroom';
  _id: Scalars['String']['output'];
  classCode: Scalars['String']['output'];
  classLink?: Maybe<ClassroomClassLink>;
  clever?: Maybe<ClassroomClever>;
  color: ClassroomColor;
  creationDate: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  group: Group;
  invites: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  numStudents: Scalars['Int']['output'];
  numTeachers: Scalars['Int']['output'];
  period: Scalars['String']['output'];
  schoolDistrictSemester?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  students: Array<User>;
  subject: Scalars['String']['output'];
  teachers: Array<User>;
  users: Array<UserWithRole>;
  uuid: Scalars['String']['output'];
};

export type ClassroomAssignmentsModel = {
  __typename?: 'ClassroomAssignmentsModel';
  classroom: Classroom;
  queryExecutedAt: Scalars['DateTime']['output'];
  userAssignmentSummary: Array<UserAssignmentSummary>;
};

export type ClassroomClassLink = {
  __typename?: 'ClassroomClassLink';
  classCode?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  course?: Maybe<ClassroomClassLinkCourse>;
  grades?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['DateTime']['output']>;
  school?: Maybe<ClassroomClassLinkSchool>;
  status: Scalars['String']['output'];
  subjectCodes?: Maybe<Array<Scalars['String']['output']>>;
  terms?: Maybe<Array<ClassroomClassLinkTerm>>;
};

export type ClassroomClassLinkCourse = {
  __typename?: 'ClassroomClassLinkCourse';
  sourcedId?: Maybe<Scalars['String']['output']>;
};

export type ClassroomClassLinkSchool = {
  __typename?: 'ClassroomClassLinkSchool';
  sourcedId?: Maybe<Scalars['String']['output']>;
};

export type ClassroomClassLinkTerm = {
  __typename?: 'ClassroomClassLinkTerm';
  sourcedId?: Maybe<Scalars['String']['output']>;
};

export type ClassroomClever = {
  __typename?: 'ClassroomClever';
  created?: Maybe<Scalars['DateTime']['output']>;
  districtId?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  primaryTeacherId?: Maybe<Scalars['String']['output']>;
  sectionNumber?: Maybe<Scalars['String']['output']>;
  sisId?: Maybe<Scalars['String']['output']>;
  students?: Maybe<Array<Scalars['String']['output']>>;
  subject?: Maybe<Scalars['String']['output']>;
  termId?: Maybe<Scalars['String']['output']>;
};

export enum ClassroomColor {
  Blue = 'BLUE',
  Cyan = 'CYAN',
  DarkGreen = 'DARK_GREEN',
  Green = 'GREEN',
  Indigo = 'INDIGO',
  Lavender = 'LAVENDER',
  LightBlue = 'LIGHT_BLUE',
  LightGreen = 'LIGHT_GREEN',
  LightRed = 'LIGHT_RED',
  Maroon = 'MAROON',
  Midnight = 'MIDNIGHT',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Rust = 'RUST',
  Secondary = 'SECONDARY',
  Yellow = 'YELLOW',
}

export type CleverDistrictSemester = {
  __typename?: 'CleverDistrictSemester';
  endDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type CleverGroup = {
  __typename?: 'CleverGroup';
  admin?: Maybe<CleverGroupAdmin>;
  id?: Maybe<Scalars['String']['output']>;
  lastPlasmaGamesSync?: Maybe<Scalars['DateTime']['output']>;
  lastSync?: Maybe<Scalars['DateTime']['output']>;
  mdrNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ncesId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CleverGroupAdmin = {
  __typename?: 'CleverGroupAdmin';
  cleverId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CleverSchool = {
  __typename?: 'CleverSchool';
  id?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['DateTime']['output']>;
  mdrNumber?: Maybe<Scalars['String']['output']>;
  ncesId?: Maybe<Scalars['String']['output']>;
  schoolNumber?: Maybe<Scalars['String']['output']>;
  sisId?: Maybe<Scalars['String']['output']>;
  stateId?: Maybe<Scalars['String']['output']>;
};

export type CleverSchoolLocation = {
  __typename?: 'CleverSchoolLocation';
  city?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CleverSchoolPrincipal = {
  __typename?: 'CleverSchoolPrincipal';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ClickTracker = {
  __typename?: 'ClickTracker';
  _id: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  group: Group;
  url: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type CompletedToursDto = {
  completed: Scalars['Boolean']['input'];
  tour: Scalars['String']['input'];
};

export type ConnectionPageInfo = {
  __typename?: 'ConnectionPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  nextPageLink?: Maybe<PageLink>;
  pageLimit: Scalars['Float']['output'];
  prevPageLink?: Maybe<PageLink>;
};

export type CourseClever = {
  __typename?: 'CourseClever';
  district?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type CreateActivityCourseDto = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateActivityLinkDto = {
  btnType: ButtonType;
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateActivityRatingDto = {
  activityName: Scalars['String']['input'];
  course: Scalars['String']['input'];
  description?: Scalars['String']['input'];
  easeOfUse: Scalars['Float']['input'];
  effectiveness: Scalars['Float']['input'];
  gradeAppropriate: Scalars['Float']['input'];
  studentEngagement: Scalars['Float']['input'];
  unit: Scalars['String']['input'];
};

export type CreateActivityUnitDto = {
  code: Scalars['String']['input'];
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  pacingGuide?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAdminDto = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreateAssignmentDto = {
  aId: Scalars['String']['input'];
  awsLink: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  iconUrl: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  questions: Array<AssignmentQuestionDto>;
  specialRequirement?: InputMaybe<AssignmentSpecialRequirement>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type: AssignmentType;
  version: AssignmentVersionDto;
};

export type CreateAssignmentManagerDto = {
  assignment: Scalars['String']['input'];
  classroom: Scalars['String']['input'];
  defaults: AssignmentControlsDto;
};

export type CreateCalendarTimeWindowDto = {
  lengthOfTime: Scalars['Float']['input'];
  measurementOfTime: MeasurementOfTimeType;
};

export type CreateClassroomDto = {
  color?: InputMaybe<ClassroomColor>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  period: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subject: Scalars['String']['input'];
};

export type CreateClickTrackerDto = {
  groupId: Scalars['String']['input'];
  url: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateDistrictSemesterDto = {
  endDate: Scalars['DateTime']['input'];
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  postSurveyOn?: Scalars['Boolean']['input'];
  postSurveyWindow: CreateCalendarTimeWindowDto;
  startDate: Scalars['DateTime']['input'];
};

export type CreateGroupAddressDto = {
  county?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
};

export type CreateGroupDto = {
  domains: Array<Scalars['String']['input']>;
  hasSciOps: Scalars['Boolean']['input'];
  isDemo: Scalars['Boolean']['input'];
  isInPilot: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateMessageDto = {
  key: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type CreateOnlineActivityDto = {
  assignmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreatePortalNotificationDto = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  displayDate?: InputMaybe<Scalars['DateTime']['input']>;
  message: CreateMessageDto;
  sender: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateResourceStatDto = {
  activity: Scalars['String']['input'];
  course: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  teacherId: Scalars['String']['input'];
  unit: Scalars['String']['input'];
};

export type CreateStateStandardDto = {
  abbr: Scalars['String']['input'];
  name: Scalars['String']['input'];
  standards?: InputMaybe<Array<CreateUnitStandardDto>>;
};

export type CreateStudentActivityDto = {
  creationDate?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  estimatedTime?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  recommendationScore?: InputMaybe<Scalars['Float']['input']>;
  studentPortalAssetLink?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type CreateStudentsDto = {
  classCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
  usernameList: Array<Scalars['String']['input']>;
};

export type CreateSubStandardDto = {
  code?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
};

export type CreateSurveyDto = {
  name: Scalars['String']['input'];
  sections: Array<SurveySectionDto>;
  surveyId: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type CreateSurveyResultDto = {
  surveyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  versionNumber: Scalars['Float']['input'];
};

export type CreateUnitStandardDto = {
  code?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  subStandards?: InputMaybe<Array<CreateSubStandardDto>>;
};

export type CreateUserDto = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type DataExport = {
  __typename?: 'DataExport';
  avgStudentLogin: Scalars['Float']['output'];
  avgStudentLvl: Scalars['Float']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  numStudents: Scalars['Int']['output'];
  schoolDistrict: Scalars['String']['output'];
  state: Scalars['String']['output'];
  teacherLogins: Scalars['Int']['output'];
};

export type DistrictSemester = {
  __typename?: 'DistrictSemester';
  _id: Scalars['ID']['output'];
  classLink?: Maybe<ClassLinkDistrictSemester>;
  clever?: Maybe<CleverDistrictSemester>;
  endDate: Scalars['DateTime']['output'];
  isDefault: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  postSurveyOn: Scalars['Boolean']['output'];
  postSurveyWindow: CalendarTimeWindow;
  startDate: Scalars['DateTime']['output'];
};

export enum EduLvl {
  College = 'COLLEGE',
  Elementary = 'ELEMENTARY',
  High = 'HIGH',
  Middle = 'MIDDLE',
}

export enum EstimatedTime {
  FifteenToThirtyMin = 'FIFTEEN_TO_THIRTY_MIN',
  FiveToFifteenMin = 'FIVE_TO_FIFTEEN_MIN',
  FortyfiveToSixtyMin = 'FORTYFIVE_TO_SIXTY_MIN',
  SixtyPlusMin = 'SIXTY_PLUS_MIN',
  ThirtyToFortyfiveMin = 'THIRTY_TO_FORTYFIVE_MIN',
}

export type FindQueryParams = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  orderBy: Array<OrderBy>;
};

export type GetActivityCourseDto = {
  _id?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
};

export type GetActivityRatingsDto = {
  activityId?: InputMaybe<Scalars['String']['input']>;
  activityName?: InputMaybe<Scalars['String']['input']>;
  course?: InputMaybe<Scalars['String']['input']>;
  districtName?: InputMaybe<Scalars['String']['input']>;
  easeOfUse?: InputMaybe<Scalars['Float']['input']>;
  effectiveness?: InputMaybe<Scalars['Float']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gradeAppropriate?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  studentEngagement?: InputMaybe<Scalars['Float']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GetActivityUnitDto = {
  _id?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
};

export type GetAssignmentManagersDto = {
  /** The String value of the Assignment ID */
  assignment?: InputMaybe<Scalars['String']['input']>;
  /** The String value of the Classroom ID */
  classroom?: InputMaybe<Scalars['String']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetClassroomDto = {
  _id?: InputMaybe<Scalars['String']['input']>;
  classCode?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type GetClassroomsDto = {
  group?: InputMaybe<Scalars['String']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type GetClickTrackersDto = {
  group?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type GetGroupDto = {
  _id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type GetGroupsDto = {
  hasSciOps?: InputMaybe<Scalars['Boolean']['input']>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  isInPilot?: InputMaybe<Scalars['Boolean']['input']>;
  isSurveysOn?: InputMaybe<Scalars['Boolean']['input']>;
  isTeacherSignupOn?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type GetManyAssignmentSubmissionDto = {
  assignment?: InputMaybe<Scalars['String']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  needsReview?: InputMaybe<Scalars['Boolean']['input']>;
  submissionManager?: InputMaybe<Scalars['String']['input']>;
};

export type GetManyAssignmentsDto = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameRegex?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AssignmentType>;
};

export type GetManySubmissionManagersDto = {
  assignment?: InputMaybe<Scalars['String']['input']>;
  assignmentManager?: InputMaybe<Scalars['String']['input']>;
  isFinished?: InputMaybe<Scalars['Boolean']['input']>;
  isOverride?: InputMaybe<Scalars['Boolean']['input']>;
  needsReview?: InputMaybe<Scalars['Boolean']['input']>;
  /** User id */
  user?: InputMaybe<Scalars['String']['input']>;
};

export type GetPortalNotificationsDto = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  sender?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NotificationType>;
  /** User id */
  user?: InputMaybe<Scalars['String']['input']>;
};

export type GetResourcesStatsDto = {
  activity?: InputMaybe<Scalars['String']['input']>;
  course?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  teacher?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GetSaveDataDto = {
  _id?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type GetSingleAssignmentDto = {
  aId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GetStudentActivitiesDto = {
  isAdvanced?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GetStudentActivitiesSearchDto = {
  categories?: InputMaybe<Array<ResourceCategoryType>>;
  courseNameBoost?: InputMaybe<Scalars['String']['input']>;
  courses?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  reviewTypes?: InputMaybe<Array<ReviewResourceType>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<SkillConnectionTagType>>;
  units?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetSurveyByUserAndSurveyId = {
  surveyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type GetSurveyDataDto = {
  _id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  surveyId?: InputMaybe<Scalars['String']['input']>;
};

export type GetSurveyResultDto = {
  _id?: InputMaybe<Scalars['String']['input']>;
  userAndSurveyId?: InputMaybe<GetSurveyByUserAndSurveyId>;
};

export type GetSurveyResultsDto = {
  addrState?: InputMaybe<Scalars['String']['input']>;
  classroomId?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  teacherEmail?: InputMaybe<Scalars['String']['input']>;
};

export type GetTeacherTrainingDto = {
  _id?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserDto = {
  _id?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
};

export type GetUsersByNameDto = {
  /** This string becomes a RegExp */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** This string becomes a RegExp */
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type GetUsersDto = {
  /** This string becomes a RegExp */
  email?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<UserRole>;
};

export type GetWebStatsDto = {
  maxDateStr?: InputMaybe<Scalars['String']['input']>;
  minDateStr?: InputMaybe<Scalars['String']['input']>;
};

export type Group = {
  __typename?: 'Group';
  _id: Scalars['String']['output'];
  address: GroupAddress;
  classLink?: Maybe<ClassLinkGroup>;
  classrooms: Array<Classroom>;
  clever?: Maybe<CleverGroup>;
  creationDate: Scalars['DateTime']['output'];
  districtAdmins: Array<User>;
  districtSemesters: Array<DistrictSemester>;
  domains: Array<Scalars['String']['output']>;
  groupCodes: Array<NewGroupCode>;
  hasSciOps: Scalars['Boolean']['output'];
  inviteCodes: Array<NewGroupCode>;
  isDemo?: Maybe<Scalars['Boolean']['output']>;
  isInPilot: Scalars['Boolean']['output'];
  isSurveysOn: Scalars['Boolean']['output'];
  isTeacherSignupOn: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  numClassrooms: Scalars['Int']['output'];
  numDistrictAdmins: Scalars['Int']['output'];
  numStudents: Scalars['Int']['output'];
  numTeachers: Scalars['Int']['output'];
  numUsers: Scalars['Int']['output'];
  students: Array<User>;
  surveyInfo?: Maybe<GroupSurveyInvites>;
  teachers: Array<User>;
  users: Array<UserWithRole>;
  uuid: Scalars['String']['output'];
};

export type GroupAddress = {
  __typename?: 'GroupAddress';
  county?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export enum GroupCodeType {
  District = 'DISTRICT',
  Teacher = 'TEACHER',
}

export type GroupDataExport = {
  __typename?: 'GroupDataExport';
  avgStudentLvl: Scalars['Float']['output'];
  numStudents: Scalars['Int']['output'];
  numTeachers: Scalars['Int']['output'];
  schoolDistrict: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type GroupSurveyInvites = {
  __typename?: 'GroupSurveyInvites';
  highSchoolPostSurvey: Scalars['String']['output'];
  highSchoolPreSurvey: Scalars['String']['output'];
  middleSchoolPostSurvey: Scalars['String']['output'];
  middleSchoolPreSurvey: Scalars['String']['output'];
};

export type JobProcessingResult = {
  __typename?: 'JobProcessingResult';
  errorMessages: Array<Scalars['String']['output']>;
  numCreated: Scalars['Float']['output'];
  numFailed: Scalars['Float']['output'];
  numUpdated: Scalars['Float']['output'];
};

export type Level = {
  __typename?: 'Level';
  levelKey: Scalars['String']['output'];
  maxStars: Scalars['Int']['output'];
  numComplete: Scalars['Int']['output'];
  numEfficientStarsEarned: Scalars['Int']['output'];
  numPlays: Scalars['Int']['output'];
  numSurvivabilityStarsEarned: Scalars['Int']['output'];
  numTurnStarsEarned: Scalars['Int']['output'];
};

export type LevelCompletion = {
  __typename?: 'LevelCompletion';
  dateCompleted: Scalars['DateTime']['output'];
  difficulty: Scalars['Float']['output'];
  levelKey: Scalars['String']['output'];
  totalStarsEarned: Scalars['Int']['output'];
  totalTimeToCompleteSeconds: Scalars['Int']['output'];
  totalTurnsToComplete: Scalars['Int']['output'];
};

export type LevelGroupDetails = {
  __typename?: 'LevelGroupDetails';
  category: SciOpsLevelCategory;
  completionHistory: Array<LevelCompletion>;
  highestLevel: Scalars['String']['output'];
  levels: Array<Level>;
};

export enum MeasurementOfTimeType {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type Message = {
  __typename?: 'Message';
  key: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addActivityLinkToStudentActivity: StudentActivity;
  addAllAssignmentData: Scalars['Boolean']['output'];
  addAssignmentToStudentActivity: StudentActivity;
  addConsentToSurveyResult: SurveyResults;
  addDistrictSemesterToClassroom: Classroom;
  addGroupDomain: Group;
  addGroupDomains: Group;
  addOnlineActivityToStudentActivity: StudentActivity;
  addStateStandardToUnit: ActivityUnit;
  addStudentActivityToUnit: ActivityUnit;
  addStudentToGroup: Group;
  addTagToStudentActivity: StudentActivity;
  addTeacherToGroup: Group;
  addUnitStandardToStateStandard: ActivityUnit;
  addUnitToCourse: ActivityCourse;
  addUserSciOpsLogin: User;
  addUserSurveyInvite: User;
  assignClassroomAssignment: AssignmentManager;
  assignDistrictSemestersNames: Scalars['Boolean']['output'];
  assignSCPostSurveysByClass?: Maybe<Scalars['Boolean']['output']>;
  bulkCreateStudents: Array<User>;
  captureSnapShot: WebStats;
  changeActivityLinkPositionInArray: StudentActivity;
  changeActivityPositionInArray: ActivityUnit;
  changeUnitPositionInArray: ActivityCourse;
  changeUserRole?: Maybe<User>;
  changeWholeUnitIsActiveStatus: ActivityUnit;
  checkGroupsToTurnOffPostSurveys: Array<Group>;
  checkGroupsToTurnOnPostSurveys: Array<Group>;
  checkToAssignSurveyInviteForUser: User;
  cleanUpGroupCodeData: Array<NewGroupCode>;
  cleanUpGroupData: Array<Group>;
  cleanUpTrainingData: Array<TeacherTraining>;
  createActivityCourse: ActivityCourse;
  createActivityUnit: ActivityCourse;
  createAdminUser: User;
  createAssignment: Assignment;
  createClassroom: Classroom;
  createClickTracker: ClickTracker;
  createDistrictAdminPortal: User;
  createDistrictSemester: Group;
  createDistrictUser?: Maybe<User>;
  createGroup: Group;
  createGroupCode: NewGroupCode;
  createNewAssignmentSubmission: SubmissionManager;
  createNewResourceStat?: Maybe<ResourcesStats>;
  createNewTeacherTraining: TeacherTraining;
  createPortalNotification: PortalNotification;
  createSciopsSaveData: SaveData;
  createStudent: User;
  createStudentActivity: ActivityCourse;
  createSurveyData: SurveyData;
  createSurveyResult: SurveyResults;
  createTeacherAdminPortal: User;
  createTeacherUser?: Maybe<User>;
  createTeachers: Array<Scalars['String']['output']>;
  createTestingClassrooms?: Maybe<Scalars['Boolean']['output']>;
  deleteActivityCourse: ActivityCourse;
  deleteActivityRating: ActivityRating;
  deleteActivityUnit: ActivityUnit;
  deleteAllNewAssignmentData: Scalars['Boolean']['output'];
  deleteAllOldAssignmentData: Scalars['Boolean']['output'];
  deleteAssignment: Assignment;
  deleteAssignmentManager: AssignmentManager;
  deleteClassroom: Scalars['Boolean']['output'];
  deleteDistrictSemester: Group;
  deletePortalNotification: PortalNotification;
  deleteSciopsSaveData: SaveData;
  deleteStudentActivity: ActivityUnit;
  deleteStudentClickTrackerData: Scalars['Boolean']['output'];
  editGroupAddress: Group;
  editGroupName: Group;
  generateMonthlyNewTeachersReport: Scalars['Boolean']['output'];
  incrUserLogin: User;
  joinClassroom: Classroom;
  login: User;
  logout: Scalars['Boolean']['output'];
  markPortalNotificationAsDeleted: PortalNotification;
  markSubmissionManagerAsComplete: SubmissionManager;
  markSurveyResultComplete: SurveyResults;
  migrateGroupsToPopulateSurveyInfo: Scalars['Boolean']['output'];
  moveUserToGroup: Group;
  processForgotPass: User;
  refactorTeacherTrainings: Scalars['Boolean']['output'];
  removeActivityLinkFromStudentActivity: StudentActivity;
  removeAssignmentFromStudentActivity: StudentActivity;
  removeClassLinkId: Group;
  removeCleverId: Group;
  removeDistrictSemesterFromClassroom: Classroom;
  removeGroupDomain: Group;
  removeGroupDomains: Group;
  removeOnlineActivityFromStudentActivity: StudentActivity;
  removeStateStandardFromUnit: ActivityUnit;
  removeStudent: Classroom;
  removeStudentActivityFromUnit: ActivityUnit;
  removeStudentToGroup: Group;
  removeTagFromStudentActivity: StudentActivity;
  removeTeacherToGroup: Group;
  removeTeacherTrainingData: TeacherTraining;
  removeUnitFromCourse: ActivityCourse;
  removeUnitStandardFromStateStandard: ActivityUnit;
  removeUser: User;
  removeUserByAdmin: Scalars['Boolean']['output'];
  removeUserFromClickTracker: Scalars['Boolean']['output'];
  removeUserFromGroup: Group;
  removeUserSurveyInvite: User;
  resendVerification: Scalars['Boolean']['output'];
  runClassroomUserUpdate: Scalars['Boolean']['output'];
  runCleverSyncForAllGroups: Scalars['Boolean']['output'];
  runDeletionScripts: Scalars['Boolean']['output'];
  runGroupCodeSchemaUpdate: Scalars['Boolean']['output'];
  runGroupUserUpdate: Scalars['Boolean']['output'];
  runSisSync?: Maybe<Scalars['Boolean']['output']>;
  saveAssignmentSubmissionProgress: SubmissionManager;
  saveLevelCompletion: SaveData;
  sciopsLogoutUser: Scalars['Boolean']['output'];
  sendContactUs: Scalars['Boolean']['output'];
  sendEmailForgotPassword: Scalars['Boolean']['output'];
  sendSalesEmailBlast?: Maybe<Array<Scalars['Boolean']['output']>>;
  sendTeacherCurriculumSupport?: Maybe<Scalars['Boolean']['output']>;
  sendTeacherFeedback?: Maybe<Scalars['Boolean']['output']>;
  sendTeacherPortalContactForm?: Maybe<Scalars['Boolean']['output']>;
  sendTeacherTechSupport?: Maybe<Scalars['Boolean']['output']>;
  sendTrainingCertEmail: Scalars['Boolean']['output'];
  setClassLinkId: Group;
  setCleverId: Group;
  setDemoState: Group;
  setGroupHasSciOps: Group;
  setGroupTeacherSignup: Group;
  setPilotState: Group;
  setSciopsSaveDataCareers: SaveData;
  setSciopsSaveDataCharacter: SaveData;
  setSciopsSaveDataLevelCompletion: SaveData;
  setSciopsSaveDataNotifications: SaveData;
  setSciopsSaveDataSettings: SaveData;
  setSciopsSaveDataViewedNotifications: SaveData;
  setUserDemoStatus: User;
  setUserDeveloperStatus: User;
  submitActivityRating: ActivityRating;
  submitSurveyResultAnswers: SurveyResults;
  testAddSubmissionAnswers: AssignmentSubmission;
  toggleOnGroupSurveys: Group;
  tokenAuthentication: User;
  unlockSciOpsLvls: User;
  unlockSciOpsLvlsForClassroom: Classroom;
  unlockSciOpsLvlsForClassrooms: Scalars['Boolean']['output'];
  updateActivityCourse: ActivityCourse;
  updateActivityUnit: ActivityUnit;
  updateAllUnitAndCourseName: Scalars['Boolean']['output'];
  updateAssignmentManagerDefaults: AssignmentManager;
  updateClassroom: Classroom;
  updateComicOnUser: User;
  updateDistrictSemester: Group;
  updateGroupCode: NewGroupCode;
  updatePortalNotification: PortalNotification;
  updatePortalNotificationMessage: PortalNotification;
  updateStateStandard: ActivityUnit;
  updateStudentActivity: StudentActivity;
  updateStudentActivityResourceCategoryType: StudentActivity;
  updateStudentActivityReviewResourceType: StudentActivity;
  updateStudentActivitySkillConnectionTags: StudentActivity;
  updateSurveyInviteInfo: Group;
  updateTeacherTrainingHasCert?: Maybe<TeacherTraining>;
  updateTeacherTrainingModuleData?: Maybe<TeacherTraining>;
  updateTeacherTrainingTopicData?: Maybe<TeacherTraining>;
  updateUser: User;
  updateUserActivities: User;
  updateUserCourses: User;
  updateUserEduProfile: User;
  updateUserEducationLevel: User;
  updateUserGradesTaught: User;
  updateUserLanguage: User;
  updateUserPassword: User;
  updateUserProfile: User;
  updateUserSciOpsPlayTime: User;
  updateUserSubjectsTaught: User;
  updateUserTour: User;
  updateUserTrainingDates: Array<Scalars['String']['output']>;
  updateUserUnits: User;
  updateUserVerification: User;
  validateTeacherTrainingModules?: Maybe<TeacherTraining>;
  verifyAllTeachersByGroup: Scalars['Boolean']['output'];
  verifyUser: Scalars['Boolean']['output'];
};

export type MutationAddActivityLinkToStudentActivityArgs = {
  createActivityLinkDto: CreateActivityLinkDto;
  studentActivityId: Scalars['String']['input'];
};

export type MutationAddAssignmentToStudentActivityArgs = {
  assignmentId: Scalars['String']['input'];
  studentActivityId: Scalars['String']['input'];
};

export type MutationAddConsentToSurveyResultArgs = {
  surveyId: GetSurveyResultDto;
};

export type MutationAddDistrictSemesterToClassroomArgs = {
  districtSemesterId: Scalars['String']['input'];
  getClassroom: GetClassroomDto;
};

export type MutationAddGroupDomainArgs = {
  domainToAdd: Scalars['String']['input'];
  getGroup: GetGroupDto;
};

export type MutationAddGroupDomainsArgs = {
  domainsToAdd: Array<Scalars['String']['input']>;
  getGroup: GetGroupDto;
};

export type MutationAddOnlineActivityToStudentActivityArgs = {
  createOnlineActivityDto: CreateOnlineActivityDto;
  studentActivityId: Scalars['String']['input'];
};

export type MutationAddStateStandardToUnitArgs = {
  createStateStandardDto: CreateStateStandardDto;
  getActivityUnitDto: GetActivityUnitDto;
};

export type MutationAddStudentActivityToUnitArgs = {
  studentActivityId: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type MutationAddStudentToGroupArgs = {
  getGroup: GetGroupDto;
  getUser: GetUserDto;
};

export type MutationAddTagToStudentActivityArgs = {
  activityTag: Scalars['String']['input'];
  studentActivityId: Scalars['String']['input'];
};

export type MutationAddTeacherToGroupArgs = {
  getGroup: GetGroupDto;
  getUser: GetUserDto;
};

export type MutationAddUnitStandardToStateStandardArgs = {
  createUnitStandardDto: CreateUnitStandardDto;
  getActivityUnitDto: GetActivityUnitDto;
  stateStandardId: Scalars['String']['input'];
};

export type MutationAddUnitToCourseArgs = {
  courseId: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type MutationAddUserSciOpsLoginArgs = {
  getUser: GetUserDto;
};

export type MutationAddUserSurveyInviteArgs = {
  getUser: GetUserDto;
  surveyName: Scalars['String']['input'];
};

export type MutationAssignClassroomAssignmentArgs = {
  dto: CreateAssignmentManagerDto;
};

export type MutationAssignScPostSurveysByClassArgs = {
  getClassroom: GetClassroomDto;
};

export type MutationBulkCreateStudentsArgs = {
  createStudentsDto: CreateStudentsDto;
  creationType: UserCreationType;
};

export type MutationChangeActivityLinkPositionInArrayArgs = {
  currentIndex: Scalars['Float']['input'];
  destinationIndex: Scalars['Float']['input'];
  studentActivityId: Scalars['String']['input'];
};

export type MutationChangeActivityPositionInArrayArgs = {
  currentIndex: Scalars['Float']['input'];
  destinationIndex: Scalars['Float']['input'];
  getActivityUnitDto: GetActivityUnitDto;
};

export type MutationChangeUnitPositionInArrayArgs = {
  currentIndex: Scalars['Float']['input'];
  destinationIndex: Scalars['Float']['input'];
  getActivityCourseDto: GetActivityCourseDto;
};

export type MutationChangeUserRoleArgs = {
  roleAccessCode: Scalars['String']['input'];
  userProfileDto?: InputMaybe<UserProfileDto>;
  userRole: UserRole;
};

export type MutationChangeWholeUnitIsActiveStatusArgs = {
  activityUnitId: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
};

export type MutationCheckToAssignSurveyInviteForUserArgs = {
  getUserDto: GetUserDto;
};

export type MutationCreateActivityCourseArgs = {
  createActivityCourseDto: CreateActivityCourseDto;
};

export type MutationCreateActivityUnitArgs = {
  courseId: Scalars['String']['input'];
  createActivityUnitDto: CreateActivityUnitDto;
};

export type MutationCreateAdminUserArgs = {
  adminSignUpData: CreateAdminDto;
  creationType: UserCreationType;
};

export type MutationCreateAssignmentArgs = {
  dto: CreateAssignmentDto;
};

export type MutationCreateClassroomArgs = {
  createClassroom: CreateClassroomDto;
  districtSemesterId: Scalars['String']['input'];
};

export type MutationCreateClickTrackerArgs = {
  createClickTrackerDto: CreateClickTrackerDto;
};

export type MutationCreateDistrictAdminPortalArgs = {
  creationType: UserCreationType;
  districtCreationDTO: TeacherSignupDto;
};

export type MutationCreateDistrictSemesterArgs = {
  createDistrictSemesterDto: CreateDistrictSemesterDto;
  getGroup: GetGroupDto;
};

export type MutationCreateDistrictUserArgs = {
  creationType: UserCreationType;
  districtSignupData: TeacherSignupDto;
};

export type MutationCreateGroupArgs = {
  createGroup: CreateGroupDto;
  createGroupAddress?: InputMaybe<CreateGroupAddressDto>;
};

export type MutationCreateGroupCodeArgs = {
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  groupId: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  numLicenses?: InputMaybe<Scalars['Int']['input']>;
  type: GroupCodeType;
};

export type MutationCreateNewAssignmentSubmissionArgs = {
  submissionManagerId: Scalars['String']['input'];
};

export type MutationCreateNewResourceStatArgs = {
  createResourceStatDto: CreateResourceStatDto;
};

export type MutationCreateNewTeacherTrainingArgs = {
  getUserDto: GetUserDto;
};

export type MutationCreatePortalNotificationArgs = {
  createPortalNotificationDto: CreatePortalNotificationDto;
  user: Scalars['String']['input'];
};

export type MutationCreateSciopsSaveDataArgs = {
  getUser: GetUserDto;
};

export type MutationCreateStudentArgs = {
  classCode: Scalars['String']['input'];
  createStudent: CreateUserDto;
  creationType: UserCreationType;
};

export type MutationCreateStudentActivityArgs = {
  activityUnitId: Scalars['String']['input'];
  createStudentActivityDto: CreateStudentActivityDto;
};

export type MutationCreateSurveyDataArgs = {
  createSurveyData: CreateSurveyDto;
};

export type MutationCreateSurveyResultArgs = {
  surveyResultData: CreateSurveyResultDto;
};

export type MutationCreateTeacherAdminPortalArgs = {
  creationType: UserCreationType;
  teacherCreationDTO: TeacherSignupDto;
};

export type MutationCreateTeacherUserArgs = {
  creationType: UserCreationType;
  teacherSignupData: TeacherSignupDto;
  userEduProfileDto?: InputMaybe<UserEduProfileDto>;
};

export type MutationCreateTeachersArgs = {
  creationType: UserCreationType;
  password: Scalars['String']['input'];
  teacherSignupDataArray: Array<TeacherSignupDto>;
};

export type MutationDeleteActivityCourseArgs = {
  activityCourseId: Scalars['String']['input'];
};

export type MutationDeleteActivityRatingArgs = {
  activityRatingId: Scalars['String']['input'];
};

export type MutationDeleteActivityUnitArgs = {
  activityUnitId: Scalars['String']['input'];
};

export type MutationDeleteAssignmentArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteAssignmentManagerArgs = {
  assignmentManagerId: Scalars['String']['input'];
};

export type MutationDeleteClassroomArgs = {
  getClassroom: GetClassroomDto;
};

export type MutationDeleteDistrictSemesterArgs = {
  districtSemesterId: Scalars['String']['input'];
  getGroup: GetGroupDto;
};

export type MutationDeletePortalNotificationArgs = {
  portalNotificationId: Scalars['String']['input'];
};

export type MutationDeleteSciopsSaveDataArgs = {
  getSaveData: GetSaveDataDto;
};

export type MutationDeleteStudentActivityArgs = {
  studentActivityId: Scalars['String']['input'];
};

export type MutationEditGroupAddressArgs = {
  createGroupAddress: CreateGroupAddressDto;
  getGroup: GetGroupDto;
};

export type MutationEditGroupNameArgs = {
  getGroup: GetGroupDto;
  newName: Scalars['String']['input'];
};

export type MutationIncrUserLoginArgs = {
  getUser: GetUserDto;
};

export type MutationJoinClassroomArgs = {
  classCode: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationMarkPortalNotificationAsDeletedArgs = {
  portalNotificationId: Scalars['String']['input'];
};

export type MutationMarkSubmissionManagerAsCompleteArgs = {
  submissionManagerId: Scalars['String']['input'];
};

export type MutationMarkSurveyResultCompleteArgs = {
  surveyId: GetSurveyResultDto;
};

export type MutationMoveUserToGroupArgs = {
  getUserDto: GetUserDto;
  targetGroupDto: GetGroupDto;
};

export type MutationProcessForgotPassArgs = {
  newPassword: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type MutationRemoveActivityLinkFromStudentActivityArgs = {
  activityLinkId: Scalars['String']['input'];
  studentActivityId: Scalars['String']['input'];
};

export type MutationRemoveAssignmentFromStudentActivityArgs = {
  studentActivityId: Scalars['String']['input'];
};

export type MutationRemoveClassLinkIdArgs = {
  getGroupDto: GetGroupDto;
};

export type MutationRemoveCleverIdArgs = {
  getGroupDto: GetGroupDto;
};

export type MutationRemoveDistrictSemesterFromClassroomArgs = {
  getClassroom: GetClassroomDto;
};

export type MutationRemoveGroupDomainArgs = {
  domainToRemove: Scalars['String']['input'];
  getGroup: GetGroupDto;
};

export type MutationRemoveGroupDomainsArgs = {
  domainsToRemove: Array<Scalars['String']['input']>;
  getGroup: GetGroupDto;
};

export type MutationRemoveOnlineActivityFromStudentActivityArgs = {
  studentActivityId: Scalars['String']['input'];
};

export type MutationRemoveStateStandardFromUnitArgs = {
  getActivityUnitDto: GetActivityUnitDto;
  stateStandardId: Scalars['String']['input'];
};

export type MutationRemoveStudentArgs = {
  getClassroom: GetClassroomDto;
  getUser: GetUserDto;
};

export type MutationRemoveStudentActivityFromUnitArgs = {
  studentActivityId: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type MutationRemoveStudentToGroupArgs = {
  getGroup: GetGroupDto;
  getUser: GetUserDto;
};

export type MutationRemoveTagFromStudentActivityArgs = {
  activityTag: Scalars['String']['input'];
  studentActivityId: Scalars['String']['input'];
};

export type MutationRemoveTeacherToGroupArgs = {
  getGroup: GetGroupDto;
  getUser: GetUserDto;
};

export type MutationRemoveTeacherTrainingDataArgs = {
  user: Scalars['String']['input'];
};

export type MutationRemoveUnitFromCourseArgs = {
  courseId: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
};

export type MutationRemoveUnitStandardFromStateStandardArgs = {
  getActivityUnitDto: GetActivityUnitDto;
  stateStandardId: Scalars['String']['input'];
  unitStandardId: Scalars['String']['input'];
};

export type MutationRemoveUserArgs = {
  userId: GetUserDto;
};

export type MutationRemoveUserByAdminArgs = {
  userId: GetUserDto;
};

export type MutationRemoveUserFromClickTrackerArgs = {
  user: Scalars['String']['input'];
};

export type MutationRemoveUserFromGroupArgs = {
  getGroup: GetGroupDto;
  getUser: GetUserDto;
};

export type MutationRemoveUserSurveyInviteArgs = {
  getUser: GetUserDto;
  surveyName: Scalars['String']['input'];
};

export type MutationRunSisSyncArgs = {
  getGroupDto: GetGroupDto;
  thirdPartyId: Scalars['String']['input'];
};

export type MutationSaveAssignmentSubmissionProgressArgs = {
  answers: Array<SaveAssignmentSubmissionAnswerDto>;
  isComplete: Scalars['Boolean']['input'];
  submissionManagerId: Scalars['String']['input'];
};

export type MutationSaveLevelCompletionArgs = {
  getSaveData: GetSaveDataDto;
  levelCategory: Scalars['Float']['input'];
  levelKey: Scalars['String']['input'];
};

export type MutationSendContactUsArgs = {
  emailBody: SendContactUsDto;
};

export type MutationSendEmailForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationSendSalesEmailBlastArgs = {
  dynamicData: Array<SalesDynamicData>;
  emailData: SalesSendgridEmailDto;
};

export type MutationSendTeacherCurriculumSupportArgs = {
  emailBody: SendTeacherCurriculumSupportDto;
};

export type MutationSendTeacherFeedbackArgs = {
  emailBody: SendTeacherFeedbackDto;
};

export type MutationSendTeacherPortalContactFormArgs = {
  emailBody: SendTeacherPortalContactDto;
};

export type MutationSendTeacherTechSupportArgs = {
  emailBody: SendTeacherTechSupportDto;
};

export type MutationSetClassLinkIdArgs = {
  classLinkAccessToken: Scalars['String']['input'];
  classLinkAppId: Scalars['String']['input'];
  classLinkSourcedId: Scalars['String']['input'];
  classLinkTenantId?: InputMaybe<Scalars['String']['input']>;
  getGroupDto: GetGroupDto;
};

export type MutationSetCleverIdArgs = {
  cleverId: Scalars['String']['input'];
  getGroupDto: GetGroupDto;
};

export type MutationSetDemoStateArgs = {
  getGroupDto: GetGroupDto;
  isDemo: Scalars['Boolean']['input'];
};

export type MutationSetGroupHasSciOpsArgs = {
  getGroup: GetGroupDto;
  hasSciOps: Scalars['Boolean']['input'];
};

export type MutationSetGroupTeacherSignupArgs = {
  getGroup: GetGroupDto;
  isTeacherSignupOn: Scalars['Boolean']['input'];
};

export type MutationSetPilotStateArgs = {
  getGroupDto: GetGroupDto;
  isInPilot: Scalars['Boolean']['input'];
};

export type MutationSetSciopsSaveDataCareersArgs = {
  careers: Array<SaveDataCareerDto>;
  getSaveData: GetSaveDataDto;
};

export type MutationSetSciopsSaveDataCharacterArgs = {
  character: Scalars['Float']['input'];
  characterData: SaveDataCharacterDto;
  getSaveData: GetSaveDataDto;
};

export type MutationSetSciopsSaveDataLevelCompletionArgs = {
  getSaveData: GetSaveDataDto;
  levelCategory: Scalars['Float']['input'];
  levels: Array<SaveDataLevelDto>;
};

export type MutationSetSciopsSaveDataNotificationsArgs = {
  getSaveData: GetSaveDataDto;
  notificationCategory: Scalars['Float']['input'];
  notifications: Array<SaveDataNotificationDto>;
};

export type MutationSetSciopsSaveDataSettingsArgs = {
  getSaveData: GetSaveDataDto;
  settings: SaveDataSettingsDto;
};

export type MutationSetSciopsSaveDataViewedNotificationsArgs = {
  getSaveData: GetSaveDataDto;
  viewedCategory: Scalars['Float']['input'];
  viewedData: Array<Scalars['Float']['input']>;
};

export type MutationSetUserDemoStatusArgs = {
  getUser: GetUserDto;
  isDemo: Scalars['Boolean']['input'];
};

export type MutationSetUserDeveloperStatusArgs = {
  getUser: GetUserDto;
  isDev: Scalars['Boolean']['input'];
};

export type MutationSubmitActivityRatingArgs = {
  activityId: Scalars['String']['input'];
  createActivityRatingDto: CreateActivityRatingDto;
  userId: Scalars['String']['input'];
};

export type MutationSubmitSurveyResultAnswersArgs = {
  results: Array<SurveyResultsDataDto>;
  surveyId: GetSurveyResultDto;
};

export type MutationTestAddSubmissionAnswersArgs = {
  answers: Array<SaveAssignmentSubmissionAnswerDto>;
  assignmentSubmissionId: Scalars['String']['input'];
};

export type MutationToggleOnGroupSurveysArgs = {
  getGroup: GetGroupDto;
  isSurveysOn: Scalars['Boolean']['input'];
};

export type MutationTokenAuthenticationArgs = {
  token: Scalars['String']['input'];
};

export type MutationUnlockSciOpsLvlsArgs = {
  getUserDto: GetUserDto;
  lvlKey: Scalars['String']['input'];
};

export type MutationUnlockSciOpsLvlsForClassroomArgs = {
  getClassroomDto: GetClassroomDto;
  lvlKey: Scalars['String']['input'];
};

export type MutationUnlockSciOpsLvlsForClassroomsArgs = {
  classRoomDtoArray: Array<GetClassroomDto>;
  lvlKey: Scalars['String']['input'];
};

export type MutationUpdateActivityCourseArgs = {
  getActivityCourseDto: GetActivityCourseDto;
  updateActivityCourseDto: UpdateActivityCourseDto;
};

export type MutationUpdateActivityUnitArgs = {
  getActivityUnitDto: GetActivityUnitDto;
  updateActivityUnitDto: UpdateActivityUnitDto;
};

export type MutationUpdateAssignmentManagerDefaultsArgs = {
  assignmentManagerId: Scalars['String']['input'];
  controls: AssignmentControlsDto;
  isOverride?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateClassroomArgs = {
  districtSemesterId: Scalars['String']['input'];
  getClassroom: GetClassroomDto;
  updateClassroom: UpdateClassroomDto;
};

export type MutationUpdateComicOnUserArgs = {
  getUser: GetUserDto;
  updateUserComicDto: UpdateUserComicDto;
};

export type MutationUpdateDistrictSemesterArgs = {
  districtSemesterId: Scalars['String']['input'];
  getGroup: GetGroupDto;
  updateDistrictSemesterDto: UpdateDistrictSemesterDto;
};

export type MutationUpdateGroupCodeArgs = {
  _id: Scalars['String']['input'];
  update: UpdateGroupCodeDataDto;
};

export type MutationUpdatePortalNotificationArgs = {
  portalNotificationId: Scalars['String']['input'];
  updatePortalNotificationDto: UpdatePortalNotificationDto;
};

export type MutationUpdatePortalNotificationMessageArgs = {
  portalNotificationId: Scalars['String']['input'];
  updateMessageDto: UpdateMessageDto;
};

export type MutationUpdateStateStandardArgs = {
  getActivityUnitDto: GetActivityUnitDto;
  stateStandardId: Scalars['String']['input'];
  updateStateStandardDto: UpdateStateStandardDto;
};

export type MutationUpdateStudentActivityArgs = {
  studentActivityId: Scalars['String']['input'];
  updateStudentActivityDto: UpdateStudentActivityDto;
};

export type MutationUpdateStudentActivityResourceCategoryTypeArgs = {
  resourceCategoryType?: InputMaybe<ResourceCategoryType>;
  studentActivityId: Scalars['String']['input'];
};

export type MutationUpdateStudentActivityReviewResourceTypeArgs = {
  reviewResourceType?: InputMaybe<ReviewResourceType>;
  studentActivityId: Scalars['String']['input'];
};

export type MutationUpdateStudentActivitySkillConnectionTagsArgs = {
  skillConnectionTags: Array<SkillConnectionTagType>;
  studentActivityId: Scalars['String']['input'];
};

export type MutationUpdateSurveyInviteInfoArgs = {
  getGroup: GetGroupDto;
  updateGroupSurveyInvitesDto: UpdateGroupSurveyInvitesDto;
};

export type MutationUpdateTeacherTrainingHasCertArgs = {
  getTeacherTrainingDto: GetTeacherTrainingDto;
  hasCert: Scalars['Boolean']['input'];
};

export type MutationUpdateTeacherTrainingModuleDataArgs = {
  getTeacherTrainingDto: GetTeacherTrainingDto;
  updateTeacherTrainingModuleDto: UpdateTeacherTrainingTopicDto;
};

export type MutationUpdateTeacherTrainingTopicDataArgs = {
  getTeacherTrainingDto: GetTeacherTrainingDto;
  moduleCode: Scalars['String']['input'];
  updateTeacherTrainingTopicDto: UpdateTeacherTrainingTopicDto;
};

export type MutationUpdateUserArgs = {
  getUser: GetUserDto;
  updateUser: UpdateUserDto;
};

export type MutationUpdateUserActivitiesArgs = {
  activityType: Array<Scalars['String']['input']>;
  getUser: GetUserDto;
};

export type MutationUpdateUserCoursesArgs = {
  courseCategory: Array<Scalars['String']['input']>;
  getUser: GetUserDto;
};

export type MutationUpdateUserEduProfileArgs = {
  getUser: GetUserDto;
  userEduProfileDto: UserEduProfileDto;
};

export type MutationUpdateUserEducationLevelArgs = {
  educationLevel: EduLvl;
  getUser: GetUserDto;
};

export type MutationUpdateUserGradesTaughtArgs = {
  getUser: GetUserDto;
  gradesTaught: Array<Scalars['String']['input']>;
};

export type MutationUpdateUserLanguageArgs = {
  getUser: GetUserDto;
  language: Scalars['String']['input'];
};

export type MutationUpdateUserPasswordArgs = {
  getUser: GetUserDto;
  newPassword: Scalars['String']['input'];
};

export type MutationUpdateUserProfileArgs = {
  getUser: GetUserDto;
  userProfile?: InputMaybe<UserProfileDto>;
  userProfilePicture?: InputMaybe<UserProfilePictureDto>;
};

export type MutationUpdateUserSciOpsPlayTimeArgs = {
  timeToAdd: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type MutationUpdateUserSubjectsTaughtArgs = {
  getUser: GetUserDto;
  subjectsTaught: Array<Scalars['String']['input']>;
};

export type MutationUpdateUserTourArgs = {
  completedToursDto: CompletedToursDto;
  getUser: GetUserDto;
};

export type MutationUpdateUserTrainingDatesArgs = {
  trainingDate: Scalars['DateTime']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type MutationUpdateUserUnitsArgs = {
  getUser: GetUserDto;
  units: Array<Scalars['String']['input']>;
};

export type MutationUpdateUserVerificationArgs = {
  getUser: GetUserDto;
  userVerification: UserVerificationDto;
};

export type MutationValidateTeacherTrainingModulesArgs = {
  getTeacherTrainingDto: GetTeacherTrainingDto;
};

export type MutationVerifyAllTeachersByGroupArgs = {
  groupID: Scalars['String']['input'];
};

export type MutationVerifyUserArgs = {
  uuid: Scalars['String']['input'];
};

export type NewGroupCode = {
  __typename?: 'NewGroupCode';
  _id: Scalars['ID']['output'];
  code: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  group: Group;
  isActive: Scalars['Boolean']['output'];
  numLicenses?: Maybe<Scalars['Int']['output']>;
  type: GroupCodeType;
  users: Array<User>;
};

export type Newsletter = {
  __typename?: 'Newsletter';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  fileOriginalName: Scalars['String']['output'];
  storageURL: Scalars['String']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  charKey: Scalars['String']['output'];
  messageId: Scalars['Float']['output'];
  notificationId: Scalars['Float']['output'];
  type: Scalars['Float']['output'];
  unlockKey: Scalars['String']['output'];
};

export enum NotificationType {
  Comic = 'COMIC',
}

export type OnlineLink = {
  __typename?: 'OnlineLink';
  aId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OrderBy = {
  direction: Scalars['String']['input'];
  field: Scalars['String']['input'];
};

export type PageLink = {
  __typename?: 'PageLink';
  after?: Maybe<Scalars['String']['output']>;
  before?: Maybe<Scalars['String']['output']>;
  size: Scalars['Float']['output'];
};

export type PortalNotification = {
  __typename?: 'PortalNotification';
  _id: Scalars['String']['output'];
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  creationDate: Scalars['DateTime']['output'];
  deleted: Scalars['Boolean']['output'];
  displayDate?: Maybe<Scalars['DateTime']['output']>;
  message: Message;
  read: Scalars['Boolean']['output'];
  sender: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: NotificationType;
  updateTime?: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

/** The selection of available profile picture colors */
export enum ProfilePicColor {
  Blue = 'BLUE',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Purple = 'PURPLE',
  Red = 'RED',
}

/** The selection of available profile pictures */
export enum ProfilePicImg {
  Apple = 'APPLE',
  Flask = 'FLASK',
  Galaxy = 'GALAXY',
  H2O = 'H2O',
  Leaf = 'LEAF',
  Pencil = 'PENCIL',
  Pi = 'PI',
  PlasmaDark = 'PLASMA_DARK',
  PlasmaLight = 'PLASMA_LIGHT',
  Rocket = 'ROCKET',
}

export type Query = {
  __typename?: 'Query';
  checkForPortalNotifications: Array<PortalNotification>;
  checkStudentActivityAccess: Scalars['Boolean']['output'];
  classroomExist: Scalars['Boolean']['output'];
  compareUserPassword: Scalars['Boolean']['output'];
  getActivityCourse: ActivityCourse;
  getActivityCourses: Array<ActivityCourse>;
  getActivityCoursesCount: Scalars['Int']['output'];
  getActivityRating: ActivityRating;
  getActivityRatings: Array<ActivityRating>;
  getActivityUnit?: Maybe<ActivityUnit>;
  getActivityUnits: Array<ActivityUnit>;
  getActivityUnitsCount: Scalars['Int']['output'];
  getAllGroupCodes: Array<NewGroupCode>;
  getAllSurveyData: Array<SurveyData>;
  getAllSurveys: Array<SurveyResults>;
  getAllSurveysForUser: Array<SurveyResults>;
  getAllTeacherTrainingModuleData: Array<TeacherTrainingModuleData>;
  getAllTeacherTrainings: Array<TeacherTraining>;
  getAssignmentByDto?: Maybe<Assignment>;
  getAssignmentById?: Maybe<Assignment>;
  getAssignmentCount: Scalars['Int']['output'];
  getAssignmentManager?: Maybe<AssignmentManager>;
  getAssignmentManagersForClassrooms: Array<AssignmentManager>;
  getAssignmentSubmissionById?: Maybe<AssignmentSubmission>;
  getClassroom?: Maybe<Classroom>;
  getClassroomAdmin?: Maybe<Classroom>;
  getClassroomAssignmentsSummary: ClassroomAssignmentsModel;
  getClassroomDistrict?: Maybe<Classroom>;
  getClassrooms: Array<Classroom>;
  getClassroomsCount: Scalars['Int']['output'];
  getClickTrackerById: ClickTracker;
  getClickTrackers: Array<ClickTracker>;
  getForgotPass: User;
  getGroup?: Maybe<Group>;
  getGroupAdmin?: Maybe<Group>;
  getGroupCode?: Maybe<NewGroupCode>;
  getGroups: Array<Group>;
  getGroupsCount: Scalars['Int']['output'];
  getManyAssignmentManagers: Array<AssignmentManager>;
  getManyAssignmentSubmissions: Array<AssignmentSubmission>;
  getManyAssignmentsByDto: Array<Assignment>;
  getManySubmissionManagers: Array<SubmissionManager>;
  getMe: User;
  getMyClassrooms: Array<Classroom>;
  getMyClassroomsAssignmentManagers: Array<AssignmentManager>;
  getMyGroup: Group;
  getMyPortalNotifications: Array<PortalNotification>;
  getMyState: Scalars['String']['output'];
  getNewsletters: Array<Newsletter>;
  getNewslettersCount: Scalars['Float']['output'];
  getPortalNotification: PortalNotification;
  getPortalNotifications: Array<PortalNotification>;
  getPortalNotificationsForUser: Array<PortalNotification>;
  getRecommendedStudentActivities: Array<StudentActivity>;
  getResourceStat: ResourcesStats;
  getResourceStats: Array<ResourcesStats>;
  getSalesTeamMembers: Array<SalesTeamMember>;
  getSaveDatas: Array<SaveData>;
  getSciOpsJWT: Scalars['String']['output'];
  getSciOpsTokenCacheMap: Array<TokenCache>;
  getSciOpsUserCacheMap: Array<UserCacheMap>;
  getSciopsHighestLevel: Scalars['String']['output'];
  getSendGridTemplates: Array<SendgridTemplatesDto>;
  getStudentActivities: Array<StudentActivity>;
  getStudentActivitiesCount: Scalars['Int']['output'];
  getStudentActivity: StudentActivity;
  getStudentActivitySearch: Array<StudentActivity>;
  getSubmissionManagerById?: Maybe<SubmissionManager>;
  getSubmissionManagerByUnique?: Maybe<SubmissionManager>;
  getSurveyData?: Maybe<SurveyData>;
  getSurveyResult?: Maybe<SurveyResults>;
  getSurveyResults: Array<SurveyResults>;
  getSurveyResultsDataExport: Array<SurveyResultsExport>;
  getTeacherTraining?: Maybe<TeacherTraining>;
  getUser?: Maybe<User>;
  getUserAdmin?: Maybe<User>;
  getUserDistrict?: Maybe<User>;
  getUsers: UsersConnectionDto;
  getUsersByName: Array<User>;
  getUsersCount: Scalars['Int']['output'];
  getWebStats: Array<WebStats>;
  isAuthenticated: Scalars['Boolean']['output'];
  isUsernameAvailable: Scalars['Boolean']['output'];
  runGroupExport: Array<GroupDataExport>;
  runScript: Array<DataExport>;
  sciopsSaveData: SaveData;
  userHasSurveyInvite: Scalars['Boolean']['output'];
  userSessionExpiresOn?: Maybe<Scalars['String']['output']>;
};

export type QueryCheckForPortalNotificationsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryCheckStudentActivityAccessArgs = {
  submissionManagerId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type QueryClassroomExistArgs = {
  getClassroom: GetClassroomDto;
};

export type QueryCompareUserPasswordArgs = {
  getUser: GetUserDto;
  password: Scalars['String']['input'];
};

export type QueryGetActivityCourseArgs = {
  getActivityCourseDto: GetActivityCourseDto;
};

export type QueryGetActivityRatingArgs = {
  activityRatingId: Scalars['String']['input'];
};

export type QueryGetActivityRatingsArgs = {
  getActivityRatingsDto: GetActivityRatingsDto;
};

export type QueryGetActivityUnitArgs = {
  getActivityUnitDto: GetActivityUnitDto;
};

export type QueryGetAllSurveysForUserArgs = {
  userId: Scalars['String']['input'];
};

export type QueryGetAssignmentByDtoArgs = {
  dto: GetSingleAssignmentDto;
};

export type QueryGetAssignmentByIdArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetAssignmentCountArgs = {
  dto: GetManyAssignmentsDto;
};

export type QueryGetAssignmentManagerArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetAssignmentManagersForClassroomsArgs = {
  classroomsIds: Array<Scalars['String']['input']>;
};

export type QueryGetAssignmentSubmissionByIdArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetClassroomArgs = {
  getClassroom: GetClassroomDto;
};

export type QueryGetClassroomAdminArgs = {
  getClassroom: GetClassroomDto;
};

export type QueryGetClassroomAssignmentsSummaryArgs = {
  classroomDto: GetClassroomDto;
};

export type QueryGetClassroomDistrictArgs = {
  getClassroom: GetClassroomDto;
};

export type QueryGetClassroomsArgs = {
  getClassrooms: GetClassroomsDto;
};

export type QueryGetClassroomsCountArgs = {
  getClassrooms: GetClassroomsDto;
};

export type QueryGetClickTrackerByIdArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetClickTrackersArgs = {
  getClickTrackersDto: GetClickTrackersDto;
};

export type QueryGetForgotPassArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryGetGroupArgs = {
  getGroupDto: GetGroupDto;
};

export type QueryGetGroupAdminArgs = {
  getGroupDto: GetGroupDto;
};

export type QueryGetGroupCodeArgs = {
  _id?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<GroupCodeType>;
};

export type QueryGetGroupsArgs = {
  getGroupsDto: GetGroupsDto;
};

export type QueryGetGroupsCountArgs = {
  getGroupsDto: GetGroupsDto;
};

export type QueryGetManyAssignmentManagersArgs = {
  dto: GetAssignmentManagersDto;
};

export type QueryGetManyAssignmentSubmissionsArgs = {
  dto: GetManyAssignmentSubmissionDto;
};

export type QueryGetManyAssignmentsByDtoArgs = {
  dto: GetManyAssignmentsDto;
};

export type QueryGetManySubmissionManagersArgs = {
  dto: GetManySubmissionManagersDto;
};

export type QueryGetPortalNotificationArgs = {
  portalNotificationId: Scalars['String']['input'];
};

export type QueryGetPortalNotificationsArgs = {
  getPortalNotificationsDto: GetPortalNotificationsDto;
};

export type QueryGetPortalNotificationsForUserArgs = {
  user: Scalars['String']['input'];
};

export type QueryGetResourceStatArgs = {
  resourceId: Scalars['String']['input'];
};

export type QueryGetResourceStatsArgs = {
  getResourcesStatsDto: GetResourcesStatsDto;
};

export type QueryGetStudentActivitiesArgs = {
  getStudentActivitiesDto: GetStudentActivitiesDto;
};

export type QueryGetStudentActivitiesCountArgs = {
  getStudentActivitiesDto: GetStudentActivitiesDto;
};

export type QueryGetStudentActivityArgs = {
  studentActivityId: Scalars['String']['input'];
};

export type QueryGetStudentActivitySearchArgs = {
  searchDto: GetStudentActivitiesSearchDto;
};

export type QueryGetSubmissionManagerByIdArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetSubmissionManagerByUniqueArgs = {
  assignmentManagerId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type QueryGetSurveyDataArgs = {
  surveyId: GetSurveyDataDto;
};

export type QueryGetSurveyResultArgs = {
  surveyId: GetSurveyResultDto;
};

export type QueryGetSurveyResultsArgs = {
  getSurveyResultsDto: GetSurveyResultsDto;
};

export type QueryGetSurveyResultsDataExportArgs = {
  state: Scalars['String']['input'];
};

export type QueryGetTeacherTrainingArgs = {
  getTeacherTrainingDto: GetTeacherTrainingDto;
};

export type QueryGetUserArgs = {
  getUserDto: GetUserDto;
};

export type QueryGetUserAdminArgs = {
  getUserDto: GetUserDto;
};

export type QueryGetUserDistrictArgs = {
  getUserDto: GetUserDto;
};

export type QueryGetUsersArgs = {
  getUsersDto: GetUsersDto;
  queryParams?: InputMaybe<FindQueryParams>;
};

export type QueryGetUsersByNameArgs = {
  getUsersByNameDto: GetUsersByNameDto;
};

export type QueryGetUsersCountArgs = {
  getUsersDto: GetUsersDto;
};

export type QueryGetWebStatsArgs = {
  getWebStatsDto: GetWebStatsDto;
};

export type QueryIsUsernameAvailableArgs = {
  username: Scalars['String']['input'];
};

export type QuerySciopsSaveDataArgs = {
  getSaveData: GetSaveDataDto;
};

export type QueryUserHasSurveyInviteArgs = {
  getUser: GetUserDto;
  surveyName: Scalars['String']['input'];
};

export enum QuestionType {
  Agree = 'AGREE',
  Career = 'CAREER',
  Freq = 'FREQ',
  Major = 'MAJOR',
  Number = 'NUMBER',
  PickMany = 'PICK_MANY',
  PickOne = 'PICK_ONE',
  Rank = 'RANK',
  Sci = 'SCI',
  Text = 'TEXT',
}

export enum ResourceCategoryType {
  DirectInstruction = 'DIRECT_INSTRUCTION',
  FieldGuides = 'FIELD_GUIDES',
  GameSupport = 'GAME_SUPPORT',
  GeneralUnitSupports = 'GENERAL_UNIT_SUPPORTS',
  HandsOn = 'HANDS_ON',
  Interactive = 'INTERACTIVE',
  MotionGraphicVideos = 'MOTION_GRAPHIC_VIDEOS',
  Practice = 'PRACTICE',
  Review = 'REVIEW',
  StemExtension = 'STEM_EXTENSION',
}

export type ResourcesStats = {
  __typename?: 'ResourcesStats';
  _id: Scalars['String']['output'];
  activity: Scalars['String']['output'];
  course: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  group: Group;
  link?: Maybe<Scalars['String']['output']>;
  teacher: User;
  unit: Scalars['String']['output'];
};

export enum ReviewResourceType {
  CaseFileReview = 'CASE_FILE_REVIEW',
  ExpertEscape = 'EXPERT_ESCAPE',
  MindReader = 'MIND_READER',
  SearchAndRescue = 'SEARCH_AND_RESCUE',
  Sketch = 'SKETCH',
  Strike = 'STRIKE',
}

export type SalesDynamicData = {
  booth_number?: InputMaybe<Scalars['String']['input']>;
  conference_name?: InputMaybe<Scalars['String']['input']>;
  district_name?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  state_name?: InputMaybe<Scalars['String']['input']>;
  toEmailAddress: Scalars['String']['input'];
};

export type SalesSendgridEmailDto = {
  categories: Array<Scalars['String']['input']>;
  salesTeamMemberID: SalesTeamMembers;
  templateId: Scalars['String']['input'];
};

export type SalesTeamMember = {
  __typename?: 'SalesTeamMember';
  email: Scalars['String']['output'];
  id: SalesTeamMembers;
  team_member_img: Scalars['String']['output'];
  team_member_link: Scalars['String']['output'];
  team_member_name: Scalars['String']['output'];
  team_member_number: Scalars['String']['output'];
};

export enum SalesTeamMembers {
  Hunter = 'HUNTER',
  Kristen = 'KRISTEN',
  Mike = 'MIKE',
  Ryan = 'RYAN',
}

export type SaveAssignmentSubmissionAnswerDto = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  answer: Scalars['JSON']['input'];
  answerType?: InputMaybe<AssignmentAnswerType>;
  grade: Scalars['Float']['input'];
  isUpdated: Scalars['Boolean']['input'];
  needsReview: Scalars['Boolean']['input'];
  question: AssignmentQuestionDto;
};

export type SaveData = {
  __typename?: 'SaveData';
  _id: Scalars['String']['output'];
  career: Array<Career>;
  characters: SaveDataCharacters;
  levels: SaveDataLevels;
  notifications: SaveDataNotifications;
  settings: Settings;
  user: User;
};

export type SaveDataCareerDto = {
  careerKey: Scalars['String']['input'];
};

export type SaveDataCharacter = {
  __typename?: 'SaveDataCharacter';
  appCurrency: Scalars['Float']['output'];
  charKey: Scalars['String']['output'];
  currency: Scalars['Float']['output'];
  items: Array<CharacterItem>;
  scientist: Scalars['String']['output'];
  selCurrency: Scalars['Float']['output'];
  unitVariant: Scalars['Float']['output'];
  upgrades: Array<CharacterUpgrade>;
};

export type SaveDataCharacterDto = {
  appCurrency?: InputMaybe<Scalars['Int']['input']>;
  charKey: Scalars['String']['input'];
  currency?: InputMaybe<Scalars['Int']['input']>;
  items?: InputMaybe<Array<SaveDataCharacterItemsDto>>;
  scientist?: InputMaybe<Scalars['String']['input']>;
  selCurrency?: InputMaybe<Scalars['Int']['input']>;
  unitVariant?: InputMaybe<Scalars['Int']['input']>;
  upgrades?: InputMaybe<Array<SaveDataCharacterUpgradesDto>>;
};

export type SaveDataCharacterItemsDto = {
  defaultData?: InputMaybe<Scalars['Int']['input']>;
  gear?: InputMaybe<Scalars['String']['input']>;
  selData?: InputMaybe<Scalars['Int']['input']>;
};

export type SaveDataCharacterUpgradesDto = {
  defaultIndex?: InputMaybe<Scalars['Int']['input']>;
  gear?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  upgradeKey?: InputMaybe<Scalars['String']['input']>;
};

export type SaveDataCharacters = {
  __typename?: 'SaveDataCharacters';
  charAki?: Maybe<SaveDataCharacter>;
  charNicole?: Maybe<SaveDataCharacter>;
  charZia?: Maybe<SaveDataCharacter>;
};

export type SaveDataLevelDto = {
  levelKey: Scalars['String']['input'];
  maxStars: Scalars['Int']['input'];
  numComplete: Scalars['Int']['input'];
  numEfficientStarsEarned: Scalars['Int']['input'];
  numPlays: Scalars['Int']['input'];
  numSurvivabilityStarsEarned: Scalars['Int']['input'];
  numTurnStarsEarned: Scalars['Int']['input'];
};

export type SaveDataLevels = {
  __typename?: 'SaveDataLevels';
  campaign: LevelGroupDetails;
  challenge: LevelGroupDetails;
};

export type SaveDataNotificationDto = {
  charKey: Scalars['String']['input'];
  messageId: Scalars['Int']['input'];
  notificationId: Scalars['Int']['input'];
  type: Scalars['Int']['input'];
  unlockKey: Scalars['String']['input'];
};

export type SaveDataNotifications = {
  __typename?: 'SaveDataNotifications';
  careerNotifications: Array<Notification>;
  characterNotifications: Array<Notification>;
  gearNotifications: Array<Notification>;
  upgradeNotifications: Array<Notification>;
  viewedMessages: Array<Scalars['Int']['output']>;
  viewedNotifications: Array<Scalars['Int']['output']>;
};

export type SaveDataSettingsDto = {
  DifficultySetting?: InputMaybe<Scalars['Float']['input']>;
  EnableMousePanning?: InputMaybe<Scalars['Int']['input']>;
  FullScreenSetting?: InputMaybe<Scalars['Int']['input']>;
  GlobalMute?: InputMaybe<Scalars['Int']['input']>;
  GlobalVolume?: InputMaybe<Scalars['Float']['input']>;
  LastTimeUpdated?: InputMaybe<Scalars['Int']['input']>;
  MousePanSpeed?: InputMaybe<Scalars['Float']['input']>;
  MusicMute?: InputMaybe<Scalars['Int']['input']>;
  MusicVolume?: InputMaybe<Scalars['Float']['input']>;
  QualitySetting?: InputMaybe<Scalars['Int']['input']>;
  SFXMute?: InputMaybe<Scalars['Int']['input']>;
  SFXVolume?: InputMaybe<Scalars['Float']['input']>;
  ScreenResolutionHeight?: InputMaybe<Scalars['Int']['input']>;
  ScreenResolutionRefreshRate?: InputMaybe<Scalars['Int']['input']>;
  ScreenResolutionWidth?: InputMaybe<Scalars['Int']['input']>;
  VoiceMute?: InputMaybe<Scalars['Int']['input']>;
  VoiceVolume?: InputMaybe<Scalars['Float']['input']>;
  calibratePrimaryDownKey?: InputMaybe<Scalars['String']['input']>;
  calibratePrimaryUpKey?: InputMaybe<Scalars['String']['input']>;
  calibrateSecondaryDownKey?: InputMaybe<Scalars['String']['input']>;
  calibrateSecondaryUpKey?: InputMaybe<Scalars['String']['input']>;
  cameraRotateCCKey?: InputMaybe<Scalars['String']['input']>;
  cameraRotateCKey?: InputMaybe<Scalars['String']['input']>;
  cancelKey?: InputMaybe<Scalars['String']['input']>;
  downKey?: InputMaybe<Scalars['String']['input']>;
  endTurnKey?: InputMaybe<Scalars['String']['input']>;
  fastForwardKey?: InputMaybe<Scalars['String']['input']>;
  leftKey?: InputMaybe<Scalars['String']['input']>;
  rightKey?: InputMaybe<Scalars['String']['input']>;
  selectTacticalKey?: InputMaybe<Scalars['String']['input']>;
  selectUtilityKey?: InputMaybe<Scalars['String']['input']>;
  selectWeaponPrimaryKey?: InputMaybe<Scalars['String']['input']>;
  selectWeaponSecondaryKey?: InputMaybe<Scalars['String']['input']>;
  selectWeaponSpecialKey?: InputMaybe<Scalars['String']['input']>;
  selectedUnitNextKey?: InputMaybe<Scalars['String']['input']>;
  selectedUnitPreviousKey?: InputMaybe<Scalars['String']['input']>;
  submitKey?: InputMaybe<Scalars['String']['input']>;
  targetNextKey?: InputMaybe<Scalars['String']['input']>;
  targetPreviousKey?: InputMaybe<Scalars['String']['input']>;
  upKey?: InputMaybe<Scalars['String']['input']>;
  versionNumberAudio?: InputMaybe<Scalars['Int']['input']>;
  versionNumberKeybinds?: InputMaybe<Scalars['Int']['input']>;
  versionNumberVisual?: InputMaybe<Scalars['Int']['input']>;
  zoomInKey?: InputMaybe<Scalars['String']['input']>;
  zoomOutKey?: InputMaybe<Scalars['String']['input']>;
};

export enum SciOpsLevelCategory {
  Campaign = 'CAMPAIGN',
  Challenge = 'CHALLENGE',
  None = 'NONE',
}

export type SciopsSnapshot = {
  __typename?: 'SciopsSnapshot';
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  maxLevel?: Maybe<Scalars['Float']['output']>;
  numLogins?: Maybe<Scalars['Float']['output']>;
};

export type SendContactUsDto = {
  details: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  schoolDistrict?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  teacherRole?: InputMaybe<Scalars['String']['input']>;
};

export type SendGridTemplateVersion = {
  __typename?: 'SendGridTemplateVersion';
  active: Scalars['Int']['output'];
  editor: Scalars['String']['output'];
  generate_plain_content: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  template_id: Scalars['String']['output'];
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['String']['output'];
};

export type SendTeacherCurriculumSupportDto = {
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  school_subject: Scalars['String']['input'];
};

export type SendTeacherFeedbackDto = {
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rating: Scalars['String']['input'];
};

export type SendTeacherPortalContactDto = {
  comments?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  school?: InputMaybe<Scalars['String']['input']>;
};

export type SendTeacherTechSupportDto = {
  actual: Scalars['String']['input'];
  browser: Scalars['String']['input'];
  device: Scalars['String']['input'];
  email: Scalars['String']['input'];
  expected: Scalars['String']['input'];
  name: Scalars['String']['input'];
  repro: Scalars['String']['input'];
  summary: Scalars['String']['input'];
};

export type SendgridTemplatesDto = {
  __typename?: 'SendgridTemplatesDto';
  generation: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
  versions: Array<SendGridTemplateVersion>;
};

export type Settings = {
  __typename?: 'Settings';
  DifficultySetting: Scalars['Float']['output'];
  EnableMousePanning: Scalars['Int']['output'];
  FullScreenSetting: Scalars['Int']['output'];
  GlobalMute: Scalars['Int']['output'];
  GlobalVolume: Scalars['Float']['output'];
  LastTimeUpdated: Scalars['Int']['output'];
  MousePanSpeed: Scalars['Float']['output'];
  MusicMute: Scalars['Int']['output'];
  MusicVolume: Scalars['Float']['output'];
  QualitySetting: Scalars['Int']['output'];
  SFXMute: Scalars['Int']['output'];
  SFXVolume: Scalars['Float']['output'];
  ScreenResolutionHeight: Scalars['Int']['output'];
  ScreenResolutionRefreshRate: Scalars['Int']['output'];
  ScreenResolutionWidth: Scalars['Int']['output'];
  VoiceMute: Scalars['Int']['output'];
  VoiceVolume: Scalars['Float']['output'];
  calibratePrimaryDownKey: Scalars['String']['output'];
  calibratePrimaryUpKey: Scalars['String']['output'];
  calibrateSecondaryDownKey: Scalars['String']['output'];
  calibrateSecondaryUpKey: Scalars['String']['output'];
  cameraRotateCCKey: Scalars['String']['output'];
  cameraRotateCKey: Scalars['String']['output'];
  cancelKey: Scalars['String']['output'];
  downKey: Scalars['String']['output'];
  endTurnKey: Scalars['String']['output'];
  fastForwardKey: Scalars['String']['output'];
  leftKey: Scalars['String']['output'];
  rightKey: Scalars['String']['output'];
  selectTacticalKey: Scalars['String']['output'];
  selectUtilityKey: Scalars['String']['output'];
  selectWeaponPrimaryKey: Scalars['String']['output'];
  selectWeaponSecondaryKey: Scalars['String']['output'];
  selectWeaponSpecialKey: Scalars['String']['output'];
  selectedUnitNextKey: Scalars['String']['output'];
  selectedUnitPreviousKey: Scalars['String']['output'];
  submitKey: Scalars['String']['output'];
  targetNextKey: Scalars['String']['output'];
  targetPreviousKey: Scalars['String']['output'];
  upKey: Scalars['String']['output'];
  versionNumberAudio: Scalars['Int']['output'];
  versionNumberKeybinds: Scalars['Int']['output'];
  versionNumberVisual: Scalars['Int']['output'];
  zoomInKey: Scalars['String']['output'];
  zoomOutKey: Scalars['String']['output'];
};

export enum SkillConnectionTagType {
  Collaborative = 'COLLABORATIVE',
  GameBasedResource = 'GAME_BASED_RESOURCE',
  Inquiry = 'INQUIRY',
  Literacy = 'LITERACY',
  Math = 'MATH',
  RealWorld = 'REAL_WORLD',
  ScientificThinking = 'SCIENTIFIC_THINKING',
  Technology = 'TECHNOLOGY',
}

export type SsoProfile = {
  __typename?: 'SsoProfile';
  googleSub: Scalars['String']['output'];
};

export type StateStandard = {
  __typename?: 'StateStandard';
  _id: Scalars['ID']['output'];
  abbr: Scalars['String']['output'];
  name: Scalars['String']['output'];
  standards?: Maybe<Array<UnitStandard>>;
};

export type StudentActivity = {
  __typename?: 'StudentActivity';
  _id: Scalars['String']['output'];
  assignment?: Maybe<Assignment>;
  courseName: Scalars['String']['output'];
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  estimatedTime?: Maybe<EstimatedTime>;
  isActive: Scalars['Boolean']['output'];
  isAdvanced: Scalars['Boolean']['output'];
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  links: Array<ActivityLink>;
  name: Scalars['String']['output'];
  onlineActivity?: Maybe<OnlineLink>;
  recommendationScore?: Maybe<Scalars['Float']['output']>;
  resourceCategory?: Maybe<ResourceCategoryType>;
  reviewResource?: Maybe<ReviewResourceType>;
  score?: Maybe<Scalars['Float']['output']>;
  skillConnectionTags?: Maybe<Array<SkillConnectionTagType>>;
  studentPortalAssetLink?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  unitName: Scalars['String']['output'];
  unitPositionIndex?: Maybe<Scalars['Int']['output']>;
  units: Array<ActivityUnit>;
};

export type SubStandard = {
  __typename?: 'SubStandard';
  code?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
};

export type SubmissionManager = {
  __typename?: 'SubmissionManager';
  _id: Scalars['ID']['output'];
  assignment: Assignment;
  assignmentManager?: Maybe<AssignmentManager>;
  completionDate?: Maybe<Scalars['DateTime']['output']>;
  controls: AssignmentControls;
  creationDate: Scalars['DateTime']['output'];
  displayStatus: SubmissionManagerDisplayStatus;
  highestGrade: Scalars['Float']['output'];
  isConsideredComplete: Scalars['Boolean']['output'];
  isFinished: Scalars['Boolean']['output'];
  isOverride: Scalars['Boolean']['output'];
  isStatusGradable: Scalars['Boolean']['output'];
  isSubmittable: Scalars['Boolean']['output'];
  needsReview: Scalars['Boolean']['output'];
  status: SubmissionManagerStatus;
  submissions: Array<AssignmentSubmission>;
  user: User;
};

export enum SubmissionManagerDisplayStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
}

export enum SubmissionManagerStatus {
  AutoCompleted = 'AUTO_COMPLETED',
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
  InProgress = 'IN_PROGRESS',
  Late = 'LATE',
  LateNotStarted = 'LATE_NOT_STARTED',
  NotStarted = 'NOT_STARTED',
}

export type SurveyConsent = {
  __typename?: 'SurveyConsent';
  agree?: Maybe<Scalars['Boolean']['output']>;
  date: Scalars['DateTime']['output'];
};

export type SurveyData = {
  __typename?: 'SurveyData';
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sections: Array<SurveySection>;
  surveyId: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export enum SurveyImgPosition {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export type SurveyQuestionDto = {
  choices?: InputMaybe<Array<Scalars['String']['input']>>;
  code: Scalars['String']['input'];
  isRequired: Scalars['Boolean']['input'];
  questionImg?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  type: QuestionType;
};

export type SurveyResults = {
  __typename?: 'SurveyResults';
  _id: Scalars['String']['output'];
  completed?: Maybe<Scalars['Boolean']['output']>;
  completedDate?: Maybe<Scalars['DateTime']['output']>;
  consent?: Maybe<SurveyConsent>;
  results: Array<SurveyResultsData>;
  snapshotInfo?: Maybe<UserSnapshotInfo>;
  surveyId: Scalars['String']['output'];
  user: User;
  versionNumber: Scalars['Int']['output'];
};

export type SurveyResultsData = {
  __typename?: 'SurveyResultsData';
  answer: Scalars['String']['output'];
  questionCode: Scalars['String']['output'];
  section: Scalars['Float']['output'];
};

export type SurveyResultsDataDto = {
  answer: Scalars['String']['input'];
  questionCode: Scalars['String']['input'];
  section: Scalars['Int']['input'];
};

export type SurveyResultsExport = {
  __typename?: 'SurveyResultsExport';
  _id: Scalars['ID']['output'];
  age?: Maybe<Scalars['Int']['output']>;
  classroomId?: Maybe<Scalars['String']['output']>;
  classroomSubject?: Maybe<Scalars['String']['output']>;
  completed?: Maybe<Scalars['Boolean']['output']>;
  completedDate?: Maybe<Scalars['DateTime']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  gradeLvl?: Maybe<Scalars['Int']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  questionCareer1?: Maybe<Scalars['String']['output']>;
  questionCareer2?: Maybe<Scalars['String']['output']>;
  questionCareer3?: Maybe<Scalars['String']['output']>;
  questionCareerCode1?: Maybe<Scalars['String']['output']>;
  questionCareerCode2?: Maybe<Scalars['String']['output']>;
  questionCareerCode3?: Maybe<Scalars['String']['output']>;
  questionCareerRaw?: Maybe<Scalars['String']['output']>;
  questionCareerTopPick?: Maybe<Scalars['String']['output']>;
  questionCareerTopPickCode?: Maybe<Scalars['String']['output']>;
  questionMajor1?: Maybe<Scalars['String']['output']>;
  questionMajor2?: Maybe<Scalars['String']['output']>;
  questionMajor3?: Maybe<Scalars['String']['output']>;
  questionMajorCode1?: Maybe<Scalars['String']['output']>;
  questionMajorCode2?: Maybe<Scalars['String']['output']>;
  questionMajorCode3?: Maybe<Scalars['String']['output']>;
  questionMajorRaw?: Maybe<Scalars['String']['output']>;
  questionMajorTopPick?: Maybe<Scalars['String']['output']>;
  questionMajorTopPickCode?: Maybe<Scalars['String']['output']>;
  questionScience1?: Maybe<Scalars['String']['output']>;
  questionScience1Bool?: Maybe<Scalars['Boolean']['output']>;
  questionScience2?: Maybe<Scalars['String']['output']>;
  questionScience2Bool?: Maybe<Scalars['Boolean']['output']>;
  questionScience3?: Maybe<Scalars['String']['output']>;
  questionScience3Bool?: Maybe<Scalars['Boolean']['output']>;
  questionScience4?: Maybe<Scalars['String']['output']>;
  questionScience4Bool?: Maybe<Scalars['Boolean']['output']>;
  questionScience5?: Maybe<Scalars['String']['output']>;
  questionScience5Bool?: Maybe<Scalars['Boolean']['output']>;
  questionScience6?: Maybe<Scalars['String']['output']>;
  questionScience6Bool?: Maybe<Scalars['Boolean']['output']>;
  questionScience7?: Maybe<Scalars['String']['output']>;
  questionScience7Bool?: Maybe<Scalars['Boolean']['output']>;
  questionScience8?: Maybe<Scalars['String']['output']>;
  questionScience8Bool?: Maybe<Scalars['Boolean']['output']>;
  questionScience9?: Maybe<Scalars['String']['output']>;
  questionScience9Bool?: Maybe<Scalars['Boolean']['output']>;
  questionScience10?: Maybe<Scalars['String']['output']>;
  questionScience10Bool?: Maybe<Scalars['Boolean']['output']>;
  race?: Maybe<Scalars['String']['output']>;
  sciopsFirstPlayed?: Maybe<Scalars['DateTime']['output']>;
  sciopsLastLogin?: Maybe<Scalars['DateTime']['output']>;
  sciopsMaxLvl?: Maybe<Scalars['Int']['output']>;
  sciopsNumLogins?: Maybe<Scalars['Int']['output']>;
  sciopsTotalTime?: Maybe<Scalars['Int']['output']>;
  smA0?: Maybe<Scalars['Int']['output']>;
  smA1?: Maybe<Scalars['Int']['output']>;
  smA2?: Maybe<Scalars['Int']['output']>;
  smA3?: Maybe<Scalars['Int']['output']>;
  smA4?: Maybe<Scalars['Int']['output']>;
  smA5?: Maybe<Scalars['Int']['output']>;
  smB0?: Maybe<Scalars['Int']['output']>;
  smB1?: Maybe<Scalars['Int']['output']>;
  smB2?: Maybe<Scalars['Int']['output']>;
  smC0?: Maybe<Scalars['Int']['output']>;
  smC1?: Maybe<Scalars['Int']['output']>;
  smC2?: Maybe<Scalars['Int']['output']>;
  smD0?: Maybe<Scalars['Int']['output']>;
  smD1?: Maybe<Scalars['Int']['output']>;
  smD2?: Maybe<Scalars['Int']['output']>;
  smE0?: Maybe<Scalars['Int']['output']>;
  smE1?: Maybe<Scalars['Int']['output']>;
  smE2?: Maybe<Scalars['Int']['output']>;
  surveyId?: Maybe<Scalars['String']['output']>;
  userCreationDate?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userLastLogin?: Maybe<Scalars['DateTime']['output']>;
  userNumLogins?: Maybe<Scalars['Int']['output']>;
  videoGameHours?: Maybe<Scalars['Int']['output']>;
};

export type SurveySection = {
  __typename?: 'SurveySection';
  doRandomization: Scalars['Boolean']['output'];
  imgData?: Maybe<SurveySectionImgData>;
  instruction?: Maybe<Scalars['String']['output']>;
  questions: Array<SurveySectionQuestions>;
  title: Scalars['String']['output'];
};

export type SurveySectionDto = {
  doRandomization: Scalars['Boolean']['input'];
  imgData?: InputMaybe<SurveySectionImgDataDto>;
  instruction?: InputMaybe<Scalars['String']['input']>;
  questions: Array<SurveyQuestionDto>;
  title: Scalars['String']['input'];
};

export type SurveySectionImgData = {
  __typename?: 'SurveySectionImgData';
  position: SurveyImgPosition;
  url: Scalars['String']['output'];
};

export type SurveySectionImgDataDto = {
  position: SurveyImgPosition;
  url: Scalars['String']['input'];
};

export type SurveySectionQuestions = {
  __typename?: 'SurveySectionQuestions';
  choices?: Maybe<Array<Scalars['String']['output']>>;
  code: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
  questionImg?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  type: QuestionType;
};

export type TeacherSignupDto = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  groupCodeId: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  school: Scalars['String']['input'];
};

export type TeacherTraining = {
  __typename?: 'TeacherTraining';
  _id: Scalars['String']['output'];
  hasCert: Scalars['Boolean']['output'];
  modules: Array<TeacherTrainingModule>;
  user: User;
};

export type TeacherTrainingModule = {
  __typename?: 'TeacherTrainingModule';
  code: Scalars['String']['output'];
  completionDate?: Maybe<Scalars['DateTime']['output']>;
  isComplete: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  topics: Array<TeacherTrainingTopic>;
  version: Scalars['Int']['output'];
};

export type TeacherTrainingModuleData = {
  __typename?: 'TeacherTrainingModuleData';
  code: Scalars['String']['output'];
  color: Scalars['String']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  topics: Array<TeacherTrainingTopicData>;
  version: Scalars['Int']['output'];
};

export type TeacherTrainingResourceLink = {
  __typename?: 'TeacherTrainingResourceLink';
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TeacherTrainingTopic = {
  __typename?: 'TeacherTrainingTopic';
  code: Scalars['String']['output'];
  completionDate?: Maybe<Scalars['DateTime']['output']>;
  isComplete: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type TeacherTrainingTopicData = {
  __typename?: 'TeacherTrainingTopicData';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  resources: Array<TeacherTrainingResourceLink>;
  videoLink: Scalars['String']['output'];
};

export type TokenCache = {
  __typename?: 'TokenCache';
  apiToken: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UnitStandard = {
  __typename?: 'UnitStandard';
  _id: Scalars['ID']['output'];
  code?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  subStandards?: Maybe<Array<SubStandard>>;
};

export type UpdateActivityCourseDto = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateActivityUnitDto = {
  code?: InputMaybe<Scalars['String']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pacingGuide?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClassroomDto = {
  color?: InputMaybe<ClassroomColor>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDistrictSemesterDto = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postSurveyOn?: InputMaybe<Scalars['Boolean']['input']>;
  postSurveyWindow?: InputMaybe<CreateCalendarTimeWindowDto>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateGroupCodeDataDto = {
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  numLicenses?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateGroupSurveyInvitesDto = {
  highSchoolPostSurvey?: InputMaybe<Scalars['String']['input']>;
  highSchoolPreSurvey?: InputMaybe<Scalars['String']['input']>;
  middleSchoolPostSurvey?: InputMaybe<Scalars['String']['input']>;
  middleSchoolPreSurvey?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMessageDto = {
  key?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePortalNotificationDto = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  displayDate?: InputMaybe<Scalars['DateTime']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  sender?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NotificationType>;
  updateTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateStateStandardDto = {
  abbr?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStudentActivityDto = {
  courseName?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  estimatedTime?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  recommendationScore?: InputMaybe<Scalars['Float']['input']>;
  studentPortalAssetLink?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  unitName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeacherTrainingTopicDto = {
  code: Scalars['String']['input'];
  completionDate?: InputMaybe<Scalars['DateTime']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateUserComicDto = {
  comicName: Scalars['String']['input'];
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateUserDto = {
  email?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<UserState>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String']['output'];
  analytics: UserAnalytics;
  apiToken?: Maybe<Scalars['String']['output']>;
  assignments: Array<SubmissionManager>;
  classLink?: Maybe<UserClassLink>;
  classrooms: Array<Classroom>;
  clever?: Maybe<UserClever>;
  comics?: Maybe<Array<UserComic>>;
  completedTours: Array<Scalars['String']['output']>;
  developer: Scalars['Boolean']['output'];
  eduProfile?: Maybe<UserEduProfile>;
  email: Scalars['String']['output'];
  grade: Array<Scalars['String']['output']>;
  group?: Maybe<Group>;
  isDemo?: Maybe<Scalars['Boolean']['output']>;
  metadata: UserMetadata;
  numClassrooms: Scalars['Int']['output'];
  profile: UserProfile;
  role: UserRole;
  sciopsData: UserSciopsData;
  ssoProfile?: Maybe<SsoProfile>;
  state: UserState;
  surveyInvites: Array<UserSurveyInvite>;
  teacherTraining?: Maybe<TeacherTraining>;
  verification: UserVerification;
};

export type UserAnalytics = {
  __typename?: 'UserAnalytics';
  creationDate: Scalars['DateTime']['output'];
  lastLogin: Scalars['DateTime']['output'];
  numLogins: Scalars['Float']['output'];
  trainingDate?: Maybe<Scalars['DateTime']['output']>;
};

export type UserAssignmentSummary = {
  __typename?: 'UserAssignmentSummary';
  averageGrade: Scalars['Float']['output'];
  numCompleted: Scalars['Int']['output'];
  totalNumOfAssigned: Scalars['Int']['output'];
  user: User;
};

export type UserCacheMap = {
  __typename?: 'UserCacheMap';
  apiToken: Scalars['String']['output'];
  lastValidation?: Maybe<Scalars['Float']['output']>;
  time: Scalars['Float']['output'];
  timeout: Scalars['Float']['output'];
  user: User;
};

export type UserClassLink = {
  __typename?: 'UserClassLink';
  email?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  grades?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
};

export type UserClever = {
  __typename?: 'UserClever';
  created?: Maybe<Scalars['DateTime']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Scalars['String']['output']>;
  graduationYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  school?: Maybe<Scalars['String']['output']>;
  sisId?: Maybe<Scalars['String']['output']>;
  stateId?: Maybe<Scalars['String']['output']>;
  studentNumber?: Maybe<Scalars['String']['output']>;
  teacherNumber?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UserComic = {
  __typename?: 'UserComic';
  comicName: Scalars['String']['output'];
  read: Scalars['Boolean']['output'];
};

export enum UserCreationType {
  AdminBulk = 'ADMIN_BULK',
  AdminSingular = 'ADMIN_SINGULAR',
  AdminTesting = 'ADMIN_TESTING',
  Classlink = 'CLASSLINK',
  Clever = 'CLEVER',
  InviteCode = 'INVITE_CODE',
  Sso = 'SSO',
  TeacherBulk = 'TEACHER_BULK',
  TeacherSingular = 'TEACHER_SINGULAR',
}

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserEduProfile = {
  __typename?: 'UserEduProfile';
  activityType: Array<Scalars['String']['output']>;
  courseCategory: Array<Scalars['String']['output']>;
  educationLevel: EduLvl;
  gradesTaught: Array<Scalars['String']['output']>;
  subjectsTaught: Array<Scalars['String']['output']>;
  units: Array<Scalars['String']['output']>;
};

export type UserEduProfileDto = {
  activityType?: InputMaybe<Array<Scalars['String']['input']>>;
  courseCategory?: InputMaybe<Array<Scalars['String']['input']>>;
  educationLevel?: InputMaybe<EduLvl>;
  gradesTaught?: InputMaybe<Array<Scalars['String']['input']>>;
  subjectsTaught?: InputMaybe<Array<Scalars['String']['input']>>;
  units?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum UserLanguage {
  English = 'ENGLISH',
  Spanish = 'SPANISH',
}

export type UserMetadata = {
  __typename?: 'UserMetadata';
  creationType?: Maybe<UserCreationType>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  character?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  language: UserLanguage;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pictureUrl: UserProfilePictureUrl;
  school?: Maybe<Scalars['String']['output']>;
};

export type UserProfileDto = {
  character?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  school?: InputMaybe<Scalars['String']['input']>;
};

export type UserProfilePictureDto = {
  color?: InputMaybe<ProfilePicColor>;
  img?: InputMaybe<ProfilePicImg>;
};

export type UserProfilePictureUrl = {
  __typename?: 'UserProfilePictureUrl';
  color: ProfilePicColor;
  img: ProfilePicImg;
};

export enum UserRole {
  Admin = 'ADMIN',
  District = 'DISTRICT',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  User = 'USER',
}

export type UserSciopsData = {
  __typename?: 'UserSciopsData';
  firstPlayed?: Maybe<Scalars['DateTime']['output']>;
  gameSaveData?: Maybe<SaveData>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  numLogins?: Maybe<Scalars['Float']['output']>;
  totalTime?: Maybe<Scalars['Float']['output']>;
};

export type UserSnapshotInfo = {
  __typename?: 'UserSnapshotInfo';
  addrState?: Maybe<Scalars['String']['output']>;
  analyticsSnapshot: AnalyticsSnapshot;
  classroomId?: Maybe<Scalars['String']['output']>;
  classroomSubject?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  sciopsSnapshot: SciopsSnapshot;
  teacherEmail?: Maybe<Scalars['String']['output']>;
};

export enum UserState {
  Character = 'CHARACTER',
  IntroComic = 'INTRO_COMIC',
  Ready = 'READY',
  ResetPassword = 'RESET_PASSWORD',
  Unverified = 'UNVERIFIED',
}

export type UserSurveyInvite = {
  __typename?: 'UserSurveyInvite';
  creationDate: Scalars['DateTime']['output'];
  surveyUniqueName: Scalars['String']['output'];
};

export type UserVerification = {
  __typename?: 'UserVerification';
  email: Scalars['Boolean']['output'];
  profile: Scalars['Boolean']['output'];
};

export type UserVerificationDto = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  profile?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserWithRole = {
  __typename?: 'UserWithRole';
  role: UserRole;
  user: User;
};

export type UsersConnectionDto = {
  __typename?: 'UsersConnectionDto';
  edges: Array<UserEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars['Float']['output'];
};

export type WebStats = {
  __typename?: 'WebStats';
  _id: Scalars['String']['output'];
  classrooms: Array<WebStatsClassroom>;
  dateRecorded: Scalars['DateTime']['output'];
  groups: Array<WebStatsGroup>;
  numDailyLogins: Scalars['Int']['output'];
  users: WebStatsUsers;
};

export type WebStatsClassroom = {
  __typename?: 'WebStatsClassroom';
  classroom: Classroom;
  numStudents: Scalars['Int']['output'];
  numTeachers: Scalars['Int']['output'];
};

export type WebStatsGroup = {
  __typename?: 'WebStatsGroup';
  group: Group;
  name: Scalars['String']['output'];
  numClassrooms: Scalars['Int']['output'];
  numDistrictAdmins: Scalars['Int']['output'];
  numStudents: Scalars['Int']['output'];
  numTeachers: Scalars['Int']['output'];
};

export type WebStatsNewTeacher = {
  __typename?: 'WebStatsNewTeacher';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  groupName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type WebStatsUsers = {
  __typename?: 'WebStatsUsers';
  roleTotals: Array<WebStatsUsersRoles>;
  totalUsers: Scalars['Int']['output'];
};

export type WebStatsUsersRoles = {
  __typename?: 'WebStatsUsersRoles';
  count: Scalars['Int']['output'];
  role: UserRole;
};

export type GetAssignmentByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetAssignmentByIdQuery = {
  __typename?: 'Query';
  getAssignmentById?: {
    __typename?: 'Assignment';
    _id: string;
    type: AssignmentType;
    iconUrl: string;
    name: string;
    description?: string | null;
    awsLink: string;
    aId: string;
    creationDate: any;
    isActive: boolean;
    isBrotliCompressed?: boolean | null;
    questions: Array<{
      __typename?: 'AssignmentQuestion';
      qId: string;
      number: number;
      text: string;
      screenshotUrl: string;
      type: AssignmentQuestionType;
      answerType?: AssignmentAnswerType | null;
      acceptedAnswers: any;
      weight: number;
      possibleAnswerChoices?: Array<string> | null;
      section: { __typename?: 'AssignmentSection'; name: string; sId: string; weight: number };
      standards: Array<{ __typename?: 'AssignmentStandard'; state: string; code: string; description: string }>;
    }>;
    version: { __typename?: 'AssignmentVersion'; major: number; minor: number; hotfix: number; releaseDate: any };
  } | null;
};

export type GetAssignmentByDtoQueryVariables = Exact<{
  dto: GetSingleAssignmentDto;
}>;

export type GetAssignmentByDtoQuery = {
  __typename?: 'Query';
  getAssignmentByDto?: {
    __typename?: 'Assignment';
    _id: string;
    type: AssignmentType;
    iconUrl: string;
    name: string;
    awsLink: string;
    aId: string;
    version: { __typename?: 'AssignmentVersion'; major: number; minor: number; hotfix: number; releaseDate: any };
  } | null;
};

export type UpdateStudentActivityResourceCategoryTypeMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  resourceCategoryType?: InputMaybe<ResourceCategoryType>;
}>;

export type UpdateStudentActivityResourceCategoryTypeMutation = {
  __typename?: 'Mutation';
  updateStudentActivityResourceCategoryType: { __typename?: 'StudentActivity'; _id: string };
};

export type UpdateStudentActivityReviewResourceTypeMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  reviewResourceType?: InputMaybe<ReviewResourceType>;
}>;

export type UpdateStudentActivityReviewResourceTypeMutation = { __typename?: 'Mutation'; updateStudentActivityReviewResourceType: { __typename?: 'StudentActivity'; _id: string } };

export type UpdateStudentActivitySkillConnectionTagsMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  skillConnectionTags: Array<SkillConnectionTagType> | SkillConnectionTagType;
}>;

export type UpdateStudentActivitySkillConnectionTagsMutation = {
  __typename?: 'Mutation';
  updateStudentActivitySkillConnectionTags: { __typename?: 'StudentActivity'; _id: string };
};

export type GetManyAssignmentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyAssignmentsQuery = {
  __typename?: 'Query';
  getManyAssignmentsByDto: Array<{
    __typename?: 'Assignment';
    _id: string;
    aId: string;
    awsLink: string;
    creationDate: any;
    name: string;
    iconUrl: string;
    type: AssignmentType;
    questions: Array<{
      __typename?: 'AssignmentQuestion';
      qId: string;
      number: number;
      text: string;
      screenshotUrl: string;
      type: AssignmentQuestionType;
      answerType?: AssignmentAnswerType | null;
      acceptedAnswers: any;
      weight: number;
      section: { __typename?: 'AssignmentSection'; sId: string; name: string; weight: number };
      standards: Array<{ __typename?: 'AssignmentStandard'; state: string; code: string; description: string }>;
    }>;
    version: { __typename?: 'AssignmentVersion'; major: number; minor: number; hotfix: number; releaseDate: any };
  }>;
};

export type GetClassroomAssignedSpQsQueryVariables = Exact<{
  dto: GetAssignmentManagersDto;
}>;

export type GetClassroomAssignedSpQsQuery = {
  __typename?: 'Query';
  getManyAssignmentManagers: Array<{
    __typename?: 'AssignmentManager';
    _id: string;
    creationDate: any;
    isClosed: boolean;
    assignment: { __typename?: 'Assignment'; _id: string; name: string; iconUrl: string };
    defaults: { __typename?: 'AssignmentControls'; allowLate: boolean; dueDate: any; maxAttempts: number; startDate: any };
    submissions: Array<{ __typename?: 'SubmissionManager'; _id: string; isFinished: boolean; submissions: Array<{ __typename?: 'AssignmentSubmission'; _id: string }> }>;
  }>;
};

export type GetManySubmissionManagersAdminQueryVariables = Exact<{
  dto: GetManySubmissionManagersDto;
}>;

export type GetManySubmissionManagersAdminQuery = {
  __typename?: 'Query';
  getManySubmissionManagers: Array<{
    __typename?: 'SubmissionManager';
    _id: string;
    isFinished: boolean;
    completionDate?: any | null;
    assignment: { __typename?: 'Assignment'; iconUrl: string; name: string };
    submissions: Array<{ __typename?: 'AssignmentSubmission'; _id: string }>;
    controls: { __typename?: 'AssignmentControls'; dueDate: any; maxAttempts: number; startDate: any; allowLate: boolean };
  }>;
};

export type AddStudentActivityToUnitMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
}>;

export type AddStudentActivityToUnitMutation = { __typename?: 'Mutation'; addStudentActivityToUnit: { __typename?: 'ActivityUnit'; _id: string } };

export type AddActivityLinkToStudentActivityMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  createActivityLinkDto: CreateActivityLinkDto;
}>;

export type AddActivityLinkToStudentActivityMutation = { __typename?: 'Mutation'; addActivityLinkToStudentActivity: { __typename?: 'StudentActivity'; _id: string } };

export type RemoveActivityLinkFromStudentActivityMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  activityLinkId: Scalars['String']['input'];
}>;

export type RemoveActivityLinkFromStudentActivityMutation = { __typename?: 'Mutation'; removeActivityLinkFromStudentActivity: { __typename?: 'StudentActivity'; _id: string } };

export type AddOnlineActivityToStudentActivityMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  createOnlineActivityDto: CreateOnlineActivityDto;
}>;

export type AddOnlineActivityToStudentActivityMutation = { __typename?: 'Mutation'; addOnlineActivityToStudentActivity: { __typename?: 'StudentActivity'; _id: string } };

export type RemoveOnlineActivityFromStudentActivityMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
}>;

export type RemoveOnlineActivityFromStudentActivityMutation = { __typename?: 'Mutation'; removeOnlineActivityFromStudentActivity: { __typename?: 'StudentActivity'; _id: string } };

export type AddGroupDomainMutationVariables = Exact<{
  getGroup: GetGroupDto;
  domainToAdd: Scalars['String']['input'];
}>;

export type AddGroupDomainMutation = { __typename?: 'Mutation'; addGroupDomain: { __typename?: 'Group'; _id: string } };

export type AddTagToStudentActivityMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  activityTag: Scalars['String']['input'];
}>;

export type AddTagToStudentActivityMutation = { __typename?: 'Mutation'; addTagToStudentActivity: { __typename?: 'StudentActivity'; _id: string } };

export type AddUserSurveyInviteMutationVariables = Exact<{
  getUser: GetUserDto;
  surveyName: Scalars['String']['input'];
}>;

export type AddUserSurveyInviteMutation = {
  __typename?: 'Mutation';
  addUserSurveyInvite: { __typename?: 'User'; _id: string; surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }> };
};

export type GetAdminHomeInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminHomeInfoQuery = {
  __typename?: 'Query';
  districtCount: number;
  teachersCount: number;
  studentsCount: number;
  adminsCount: number;
  groupsCount: number;
  classroomsCount: number;
  usersCount: number;
  assignmentCount: number;
  studentActivitiesCount: number;
  newslettersCount: number;
};

export type ChangeActivityLinkPositionInArrayMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  currentIndex: Scalars['Float']['input'];
  destinationIndex: Scalars['Float']['input'];
}>;

export type ChangeActivityLinkPositionInArrayMutation = { __typename?: 'Mutation'; changeActivityLinkPositionInArray: { __typename?: 'StudentActivity'; _id: string } };

export type ChangeActivityPositionInArrayMutationVariables = Exact<{
  getActivityUnitDto: GetActivityUnitDto;
  currentIndex: Scalars['Float']['input'];
  destinationIndex: Scalars['Float']['input'];
}>;

export type ChangeActivityPositionInArrayMutation = { __typename?: 'Mutation'; changeActivityPositionInArray: { __typename?: 'ActivityUnit'; _id: string } };

export type ChangeUnitPositionInArrayMutationVariables = Exact<{
  getActivityCourseDto: GetActivityCourseDto;
  currentIndex: Scalars['Float']['input'];
  destinationIndex: Scalars['Float']['input'];
}>;

export type ChangeUnitPositionInArrayMutation = { __typename?: 'Mutation'; changeUnitPositionInArray: { __typename?: 'ActivityCourse'; _id: string } };

export type CreateAdminUserMutationVariables = Exact<{
  adminSignUpData: CreateAdminDto;
  creationType: UserCreationType;
}>;

export type CreateAdminUserMutation = { __typename?: 'Mutation'; createAdminUser: { __typename?: 'User'; _id: string } };

export type CreateCourseMutationVariables = Exact<{
  createActivityCourseDto: CreateActivityCourseDto;
}>;

export type CreateCourseMutation = {
  __typename?: 'Mutation';
  createActivityCourse: { __typename?: 'ActivityCourse'; _id: string; name: string; code: string; units: Array<{ __typename?: 'ActivityUnit'; _id: string }> };
};

export type CreateStudentActivityMutationVariables = Exact<{
  createStudentActivityDto: CreateStudentActivityDto;
  activityUnitId: Scalars['String']['input'];
}>;

export type CreateStudentActivityMutation = { __typename?: 'Mutation'; createStudentActivity: { __typename?: 'ActivityCourse'; _id: string } };

export type CreateUnitMutationVariables = Exact<{
  createActivityUnitDto: CreateActivityUnitDto;
  courseId: Scalars['String']['input'];
}>;

export type CreateUnitMutation = { __typename?: 'Mutation'; createActivityUnit: { __typename?: 'ActivityCourse'; _id: string } };

export type DeleteCourseMutationVariables = Exact<{
  activityCourseId: Scalars['String']['input'];
}>;

export type DeleteCourseMutation = { __typename?: 'Mutation'; deleteActivityCourse: { __typename?: 'ActivityCourse'; _id: string } };

export type DeleteStudentActivityMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
}>;

export type DeleteStudentActivityMutation = { __typename?: 'Mutation'; deleteStudentActivity: { __typename?: 'ActivityUnit'; _id: string } };

export type DeleteUnitMutationVariables = Exact<{
  activityUnitId: Scalars['String']['input'];
}>;

export type DeleteUnitMutation = { __typename?: 'Mutation'; deleteActivityUnit: { __typename?: 'ActivityUnit'; _id: string } };

export type GetAllCoursesAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCoursesAdminQuery = {
  __typename?: 'Query';
  getActivityCourses: Array<{ __typename?: 'ActivityCourse'; _id: string; name: string; code: string; units: Array<{ __typename?: 'ActivityUnit'; _id: string }> }>;
};

export type GetActivitiesQueryVariables = Exact<{
  getStudentActivitiesDto: GetStudentActivitiesDto;
}>;

export type GetActivitiesQuery = {
  __typename?: 'Query';
  getStudentActivities: Array<{
    __typename?: 'StudentActivity';
    _id: string;
    name: string;
    isActive: boolean;
    description: string;
    thumbnail?: string | null;
    creationDate?: any | null;
    isAdvanced: boolean;
    studentPortalAssetLink?: string | null;
    recommendationScore?: number | null;
    tags: Array<string>;
    skillConnectionTags?: Array<SkillConnectionTagType> | null;
    resourceCategory?: ResourceCategoryType | null;
    courseName: string;
    units: Array<{ __typename?: 'ActivityUnit'; _id: string; name: string; courses: Array<{ __typename?: 'ActivityCourse'; _id: string; name: string }> }>;
    links: Array<{ __typename?: 'ActivityLink'; _id: string; name: string; link: string; btnType: ButtonType }>;
    assignment?: { __typename?: 'Assignment'; _id: string; name: string } | null;
    onlineActivity?: { __typename?: 'OnlineLink'; name: string; aId: string } | null;
  }>;
};

export type GetActivityQueryVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
}>;

export type GetActivityQuery = {
  __typename?: 'Query';
  getStudentActivity: {
    __typename?: 'StudentActivity';
    _id: string;
    name: string;
    isActive: boolean;
    description: string;
    thumbnail?: string | null;
    creationDate?: any | null;
    lastUpdated?: any | null;
    isAdvanced: boolean;
    tags: Array<string>;
    studentPortalAssetLink?: string | null;
    resourceCategory?: ResourceCategoryType | null;
    reviewResource?: ReviewResourceType | null;
    skillConnectionTags?: Array<SkillConnectionTagType> | null;
    recommendationScore?: number | null;
    unitName: string;
    estimatedTime?: EstimatedTime | null;
    units: Array<{ __typename?: 'ActivityUnit'; _id: string; name: string; courses: Array<{ __typename?: 'ActivityCourse'; name: string; code: string }> }>;
    links: Array<{ __typename?: 'ActivityLink'; _id: string; name: string; link: string; btnType: ButtonType }>;
    assignment?: { __typename?: 'Assignment'; _id: string; name: string } | null;
    onlineActivity?: { __typename?: 'OnlineLink'; name: string; aId: string } | null;
  };
};

export type GetActivityRatingsQueryVariables = Exact<{
  getActivityRatingsDto: GetActivityRatingsDto;
}>;

export type GetActivityRatingsQuery = {
  __typename?: 'Query';
  getActivityRatings: Array<{
    __typename?: 'ActivityRating';
    email: string;
    dateSubmitted: any;
    easeOfUse: number;
    studentEngagement: number;
    gradeAppropriate: number;
    effectiveness: number;
    description: string;
  }>;
};

export type GetAllSurveyDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSurveyDataQuery = { __typename?: 'Query'; getAllSurveyData: Array<{ __typename?: 'SurveyData'; name: string; surveyId: string }> };

export type GetAllSurveysForUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type GetAllSurveysForUserQuery = {
  __typename?: 'Query';
  getAllSurveysForUser: Array<{
    __typename?: 'SurveyResults';
    _id: string;
    surveyId: string;
    completed?: boolean | null;
    completedDate?: any | null;
    consent?: { __typename?: 'SurveyConsent'; date: any } | null;
  }>;
};

export type GetUnitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUnitsQuery = {
  __typename?: 'Query';
  getActivityUnits: Array<{
    __typename?: 'ActivityUnit';
    _id: string;
    name: string;
    code: string;
    inProgress: boolean;
    isActive: boolean;
    activities: Array<{ __typename?: 'StudentActivity'; _id: string }>;
    courses: Array<{ __typename?: 'ActivityCourse'; _id: string; name: string }>;
  }>;
};

export type GetClassroomDetailsAdminQueryVariables = Exact<{
  getClassroom: GetClassroomDto;
}>;

export type GetClassroomDetailsAdminQuery = {
  __typename?: 'Query';
  getClassroomAdmin?: {
    __typename?: 'Classroom';
    _id: string;
    period: string;
    subject: string;
    startDate?: any | null;
    endDate?: any | null;
    schoolDistrictSemester?: string | null;
    name: string;
    color: ClassroomColor;
    uuid: string;
    classCode: string;
    invites: Array<string>;
    creationDate: any;
    group: {
      __typename?: 'Group';
      _id: string;
      creationDate: any;
      domains: Array<string>;
      hasSciOps: boolean;
      isSurveysOn: boolean;
      isTeacherSignupOn: boolean;
      name: string;
      uuid: string;
      address: { __typename?: 'GroupAddress'; county?: string | null; state?: string | null };
      classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
      districtAdmins: Array<{ __typename?: 'User'; _id: string }>;
      groupCodes: Array<{ __typename?: 'NewGroupCode'; _id: string }>;
      students: Array<{ __typename?: 'User'; _id: string }>;
      teachers: Array<{ __typename?: 'User'; _id: string }>;
      districtSemesters: Array<{
        __typename?: 'DistrictSemester';
        _id: string;
        startDate: any;
        endDate: any;
        postSurveyOn: boolean;
        name?: string | null;
        postSurveyWindow: { __typename?: 'CalendarTimeWindow'; lengthOfTime: number; measurementOfTime: MeasurementOfTimeType };
      }>;
    };
    students: Array<{
      __typename?: 'User';
      _id: string;
      apiToken?: string | null;
      developer: boolean;
      email: string;
      role: UserRole;
      state: UserState;
      completedTours: Array<string>;
      analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
      classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
      group?: { __typename?: 'Group'; _id: string } | null;
      profile: {
        __typename?: 'UserProfile';
        character?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        school?: string | null;
        pictureUrl: { __typename?: 'UserProfilePictureUrl'; color: ProfilePicColor; img: ProfilePicImg };
      };
      sciopsData: {
        __typename?: 'UserSciopsData';
        lastLogin?: any | null;
        firstPlayed?: any | null;
        numLogins?: number | null;
        totalTime?: number | null;
        gameSaveData?: {
          __typename?: 'SaveData';
          career: Array<{ __typename?: 'Career'; careerKey: string }>;
          characters: {
            __typename?: 'SaveDataCharacters';
            charAki?: {
              __typename?: 'SaveDataCharacter';
              appCurrency: number;
              charKey: string;
              currency: number;
              scientist: string;
              selCurrency: number;
              unitVariant: number;
              items: Array<{ __typename?: 'CharacterItem'; defaultData: number; gear: string; selData: number }>;
              upgrades: Array<{ __typename?: 'CharacterUpgrade'; defaultIndex: number; gear: string; group: string; index: number; max: number; upgradeKey: string }>;
            } | null;
            charNicole?: {
              __typename?: 'SaveDataCharacter';
              appCurrency: number;
              charKey: string;
              currency: number;
              scientist: string;
              selCurrency: number;
              unitVariant: number;
              items: Array<{ __typename?: 'CharacterItem'; defaultData: number; gear: string; selData: number }>;
              upgrades: Array<{ __typename?: 'CharacterUpgrade'; defaultIndex: number; gear: string; group: string; index: number; max: number; upgradeKey: string }>;
            } | null;
            charZia?: {
              __typename?: 'SaveDataCharacter';
              appCurrency: number;
              charKey: string;
              currency: number;
              scientist: string;
              selCurrency: number;
              unitVariant: number;
              items: Array<{ __typename?: 'CharacterItem'; defaultData: number; gear: string; selData: number }>;
              upgrades: Array<{ __typename?: 'CharacterUpgrade'; defaultIndex: number; gear: string; group: string; index: number; max: number; upgradeKey: string }>;
            } | null;
          };
          levels: {
            __typename?: 'SaveDataLevels';
            campaign: {
              __typename?: 'LevelGroupDetails';
              category: SciOpsLevelCategory;
              highestLevel: string;
              levels: Array<{
                __typename?: 'Level';
                levelKey: string;
                maxStars: number;
                numComplete: number;
                numEfficientStarsEarned: number;
                numPlays: number;
                numSurvivabilityStarsEarned: number;
                numTurnStarsEarned: number;
              }>;
            };
            challenge: {
              __typename?: 'LevelGroupDetails';
              category: SciOpsLevelCategory;
              highestLevel: string;
              levels: Array<{
                __typename?: 'Level';
                levelKey: string;
                maxStars: number;
                numComplete: number;
                numEfficientStarsEarned: number;
                numPlays: number;
                numSurvivabilityStarsEarned: number;
                numTurnStarsEarned: number;
              }>;
            };
          };
          notifications: {
            __typename?: 'SaveDataNotifications';
            viewedMessages: Array<number>;
            viewedNotifications: Array<number>;
            careerNotifications: Array<{ __typename?: 'Notification'; charKey: string; messageId: number; notificationId: number; type: number; unlockKey: string }>;
            characterNotifications: Array<{ __typename?: 'Notification'; charKey: string; messageId: number; notificationId: number; type: number; unlockKey: string }>;
            gearNotifications: Array<{ __typename?: 'Notification'; charKey: string; messageId: number; notificationId: number; type: number; unlockKey: string }>;
            upgradeNotifications: Array<{ __typename?: 'Notification'; charKey: string; messageId: number; notificationId: number; type: number; unlockKey: string }>;
          };
        } | null;
      };
      surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
      verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    }>;
    teachers: Array<{
      __typename?: 'User';
      _id: string;
      apiToken?: string | null;
      developer: boolean;
      email: string;
      role: UserRole;
      state: UserState;
      completedTours: Array<string>;
      analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
      classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
      group?: { __typename?: 'Group'; _id: string } | null;
      profile: {
        __typename?: 'UserProfile';
        character?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        school?: string | null;
        pictureUrl: { __typename?: 'UserProfilePictureUrl'; color: ProfilePicColor; img: ProfilePicImg };
      };
      sciopsData: { __typename?: 'UserSciopsData'; lastLogin?: any | null; firstPlayed?: any | null; numLogins?: number | null; totalTime?: number | null };
      surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
      verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    }>;
  } | null;
};

export type GetAllClassroomsAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllClassroomsAdminQuery = {
  __typename?: 'Query';
  getClassrooms: Array<{
    __typename?: 'Classroom';
    _id: string;
    classCode: string;
    color: ClassroomColor;
    creationDate: any;
    endDate?: any | null;
    invites: Array<string>;
    name: string;
    period: string;
    startDate?: any | null;
    numStudents: number;
    subject: string;
    numTeachers: number;
    uuid: string;
    group: { __typename?: 'Group'; _id: string };
  }>;
};

export type GetCourseQueryVariables = Exact<{
  getActivityCourseDto: GetActivityCourseDto;
}>;

export type GetCourseQuery = {
  __typename?: 'Query';
  getActivityCourse: {
    __typename?: 'ActivityCourse';
    _id: string;
    name: string;
    code: string;
    units: Array<{
      __typename?: 'ActivityUnit';
      _id: string;
      name: string;
      code: string;
      inProgress: boolean;
      isActive: boolean;
      activities: Array<{ __typename?: 'StudentActivity'; _id: string; recommendationScore?: number | null }>;
    }>;
  };
};

export type GetGroupsQueryVariables = Exact<{
  getGroupsDto: GetGroupsDto;
}>;

export type GetGroupsQuery = {
  __typename?: 'Query';
  getGroups: Array<{
    __typename?: 'Group';
    _id: string;
    name: string;
    numDistrictAdmins: number;
    numTeachers: number;
    numStudents: number;
    numClassrooms: number;
    isTeacherSignupOn: boolean;
    isSurveysOn: boolean;
    isInPilot: boolean;
    isDemo?: boolean | null;
    creationDate: any;
    address: { __typename?: 'GroupAddress'; state?: string | null; county?: string | null };
    groupCodes: Array<{ __typename?: 'NewGroupCode'; _id: string }>;
  }>;
};

export type GetGroupQueryVariables = Exact<{
  getGroupDto: GetGroupDto;
}>;

export type GetGroupQuery = {
  __typename?: 'Query';
  getGroup?: {
    __typename?: 'Group';
    _id: string;
    name: string;
    numDistrictAdmins: number;
    numTeachers: number;
    numStudents: number;
    numClassrooms: number;
    isTeacherSignupOn: boolean;
    isSurveysOn: boolean;
    creationDate: any;
    address: { __typename?: 'GroupAddress'; state?: string | null; county?: string | null };
    groupCodes: Array<{ __typename?: 'NewGroupCode'; _id: string }>;
  } | null;
};

export type GetGroupAdminQueryVariables = Exact<{
  getGroupDto: GetGroupDto;
}>;

export type GetGroupAdminQuery = {
  __typename?: 'Query';
  getGroupAdmin?: {
    __typename?: 'Group';
    _id: string;
    numStudents: number;
    numDistrictAdmins: number;
    numTeachers: number;
    numClassrooms: number;
    creationDate: any;
    domains: Array<string>;
    hasSciOps: boolean;
    isSurveysOn: boolean;
    isInPilot: boolean;
    isDemo?: boolean | null;
    name: string;
    address: { __typename?: 'GroupAddress'; county?: string | null; state?: string | null };
    groupCodes: Array<{
      __typename?: 'NewGroupCode';
      _id: string;
      code: string;
      type: GroupCodeType;
      creationDate: any;
      expirationDate?: any | null;
      isActive: boolean;
      numLicenses?: number | null;
      users: Array<{ __typename?: 'User'; _id: string }>;
    }>;
    districtSemesters: Array<{
      __typename?: 'DistrictSemester';
      _id: string;
      startDate: any;
      endDate: any;
      postSurveyOn: boolean;
      isDefault: boolean;
      name?: string | null;
      postSurveyWindow: { __typename?: 'CalendarTimeWindow'; lengthOfTime: number; measurementOfTime: MeasurementOfTimeType };
    }>;
    clever?: { __typename?: 'CleverGroup'; id?: string | null } | null;
    classLink?: { __typename?: 'ClassLinkGroup'; sourcedId?: string | null; tenantId?: string | null; appId?: string | null; lastSync?: any | null } | null;
    surveyInfo?: {
      __typename?: 'GroupSurveyInvites';
      middleSchoolPreSurvey: string;
      middleSchoolPostSurvey: string;
      highSchoolPreSurvey: string;
      highSchoolPostSurvey: string;
    } | null;
  } | null;
};

export type GetGroupNameQueryVariables = Exact<{
  getGroupDto: GetGroupDto;
}>;

export type GetGroupNameQuery = { __typename?: 'Query'; getGroup?: { __typename?: 'Group'; name: string } | null };

export type GetGroupsNameIdQueryVariables = Exact<{
  getGroupsDto: GetGroupsDto;
}>;

export type GetGroupsNameIdQuery = { __typename?: 'Query'; getGroups: Array<{ __typename?: 'Group'; _id: string; name: string }> };

export type GetNewslettersQueryVariables = Exact<{ [key: string]: never }>;

export type GetNewslettersQuery = { __typename?: 'Query'; getNewsletters: Array<{ __typename?: 'Newsletter'; storageURL: string; createdAt: any }> };

export type GetPortalNotificationsForUserQueryVariables = Exact<{
  user: Scalars['String']['input'];
}>;

export type GetPortalNotificationsForUserQuery = {
  __typename?: 'Query';
  getPortalNotificationsForUser: Array<{
    __typename?: 'PortalNotification';
    _id: string;
    title: string;
    type: NotificationType;
    read: boolean;
    deleted: boolean;
    updateTime?: any | null;
    creationDate: any;
    message: { __typename?: 'Message'; key: string };
  }>;
};

export type GetUnitQueryVariables = Exact<{
  getActivityUnitDto: GetActivityUnitDto;
}>;

export type GetUnitQuery = {
  __typename?: 'Query';
  getActivityUnit?: {
    __typename?: 'ActivityUnit';
    _id: string;
    name: string;
    isActive: boolean;
    inProgress: boolean;
    code: string;
    pacingGuide: string;
    activities: Array<{
      __typename?: 'StudentActivity';
      _id: string;
      name: string;
      isActive: boolean;
      isAdvanced: boolean;
      creationDate?: any | null;
      thumbnail?: string | null;
      recommendationScore?: number | null;
      assignment?: { __typename?: 'Assignment'; _id: string } | null;
      onlineActivity?: { __typename?: 'OnlineLink'; aId: string } | null;
    }>;
    stateStandards: Array<{
      __typename?: 'StateStandard';
      _id: string;
      abbr: string;
      name: string;
      standards?: Array<{
        __typename?: 'UnitStandard';
        _id: string;
        code?: string | null;
        description: string;
        subStandards?: Array<{ __typename?: 'SubStandard'; code?: string | null; description: string }> | null;
      }> | null;
    }>;
    courses: Array<{ __typename?: 'ActivityCourse'; _id: string; code: string }>;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  getUserDto: GetUserDto;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    _id: string;
    email: string;
    isDemo?: boolean | null;
    state: UserState;
    role: UserRole;
    profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null; school?: string | null };
    analytics: { __typename?: 'UserAnalytics'; numLogins: number; lastLogin: any; creationDate: any };
  } | null;
};

export type GetUserDetailsAdminQueryVariables = Exact<{
  getUserDto: GetUserDto;
}>;

export type GetUserDetailsAdminQuery = {
  __typename?: 'Query';
  getUserAdmin?: {
    __typename?: 'User';
    _id: string;
    apiToken?: string | null;
    developer: boolean;
    isDemo?: boolean | null;
    email: string;
    role: UserRole;
    state: UserState;
    completedTours: Array<string>;
    analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
    classrooms: Array<{
      __typename?: 'Classroom';
      _id: string;
      classCode: string;
      color: ClassroomColor;
      creationDate: any;
      endDate?: any | null;
      invites: Array<string>;
      name: string;
      period: string;
      startDate?: any | null;
      subject: string;
      uuid: string;
      group: { __typename?: 'Group'; _id: string };
      students: Array<{ __typename?: 'User'; _id: string }>;
      teachers: Array<{ __typename?: 'User'; _id: string; email: string }>;
    }>;
    ssoProfile?: { __typename?: 'SsoProfile'; googleSub: string } | null;
    group?: {
      __typename?: 'Group';
      _id: string;
      creationDate: any;
      domains: Array<string>;
      hasSciOps: boolean;
      isSurveysOn: boolean;
      isTeacherSignupOn: boolean;
      name: string;
      uuid: string;
      address: { __typename?: 'GroupAddress'; county?: string | null; state?: string | null };
      classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
      districtAdmins: Array<{ __typename?: 'User'; _id: string }>;
      groupCodes: Array<{ __typename?: 'NewGroupCode'; _id: string }>;
      students: Array<{ __typename?: 'User'; _id: string }>;
      teachers: Array<{ __typename?: 'User'; _id: string }>;
    } | null;
    profile: {
      __typename?: 'UserProfile';
      character?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      school?: string | null;
      pictureUrl: { __typename?: 'UserProfilePictureUrl'; color: ProfilePicColor; img: ProfilePicImg };
    };
    eduProfile?: {
      __typename?: 'UserEduProfile';
      educationLevel: EduLvl;
      gradesTaught: Array<string>;
      subjectsTaught: Array<string>;
      courseCategory: Array<string>;
      activityType: Array<string>;
      units: Array<string>;
    } | null;
    sciopsData: {
      __typename?: 'UserSciopsData';
      lastLogin?: any | null;
      firstPlayed?: any | null;
      numLogins?: number | null;
      totalTime?: number | null;
      gameSaveData?: {
        __typename?: 'SaveData';
        career: Array<{ __typename?: 'Career'; careerKey: string }>;
        characters: {
          __typename?: 'SaveDataCharacters';
          charAki?: {
            __typename?: 'SaveDataCharacter';
            appCurrency: number;
            charKey: string;
            currency: number;
            scientist: string;
            selCurrency: number;
            unitVariant: number;
            items: Array<{ __typename?: 'CharacterItem'; defaultData: number; gear: string; selData: number }>;
            upgrades: Array<{ __typename?: 'CharacterUpgrade'; defaultIndex: number; gear: string; group: string; index: number; max: number; upgradeKey: string }>;
          } | null;
          charNicole?: {
            __typename?: 'SaveDataCharacter';
            appCurrency: number;
            charKey: string;
            currency: number;
            scientist: string;
            selCurrency: number;
            unitVariant: number;
            items: Array<{ __typename?: 'CharacterItem'; defaultData: number; gear: string; selData: number }>;
            upgrades: Array<{ __typename?: 'CharacterUpgrade'; defaultIndex: number; gear: string; group: string; index: number; max: number; upgradeKey: string }>;
          } | null;
          charZia?: {
            __typename?: 'SaveDataCharacter';
            appCurrency: number;
            charKey: string;
            currency: number;
            scientist: string;
            selCurrency: number;
            unitVariant: number;
            items: Array<{ __typename?: 'CharacterItem'; defaultData: number; gear: string; selData: number }>;
            upgrades: Array<{ __typename?: 'CharacterUpgrade'; defaultIndex: number; gear: string; group: string; index: number; max: number; upgradeKey: string }>;
          } | null;
        };
        levels: {
          __typename?: 'SaveDataLevels';
          campaign: {
            __typename?: 'LevelGroupDetails';
            category: SciOpsLevelCategory;
            highestLevel: string;
            levels: Array<{
              __typename?: 'Level';
              levelKey: string;
              maxStars: number;
              numComplete: number;
              numEfficientStarsEarned: number;
              numPlays: number;
              numSurvivabilityStarsEarned: number;
              numTurnStarsEarned: number;
            }>;
          };
          challenge: {
            __typename?: 'LevelGroupDetails';
            category: SciOpsLevelCategory;
            highestLevel: string;
            levels: Array<{
              __typename?: 'Level';
              levelKey: string;
              maxStars: number;
              numComplete: number;
              numEfficientStarsEarned: number;
              numPlays: number;
              numSurvivabilityStarsEarned: number;
              numTurnStarsEarned: number;
            }>;
          };
        };
        notifications: {
          __typename?: 'SaveDataNotifications';
          viewedMessages: Array<number>;
          viewedNotifications: Array<number>;
          careerNotifications: Array<{ __typename?: 'Notification'; charKey: string; messageId: number; notificationId: number; type: number; unlockKey: string }>;
          characterNotifications: Array<{ __typename?: 'Notification'; charKey: string; messageId: number; notificationId: number; type: number; unlockKey: string }>;
          gearNotifications: Array<{ __typename?: 'Notification'; charKey: string; messageId: number; notificationId: number; type: number; unlockKey: string }>;
          upgradeNotifications: Array<{ __typename?: 'Notification'; charKey: string; messageId: number; notificationId: number; type: number; unlockKey: string }>;
        };
      } | null;
    };
    surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
    verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    comics?: Array<{ __typename?: 'UserComic'; comicName: string; read: boolean }> | null;
  } | null;
};

export type GetUsersQueryVariables = Exact<{
  getUsersDto: GetUsersDto;
  queryParams?: InputMaybe<FindQueryParams>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'UsersConnectionDto';
    totalCount: number;
    edges: Array<{
      __typename?: 'UserEdge';
      cursor: string;
      node: {
        __typename?: 'User';
        _id: string;
        email: string;
        role: UserRole;
        isDemo?: boolean | null;
        developer: boolean;
        numClassrooms: number;
        state: UserState;
        analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
        group?: { __typename?: 'Group'; _id: string; name: string } | null;
      };
    }>;
    pageInfo: {
      __typename?: 'ConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      pageLimit: number;
      nextPageLink?: { __typename?: 'PageLink'; after?: string | null; size: number } | null;
      prevPageLink?: { __typename?: 'PageLink'; before?: string | null; size: number } | null;
    };
  };
};

export type UsersSearchAdminQueryVariables = Exact<{
  getUsersDto: GetUsersDto;
}>;

export type UsersSearchAdminQuery = {
  __typename?: 'Query';
  getUsers: {
    __typename?: 'UsersConnectionDto';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        _id: string;
        email: string;
        role: UserRole;
        state: UserState;
        isDemo?: boolean | null;
        profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
      };
    }>;
  };
};

export type UsersSearchByNameAdminQueryVariables = Exact<{
  getUsersByNameDto: GetUsersByNameDto;
}>;

export type UsersSearchByNameAdminQuery = {
  __typename?: 'Query';
  getUsersByName: Array<{
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    state: UserState;
    isDemo?: boolean | null;
    profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
  }>;
};

export type MoveUserGroupAdminMutationVariables = Exact<{
  getUserDto: GetUserDto;
  targetGroupDto: GetGroupDto;
}>;

export type MoveUserGroupAdminMutation = { __typename?: 'Mutation'; moveUserToGroup: { __typename?: 'Group'; _id: string } };

export type DeleteClassroomAdminMutationVariables = Exact<{
  getClassroom: GetClassroomDto;
}>;

export type DeleteClassroomAdminMutation = { __typename?: 'Mutation'; deleteClassroom: boolean };

export type RemoveGroupDomainMutationVariables = Exact<{
  getGroup: GetGroupDto;
  domainToRemove: Scalars['String']['input'];
}>;

export type RemoveGroupDomainMutation = { __typename?: 'Mutation'; removeGroupDomain: { __typename?: 'Group'; _id: string } };

export type RemoveStudentAdminMutationVariables = Exact<{
  getClassroom: GetClassroomDto;
  getUser: GetUserDto;
}>;

export type RemoveStudentAdminMutation = { __typename?: 'Mutation'; removeStudent: { __typename?: 'Classroom'; _id: string } };

export type RemoveTagFromStudentActivityMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  activityTag: Scalars['String']['input'];
}>;

export type RemoveTagFromStudentActivityMutation = { __typename?: 'Mutation'; removeTagFromStudentActivity: { __typename?: 'StudentActivity'; _id: string } };

export type RemoveUserByAdminMutationVariables = Exact<{
  userId: GetUserDto;
}>;

export type RemoveUserByAdminMutation = { __typename?: 'Mutation'; removeUserByAdmin: boolean };

export type RemoveUserSurveyInviteMutationVariables = Exact<{
  getUser: GetUserDto;
  surveyName: Scalars['String']['input'];
}>;

export type RemoveUserSurveyInviteMutation = {
  __typename?: 'Mutation';
  removeUserSurveyInvite: { __typename?: 'User'; _id: string; surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }> };
};

export type AddStateStandardToUnitMutationVariables = Exact<{
  getActivityUnitDto: GetActivityUnitDto;
  createStateStandardDto: CreateStateStandardDto;
}>;

export type AddStateStandardToUnitMutation = { __typename?: 'Mutation'; addStateStandardToUnit: { __typename?: 'ActivityUnit'; _id: string } };

export type RemoveStateStandardFromUnitMutationVariables = Exact<{
  getActivityUnitDto: GetActivityUnitDto;
  stateStandardId: Scalars['String']['input'];
}>;

export type RemoveStateStandardFromUnitMutation = { __typename?: 'Mutation'; removeStateStandardFromUnit: { __typename?: 'ActivityUnit'; _id: string } };

export type UpdateStateStandardMutationVariables = Exact<{
  getActivityUnitDto: GetActivityUnitDto;
  stateStandardId: Scalars['String']['input'];
  updateStateStandardDto: UpdateStateStandardDto;
}>;

export type UpdateStateStandardMutation = { __typename?: 'Mutation'; updateStateStandard: { __typename?: 'ActivityUnit'; _id: string } };

export type AddUnitStandardToStateStandardMutationVariables = Exact<{
  getActivityUnitDto: GetActivityUnitDto;
  stateStandardId: Scalars['String']['input'];
  createUnitStandardDto: CreateUnitStandardDto;
}>;

export type AddUnitStandardToStateStandardMutation = { __typename?: 'Mutation'; addUnitStandardToStateStandard: { __typename?: 'ActivityUnit'; _id: string } };

export type RemoveUnitStandardFromStateStandardMutationVariables = Exact<{
  getActivityUnitDto: GetActivityUnitDto;
  stateStandardId: Scalars['String']['input'];
  unitStandardId: Scalars['String']['input'];
}>;

export type RemoveUnitStandardFromStateStandardMutation = { __typename?: 'Mutation'; removeUnitStandardFromStateStandard: { __typename?: 'ActivityUnit'; _id: string } };

export type GetStudentActivitiesInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetStudentActivitiesInfoQuery = { __typename?: 'Query'; coursesCount: number; unitsCount: number; activitiesCount: number };

export type UnlockSciOpsLvlsForClassroomMutationVariables = Exact<{
  getClassroomDto: GetClassroomDto;
  lvlKey: Scalars['String']['input'];
}>;

export type UnlockSciOpsLvlsForClassroomMutation = { __typename?: 'Mutation'; unlockSciOpsLvlsForClassroom: { __typename?: 'Classroom'; _id: string } };

export type UnlockSciOpsLvlsForClassroomsMutationVariables = Exact<{
  classRoomDtoArray: Array<GetClassroomDto> | GetClassroomDto;
  lvlKey: Scalars['String']['input'];
}>;

export type UnlockSciOpsLvlsForClassroomsMutation = { __typename?: 'Mutation'; unlockSciOpsLvlsForClassrooms: boolean };

export type UpdateUnitAndActivitiesIsActiveStatusMutationVariables = Exact<{
  activityUnitId: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
}>;

export type UpdateUnitAndActivitiesIsActiveStatusMutation = { __typename?: 'Mutation'; changeWholeUnitIsActiveStatus: { __typename?: 'ActivityUnit'; _id: string } };

export type UpdateCourseMutationVariables = Exact<{
  getActivityCourseDto: GetActivityCourseDto;
  updateActivityCourseDto: UpdateActivityCourseDto;
}>;

export type UpdateCourseMutation = {
  __typename?: 'Mutation';
  updateActivityCourse: { __typename?: 'ActivityCourse'; _id: string; name: string; code: string; units: Array<{ __typename?: 'ActivityUnit'; _id: string }> };
};

export type UpdateGroupSurveysMutationVariables = Exact<{
  getGroup: GetGroupDto;
  isSurveysOn: Scalars['Boolean']['input'];
}>;

export type UpdateGroupSurveysMutation = { __typename?: 'Mutation'; toggleOnGroupSurveys: { __typename?: 'Group'; _id: string } };

export type UpdateGroupAddressMutationVariables = Exact<{
  getGroup: GetGroupDto;
  createGroupAddress: CreateGroupAddressDto;
}>;

export type UpdateGroupAddressMutation = {
  __typename?: 'Mutation';
  editGroupAddress: {
    __typename?: 'Group';
    _id: string;
    numStudents: number;
    numDistrictAdmins: number;
    numTeachers: number;
    numClassrooms: number;
    creationDate: any;
    domains: Array<string>;
    hasSciOps: boolean;
    isSurveysOn: boolean;
    isInPilot: boolean;
    isDemo?: boolean | null;
    name: string;
    address: { __typename?: 'GroupAddress'; county?: string | null; state?: string | null };
    districtAdmins: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    groupCodes: Array<{
      __typename?: 'NewGroupCode';
      _id: string;
      code: string;
      type: GroupCodeType;
      creationDate: any;
      expirationDate?: any | null;
      isActive: boolean;
      numLicenses?: number | null;
      users: Array<{ __typename?: 'User'; _id: string }>;
    }>;
    students: Array<{ __typename?: 'User'; _id: string; email: string; analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number } }>;
    teachers: Array<{
      __typename?: 'User';
      _id: string;
      numClassrooms: number;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    districtSemesters: Array<{
      __typename?: 'DistrictSemester';
      _id: string;
      startDate: any;
      endDate: any;
      postSurveyOn: boolean;
      isDefault: boolean;
      name?: string | null;
      postSurveyWindow: { __typename?: 'CalendarTimeWindow'; lengthOfTime: number; measurementOfTime: MeasurementOfTimeType };
    }>;
    clever?: { __typename?: 'CleverGroup'; id?: string | null } | null;
    surveyInfo?: {
      __typename?: 'GroupSurveyInvites';
      middleSchoolPreSurvey: string;
      middleSchoolPostSurvey: string;
      highSchoolPreSurvey: string;
      highSchoolPostSurvey: string;
    } | null;
  };
};

export type UpdateGroupNameMutationVariables = Exact<{
  getGroup: GetGroupDto;
  newName: Scalars['String']['input'];
}>;

export type UpdateGroupNameMutation = {
  __typename?: 'Mutation';
  editGroupName: {
    __typename?: 'Group';
    _id: string;
    numStudents: number;
    numDistrictAdmins: number;
    numTeachers: number;
    numClassrooms: number;
    creationDate: any;
    domains: Array<string>;
    hasSciOps: boolean;
    isSurveysOn: boolean;
    isInPilot: boolean;
    isDemo?: boolean | null;
    name: string;
    address: { __typename?: 'GroupAddress'; county?: string | null; state?: string | null };
    districtAdmins: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    groupCodes: Array<{
      __typename?: 'NewGroupCode';
      _id: string;
      code: string;
      type: GroupCodeType;
      creationDate: any;
      expirationDate?: any | null;
      isActive: boolean;
      numLicenses?: number | null;
      users: Array<{ __typename?: 'User'; _id: string }>;
    }>;
    students: Array<{ __typename?: 'User'; _id: string; email: string; analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number } }>;
    teachers: Array<{
      __typename?: 'User';
      _id: string;
      numClassrooms: number;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    districtSemesters: Array<{
      __typename?: 'DistrictSemester';
      _id: string;
      startDate: any;
      endDate: any;
      postSurveyOn: boolean;
      isDefault: boolean;
      name?: string | null;
      postSurveyWindow: { __typename?: 'CalendarTimeWindow'; lengthOfTime: number; measurementOfTime: MeasurementOfTimeType };
    }>;
    clever?: { __typename?: 'CleverGroup'; id?: string | null } | null;
    surveyInfo?: {
      __typename?: 'GroupSurveyInvites';
      middleSchoolPreSurvey: string;
      middleSchoolPostSurvey: string;
      highSchoolPreSurvey: string;
      highSchoolPostSurvey: string;
    } | null;
  };
};

export type UpdateGroupSciOpsAccessMutationVariables = Exact<{
  getGroup: GetGroupDto;
  hasSciOps: Scalars['Boolean']['input'];
}>;

export type UpdateGroupSciOpsAccessMutation = {
  __typename?: 'Mutation';
  setGroupHasSciOps: {
    __typename?: 'Group';
    _id: string;
    numStudents: number;
    numDistrictAdmins: number;
    numTeachers: number;
    numClassrooms: number;
    creationDate: any;
    domains: Array<string>;
    hasSciOps: boolean;
    isSurveysOn: boolean;
    isInPilot: boolean;
    isDemo?: boolean | null;
    name: string;
    address: { __typename?: 'GroupAddress'; county?: string | null; state?: string | null };
    districtAdmins: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    groupCodes: Array<{
      __typename?: 'NewGroupCode';
      _id: string;
      code: string;
      type: GroupCodeType;
      creationDate: any;
      expirationDate?: any | null;
      isActive: boolean;
      numLicenses?: number | null;
      users: Array<{ __typename?: 'User'; _id: string }>;
    }>;
    students: Array<{ __typename?: 'User'; _id: string; email: string; analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number } }>;
    teachers: Array<{
      __typename?: 'User';
      _id: string;
      numClassrooms: number;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    districtSemesters: Array<{
      __typename?: 'DistrictSemester';
      _id: string;
      startDate: any;
      endDate: any;
      postSurveyOn: boolean;
      isDefault: boolean;
      name?: string | null;
      postSurveyWindow: { __typename?: 'CalendarTimeWindow'; lengthOfTime: number; measurementOfTime: MeasurementOfTimeType };
    }>;
    clever?: { __typename?: 'CleverGroup'; id?: string | null } | null;
    surveyInfo?: {
      __typename?: 'GroupSurveyInvites';
      middleSchoolPreSurvey: string;
      middleSchoolPostSurvey: string;
      highSchoolPreSurvey: string;
      highSchoolPostSurvey: string;
    } | null;
  };
};

export type UpdateGroupPilotStatusMutationVariables = Exact<{
  getGroupDto: GetGroupDto;
  isInPilot: Scalars['Boolean']['input'];
}>;

export type UpdateGroupPilotStatusMutation = {
  __typename?: 'Mutation';
  setPilotState: {
    __typename?: 'Group';
    _id: string;
    numStudents: number;
    numDistrictAdmins: number;
    numTeachers: number;
    numClassrooms: number;
    creationDate: any;
    domains: Array<string>;
    hasSciOps: boolean;
    isSurveysOn: boolean;
    isInPilot: boolean;
    isDemo?: boolean | null;
    name: string;
    address: { __typename?: 'GroupAddress'; county?: string | null; state?: string | null };
    districtAdmins: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    groupCodes: Array<{
      __typename?: 'NewGroupCode';
      _id: string;
      code: string;
      type: GroupCodeType;
      creationDate: any;
      expirationDate?: any | null;
      isActive: boolean;
      numLicenses?: number | null;
      users: Array<{ __typename?: 'User'; _id: string }>;
    }>;
    students: Array<{ __typename?: 'User'; _id: string; email: string; analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number } }>;
    teachers: Array<{
      __typename?: 'User';
      _id: string;
      numClassrooms: number;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    districtSemesters: Array<{
      __typename?: 'DistrictSemester';
      _id: string;
      startDate: any;
      endDate: any;
      postSurveyOn: boolean;
      isDefault: boolean;
      name?: string | null;
      postSurveyWindow: { __typename?: 'CalendarTimeWindow'; lengthOfTime: number; measurementOfTime: MeasurementOfTimeType };
    }>;
    clever?: { __typename?: 'CleverGroup'; id?: string | null } | null;
    surveyInfo?: {
      __typename?: 'GroupSurveyInvites';
      middleSchoolPreSurvey: string;
      middleSchoolPostSurvey: string;
      highSchoolPreSurvey: string;
      highSchoolPostSurvey: string;
    } | null;
  };
};

export type UpdateStudentActivityMutationVariables = Exact<{
  studentActivityId: Scalars['String']['input'];
  updateStudentActivityDto: UpdateStudentActivityDto;
}>;

export type UpdateStudentActivityMutation = { __typename?: 'Mutation'; updateStudentActivity: { __typename?: 'StudentActivity'; _id: string } };

export type UpdateSurveyInviteInfoMutationVariables = Exact<{
  getGroup: GetGroupDto;
  updateGroupSurveyInvitesDto: UpdateGroupSurveyInvitesDto;
}>;

export type UpdateSurveyInviteInfoMutation = { __typename?: 'Mutation'; updateSurveyInviteInfo: { __typename?: 'Group'; _id: string } };

export type UpdateTeacherTrainingDatesMutationVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  trainingDate: Scalars['DateTime']['input'];
}>;

export type UpdateTeacherTrainingDatesMutation = { __typename?: 'Mutation'; updateUserTrainingDates: Array<string> };

export type UpdateUnitMutationVariables = Exact<{
  getActivityUnitDto: GetActivityUnitDto;
  updateActivityUnitDto: UpdateActivityUnitDto;
}>;

export type UpdateUnitMutation = { __typename?: 'Mutation'; updateActivityUnit: { __typename?: 'ActivityUnit'; _id: string } };

export type UpdateUserProfileAdminMutationVariables = Exact<{
  getUser: GetUserDto;
  userProfile?: InputMaybe<UserProfileDto>;
  userProfilePicture?: InputMaybe<UserProfilePictureDto>;
}>;

export type UpdateUserProfileAdminMutation = { __typename?: 'Mutation'; updateUserProfile: { __typename?: 'User'; _id: string } };

export type SetUserDeveloperStatusAdminMutationVariables = Exact<{
  getUser: GetUserDto;
  isDev: Scalars['Boolean']['input'];
}>;

export type SetUserDeveloperStatusAdminMutation = { __typename?: 'Mutation'; setUserDeveloperStatus: { __typename?: 'User'; _id: string } };

export type SetUserDemoStatusAdminMutationVariables = Exact<{
  getUser: GetUserDto;
  isDemo: Scalars['Boolean']['input'];
}>;

export type SetUserDemoStatusAdminMutation = { __typename?: 'Mutation'; setUserDemoStatus: { __typename?: 'User'; _id: string } };

export type UpdateUserTourAdminMutationVariables = Exact<{
  getUser: GetUserDto;
  completedToursDto: CompletedToursDto;
}>;

export type UpdateUserTourAdminMutation = { __typename?: 'Mutation'; updateUserTour: { __typename?: 'User'; _id: string } };

export type VerifyAllTeachersByGroupMutationVariables = Exact<{
  groupID: Scalars['String']['input'];
}>;

export type VerifyAllTeachersByGroupMutation = { __typename?: 'Mutation'; verifyAllTeachersByGroup: boolean };

export type CreateGroupMutationVariables = Exact<{
  createGroup: CreateGroupDto;
  createGroupAddress?: InputMaybe<CreateGroupAddressDto>;
}>;

export type CreateGroupMutation = { __typename?: 'Mutation'; createGroup: { __typename?: 'Group'; _id: string } };

export type CreateGroupCodeMutationVariables = Exact<{
  groupId: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  type: GroupCodeType;
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  numLicenses?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreateGroupCodeMutation = { __typename?: 'Mutation'; createGroupCode: { __typename?: 'NewGroupCode'; _id: string } };

export type GetAllGroupsAdminTableQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllGroupsAdminTableQuery = {
  __typename?: 'Query';
  getGroups: Array<{
    __typename?: 'Group';
    _id: string;
    name: string;
    numDistrictAdmins: number;
    numTeachers: number;
    numStudents: number;
    numClassrooms: number;
    isSurveysOn: boolean;
    creationDate: any;
    address: { __typename?: 'GroupAddress'; state?: string | null };
  }>;
};

export type GetGroupByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type GetGroupByNameQuery = { __typename?: 'Query'; getGroupAdmin?: { __typename?: 'Group'; _id: string; name: string } | null };

export type GetGroupClassroomsQueryVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type GetGroupClassroomsQuery = {
  __typename?: 'Query';
  getGroupAdmin?: {
    __typename?: 'Group';
    _id: string;
    classrooms: Array<{
      __typename?: 'Classroom';
      _id: string;
      name: string;
      classCode: string;
      numStudents: number;
      subject: string;
      teachers: Array<{ __typename?: 'User'; email: string }>;
    }>;
  } | null;
};

export type GetGroupDetailsQueryVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type GetGroupDetailsQuery = {
  __typename?: 'Query';
  getGroupAdmin?: {
    __typename?: 'Group';
    _id: string;
    numStudents: number;
    numDistrictAdmins: number;
    numTeachers: number;
    numClassrooms: number;
    creationDate: any;
    domains: Array<string>;
    hasSciOps: boolean;
    isSurveysOn: boolean;
    isInPilot: boolean;
    isDemo?: boolean | null;
    name: string;
    address: { __typename?: 'GroupAddress'; county?: string | null; state?: string | null };
    groupCodes: Array<{
      __typename?: 'NewGroupCode';
      _id: string;
      code: string;
      type: GroupCodeType;
      creationDate: any;
      expirationDate?: any | null;
      isActive: boolean;
      numLicenses?: number | null;
      users: Array<{ __typename?: 'User'; _id: string }>;
    }>;
    districtSemesters: Array<{
      __typename?: 'DistrictSemester';
      _id: string;
      startDate: any;
      endDate: any;
      postSurveyOn: boolean;
      isDefault: boolean;
      name?: string | null;
      postSurveyWindow: { __typename?: 'CalendarTimeWindow'; lengthOfTime: number; measurementOfTime: MeasurementOfTimeType };
    }>;
    clever?: { __typename?: 'CleverGroup'; id?: string | null } | null;
    classLink?: { __typename?: 'ClassLinkGroup'; sourcedId?: string | null; tenantId?: string | null; appId?: string | null; lastSync?: any | null } | null;
    surveyInfo?: {
      __typename?: 'GroupSurveyInvites';
      middleSchoolPreSurvey: string;
      middleSchoolPostSurvey: string;
      highSchoolPreSurvey: string;
      highSchoolPostSurvey: string;
    } | null;
  } | null;
};

export type GetGroupDistrictAdminsQueryVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type GetGroupDistrictAdminsQuery = {
  __typename?: 'Query';
  getGroupAdmin?: {
    __typename?: 'Group';
    _id: string;
    districtAdmins: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
    }>;
  } | null;
};

export type GetGroupStudentsQueryVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type GetGroupStudentsQuery = {
  __typename?: 'Query';
  getGroupAdmin?: {
    __typename?: 'Group';
    _id: string;
    students: Array<{ __typename?: 'User'; _id: string; email: string; analytics: { __typename?: 'UserAnalytics'; numLogins: number; lastLogin: any; creationDate: any } }>;
  } | null;
};

export type GetGroupTeachersQueryVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type GetGroupTeachersQuery = {
  __typename?: 'Query';
  getGroupAdmin?: {
    __typename?: 'Group';
    _id: string;
    teachers: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      numClassrooms: number;
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
      analytics: { __typename?: 'UserAnalytics'; numLogins: number; lastLogin: any; trainingDate?: any | null };
    }>;
  } | null;
};

export type RemoveClassLinkIdMutationVariables = Exact<{
  getGroupDto: GetGroupDto;
}>;

export type RemoveClassLinkIdMutation = { __typename?: 'Mutation'; removeClassLinkId: { __typename?: 'Group'; _id: string } };

export type RemoveCleverIdMutationVariables = Exact<{
  getGroupDto: GetGroupDto;
}>;

export type RemoveCleverIdMutation = { __typename?: 'Mutation'; removeCleverId: { __typename?: 'Group'; _id: string } };

export type RunSisSyncMutationVariables = Exact<{
  getGroupDto: GetGroupDto;
  thirdPartyId: Scalars['String']['input'];
}>;

export type RunSisSyncMutation = { __typename?: 'Mutation'; runSisSync?: boolean | null };

export type SetClassLinkIdMutationVariables = Exact<{
  getGroupDto: GetGroupDto;
  classLinkAppId: Scalars['String']['input'];
  classLinkAccessToken: Scalars['String']['input'];
  classLinkSourcedId: Scalars['String']['input'];
  classLinkTenantId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SetClassLinkIdMutation = {
  __typename?: 'Mutation';
  setClassLinkId: {
    __typename?: 'Group';
    _id: string;
    classLink?: { __typename?: 'ClassLinkGroup'; sourcedId?: string | null; tenantId?: string | null; appId?: string | null; lastSync?: any | null } | null;
  };
};

export type SetCleverIdMutationVariables = Exact<{
  getGroupDto: GetGroupDto;
  cleverId: Scalars['String']['input'];
}>;

export type SetCleverIdMutation = { __typename?: 'Mutation'; setCleverId: { __typename?: 'Group'; _id: string } };

export type UpdateGroupInviteCodeMutationVariables = Exact<{
  _id: Scalars['String']['input'];
  update: UpdateGroupCodeDataDto;
}>;

export type UpdateGroupInviteCodeMutation = { __typename?: 'Mutation'; updateGroupCode: { __typename?: 'NewGroupCode'; _id: string } };

export type UpdateGroupDemoStatusMutationVariables = Exact<{
  getGroupDto: GetGroupDto;
  isDemo: Scalars['Boolean']['input'];
}>;

export type UpdateGroupDemoStatusMutation = {
  __typename?: 'Mutation';
  setDemoState: {
    __typename?: 'Group';
    _id: string;
    numStudents: number;
    numDistrictAdmins: number;
    numTeachers: number;
    numClassrooms: number;
    creationDate: any;
    domains: Array<string>;
    hasSciOps: boolean;
    isSurveysOn: boolean;
    isInPilot: boolean;
    isDemo?: boolean | null;
    name: string;
    address: { __typename?: 'GroupAddress'; county?: string | null; state?: string | null };
    districtAdmins: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    groupCodes: Array<{
      __typename?: 'NewGroupCode';
      _id: string;
      code: string;
      type: GroupCodeType;
      creationDate: any;
      expirationDate?: any | null;
      isActive: boolean;
      numLicenses?: number | null;
      users: Array<{ __typename?: 'User'; _id: string }>;
    }>;
    students: Array<{ __typename?: 'User'; _id: string; email: string; analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number } }>;
    teachers: Array<{
      __typename?: 'User';
      _id: string;
      numClassrooms: number;
      email: string;
      analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null };
    }>;
    districtSemesters: Array<{
      __typename?: 'DistrictSemester';
      _id: string;
      startDate: any;
      endDate: any;
      postSurveyOn: boolean;
      isDefault: boolean;
      name?: string | null;
      postSurveyWindow: { __typename?: 'CalendarTimeWindow'; lengthOfTime: number; measurementOfTime: MeasurementOfTimeType };
    }>;
    clever?: { __typename?: 'CleverGroup'; id?: string | null } | null;
    surveyInfo?: {
      __typename?: 'GroupSurveyInvites';
      middleSchoolPreSurvey: string;
      middleSchoolPostSurvey: string;
      highSchoolPreSurvey: string;
      highSchoolPostSurvey: string;
    } | null;
  };
};

export type AddAssignmentLinkToResourceMutationVariables = Exact<{
  resourceId: Scalars['String']['input'];
  assignmentId: Scalars['String']['input'];
}>;

export type AddAssignmentLinkToResourceMutation = {
  __typename?: 'Mutation';
  addAssignmentToStudentActivity: { __typename?: 'StudentActivity'; _id: string; assignment?: { __typename?: 'Assignment'; _id: string } | null };
};

export type CreateNewAssignmentFromResourceMutationVariables = Exact<{
  createAssignmentDto: CreateAssignmentDto;
}>;

export type CreateNewAssignmentFromResourceMutation = {
  __typename?: 'Mutation';
  createAssignment: {
    __typename?: 'Assignment';
    _id: string;
    type: AssignmentType;
    specialRequirement?: AssignmentSpecialRequirement | null;
    tags?: Array<string> | null;
    iconUrl: string;
    name: string;
    description?: string | null;
    isActive: boolean;
    version: { __typename?: 'AssignmentVersion'; major: number; minor: number; hotfix: number; releaseDate: any };
  };
};

export type GetAssignmentCountQueryVariables = Exact<{
  dto: GetManyAssignmentsDto;
}>;

export type GetAssignmentCountQuery = { __typename?: 'Query'; getAssignmentCount: number };

export type GetAssignmentIdByDtoQueryVariables = Exact<{
  dto: GetSingleAssignmentDto;
}>;

export type GetAssignmentIdByDtoQuery = { __typename?: 'Query'; getAssignmentByDto?: { __typename?: 'Assignment'; _id: string } | null };

export type GetAssignmentLinkedToResourceQueryVariables = Exact<{
  assignmentId: Scalars['String']['input'];
}>;

export type GetAssignmentLinkedToResourceQuery = {
  __typename?: 'Query';
  getAssignmentById?: { __typename?: 'Assignment'; _id: string; name: string; iconUrl: string; type: AssignmentType } | null;
};

export type RemoveAssignmentLinkFromResourceMutationVariables = Exact<{
  resourceId: Scalars['String']['input'];
}>;

export type RemoveAssignmentLinkFromResourceMutation = { __typename?: 'Mutation'; removeAssignmentFromStudentActivity: { __typename?: 'StudentActivity'; _id: string } };

export type GetUserDetailsQueryVariables = Exact<{
  getUserDto: GetUserDto;
}>;

export type GetUserDetailsQuery = {
  __typename?: 'Query';
  getUserAdmin?: {
    __typename?: 'User';
    _id: string;
    developer: boolean;
    isDemo?: boolean | null;
    email: string;
    role: UserRole;
    state: UserState;
    completedTours: Array<string>;
    analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
    classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
    ssoProfile?: { __typename?: 'SsoProfile'; googleSub: string } | null;
    group?: { __typename?: 'Group'; _id: string } | null;
    profile: {
      __typename?: 'UserProfile';
      character?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      school?: string | null;
      pictureUrl: { __typename?: 'UserProfilePictureUrl'; color: ProfilePicColor; img: ProfilePicImg };
    };
    eduProfile?: { __typename?: 'UserEduProfile'; educationLevel: EduLvl; gradesTaught: Array<string>; subjectsTaught: Array<string> } | null;
    sciopsData: {
      __typename?: 'UserSciopsData';
      lastLogin?: any | null;
      firstPlayed?: any | null;
      numLogins?: number | null;
      totalTime?: number | null;
      gameSaveData?: { __typename?: 'SaveData'; _id: string } | null;
    };
    surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
    verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    comics?: Array<{ __typename?: 'UserComic'; comicName: string; read: boolean }> | null;
  } | null;
};

export type GetSalesTeamMembersQueryVariables = Exact<{ [key: string]: never }>;

export type GetSalesTeamMembersQuery = { __typename?: 'Query'; getSalesTeamMembers: Array<{ __typename?: 'SalesTeamMember'; id: SalesTeamMembers; team_member_name: string }> };

export type GetSendGridTemplateListQueryVariables = Exact<{ [key: string]: never }>;

export type GetSendGridTemplateListQuery = {
  __typename?: 'Query';
  getSendGridTemplates: Array<{
    __typename?: 'SendgridTemplatesDto';
    id: string;
    name: string;
    updated_at: string;
    versions: Array<{ __typename?: 'SendGridTemplateVersion'; name: string; active: number; editor: string; subject: string; thumbnail_url?: string | null; updated_at: string }>;
  }>;
};

export type SendSalesEmailBlastMutationVariables = Exact<{
  dynamicData: Array<SalesDynamicData> | SalesDynamicData;
  emailData: SalesSendgridEmailDto;
}>;

export type SendSalesEmailBlastMutation = { __typename?: 'Mutation'; sendSalesEmailBlast?: Array<boolean> | null };

export type CreateDistrictAccountAdminPortalMutationVariables = Exact<{
  districtCreationDTO: TeacherSignupDto;
  creationType: UserCreationType;
}>;

export type CreateDistrictAccountAdminPortalMutation = { __typename?: 'Mutation'; createDistrictAdminPortal: { __typename?: 'User'; _id: string } };

export type CreateDistrictSemesterMutationVariables = Exact<{
  getGroup: GetGroupDto;
  createDistrictSemesterDto: CreateDistrictSemesterDto;
}>;

export type CreateDistrictSemesterMutation = { __typename?: 'Mutation'; createDistrictSemester: { __typename?: 'Group'; _id: string } };

export type CreateTeacherAccountAdminPortalMutationVariables = Exact<{
  teacherCreationDTO: TeacherSignupDto;
  creationType: UserCreationType;
}>;

export type CreateTeacherAccountAdminPortalMutation = { __typename?: 'Mutation'; createTeacherAdminPortal: { __typename?: 'User'; _id: string } };

export type CreatTeacherAccountsMutationVariables = Exact<{
  teacherSignupDataArray: Array<TeacherSignupDto> | TeacherSignupDto;
  password: Scalars['String']['input'];
  creationType: UserCreationType;
}>;

export type CreatTeacherAccountsMutation = { __typename?: 'Mutation'; createTeachers: Array<string> };

export type UpdateDistrictSemesterMutationVariables = Exact<{
  getGroup: GetGroupDto;
  districtSemesterId: Scalars['String']['input'];
  updateDistrictSemesterDto: UpdateDistrictSemesterDto;
}>;

export type UpdateDistrictSemesterMutation = { __typename?: 'Mutation'; updateDistrictSemester: { __typename?: 'Group'; _id: string } };

export type ComparePasswordQueryVariables = Exact<{
  getUser: GetUserDto;
  password: Scalars['String']['input'];
}>;

export type ComparePasswordQuery = { __typename?: 'Query'; compareUserPassword: boolean };

export type CreateClickTrackerMutationVariables = Exact<{
  createClickTrackerDto: CreateClickTrackerDto;
}>;

export type CreateClickTrackerMutation = { __typename?: 'Mutation'; createClickTracker: { __typename?: 'ClickTracker'; _id: string } };

export type ClassroomExistQueryVariables = Exact<{
  getClassroom: GetClassroomDto;
}>;

export type ClassroomExistQuery = { __typename?: 'Query'; classroomExist: boolean };

export type CreateStudentMutationVariables = Exact<{
  createStudent: CreateUserDto;
  classCode: Scalars['String']['input'];
  creationType: UserCreationType;
}>;

export type CreateStudentMutation = { __typename?: 'Mutation'; createStudent: { __typename?: 'User'; _id: string; email: string; role: UserRole } };

export type CreateDistrictUserMutationVariables = Exact<{
  districtSignupData: TeacherSignupDto;
  creationType: UserCreationType;
}>;

export type CreateDistrictUserMutation = {
  __typename?: 'Mutation';
  createDistrictUser?: {
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null; school?: string | null };
    group?: { __typename?: 'Group'; _id: string } | null;
  } | null;
};

export type SendEmailForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SendEmailForgotPasswordMutation = { __typename?: 'Mutation'; sendEmailForgotPassword: boolean };

export type GetForgotPassQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GetForgotPassQuery = { __typename?: 'Query'; getForgotPass: { __typename?: 'User'; email: string } };

export type ProcessForgotPassMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;

export type ProcessForgotPassMutation = { __typename?: 'Mutation'; processForgotPass: { __typename?: 'User'; email: string } };

export type GetMyGroupQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyGroupQuery = {
  __typename?: 'Query';
  getMyGroup: {
    __typename?: 'Group';
    _id: string;
    students: Array<{ __typename?: 'User'; _id: string; email: string; analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number } }>;
    teachers: Array<{
      __typename?: 'User';
      _id: string;
      role: UserRole;
      email: string;
      numClassrooms: number;
      profile: { __typename?: 'UserProfile'; school?: string | null; firstName?: string | null; lastName?: string | null };
      analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
      classrooms: Array<{ __typename?: 'Classroom'; _id: string; students: Array<{ __typename?: 'User'; _id: string }> }>;
    }>;
    classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
  };
};

export type GetMyselfQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyselfQuery = {
  __typename?: 'Query';
  getMe: {
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    developer: boolean;
    completedTours: Array<string>;
    state: UserState;
    numClassrooms: number;
    grade: Array<string>;
    analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
    surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
    profile: {
      __typename?: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      school?: string | null;
      character?: string | null;
      pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor };
    };
    verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    group?: {
      __typename?: 'Group';
      _id: string;
      name: string;
      address: { __typename?: 'GroupAddress'; state?: string | null; county?: string | null };
      districtSemesters: Array<{ __typename?: 'DistrictSemester'; _id: string; startDate: any; endDate: any; name?: string | null }>;
    } | null;
    classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
    comics?: Array<{ __typename?: 'UserComic'; read: boolean; comicName: string }> | null;
    eduProfile?: {
      __typename?: 'UserEduProfile';
      educationLevel: EduLvl;
      gradesTaught: Array<string>;
      subjectsTaught: Array<string>;
      units: Array<string>;
      courseCategory: Array<string>;
      activityType: Array<string>;
    } | null;
  };
};

export type GetGroupCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
  type: GroupCodeType;
}>;

export type GetGroupCodeQuery = {
  __typename?: 'Query';
  getGroupCode?: {
    __typename?: 'NewGroupCode';
    _id: string;
    code: string;
    creationDate: any;
    expirationDate?: any | null;
    isActive: boolean;
    numLicenses?: number | null;
    group: { __typename?: 'Group'; name: string; domains: Array<string> };
    users: Array<{ __typename?: 'User'; _id: string }>;
  } | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    developer: boolean;
    completedTours: Array<string>;
    state: UserState;
    numClassrooms: number;
    grade: Array<string>;
    analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
    surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
    profile: {
      __typename?: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      school?: string | null;
      character?: string | null;
      pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor };
    };
    verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    group?: {
      __typename?: 'Group';
      _id: string;
      name: string;
      address: { __typename?: 'GroupAddress'; state?: string | null; county?: string | null };
      districtSemesters: Array<{ __typename?: 'DistrictSemester'; _id: string; startDate: any; endDate: any; name?: string | null }>;
    } | null;
    comics?: Array<{ __typename?: 'UserComic'; read: boolean; comicName: string }> | null;
    eduProfile?: {
      __typename?: 'UserEduProfile';
      educationLevel: EduLvl;
      gradesTaught: Array<string>;
      subjectsTaught: Array<string>;
      courseCategory: Array<string>;
      activityType: Array<string>;
      units: Array<string>;
    } | null;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type CreateTeacherUserMutationVariables = Exact<{
  teacherSignupData: TeacherSignupDto;
  userEduProfileDto?: InputMaybe<UserEduProfileDto>;
  creationType: UserCreationType;
}>;

export type CreateTeacherUserMutation = {
  __typename?: 'Mutation';
  createTeacherUser?: {
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null; school?: string | null };
    group?: { __typename?: 'Group'; _id: string } | null;
    eduProfile?: { __typename?: 'UserEduProfile'; educationLevel: EduLvl; gradesTaught: Array<string>; subjectsTaught: Array<string> } | null;
  } | null;
};

export type TokenAuthenticationMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type TokenAuthenticationMutation = {
  __typename?: 'Mutation';
  tokenAuthentication: {
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    developer: boolean;
    completedTours: Array<string>;
    state: UserState;
    numClassrooms: number;
    grade: Array<string>;
    analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
    surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
    profile: {
      __typename?: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      school?: string | null;
      character?: string | null;
      pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor };
    };
    verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    group?: {
      __typename?: 'Group';
      _id: string;
      address: { __typename?: 'GroupAddress'; state?: string | null };
      districtSemesters: Array<{ __typename?: 'DistrictSemester'; _id: string; startDate: any; endDate: any; name?: string | null }>;
    } | null;
    eduProfile?: { __typename?: 'UserEduProfile'; educationLevel: EduLvl; gradesTaught: Array<string>; subjectsTaught: Array<string> } | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  getUser: GetUserDto;
  updateUser: UpdateUserDto;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    developer: boolean;
    isDemo?: boolean | null;
    completedTours: Array<string>;
    state: UserState;
    grade: Array<string>;
    analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
    surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
    profile: {
      __typename?: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      school?: string | null;
      character?: string | null;
      pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor };
    };
    verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    group?: {
      __typename?: 'Group';
      _id: string;
      address: { __typename?: 'GroupAddress'; state?: string | null };
      districtSemesters: Array<{ __typename?: 'DistrictSemester'; _id: string; startDate: any; endDate: any; name?: string | null }>;
    } | null;
    classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
    comics?: Array<{ __typename?: 'UserComic'; read: boolean; comicName: string }> | null;
    eduProfile?: { __typename?: 'UserEduProfile'; educationLevel: EduLvl; gradesTaught: Array<string>; subjectsTaught: Array<string> } | null;
  };
};

export type UpdateUserPasswordMutationVariables = Exact<{
  getUser: GetUserDto;
  newPassword: Scalars['String']['input'];
}>;

export type UpdateUserPasswordMutation = {
  __typename?: 'Mutation';
  updateUserPassword: {
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    developer: boolean;
    state: UserState;
    analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
    surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
    profile: {
      __typename?: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      school?: string | null;
      character?: string | null;
      pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor };
    };
    verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    group?: {
      __typename?: 'Group';
      _id: string;
      districtSemesters: Array<{ __typename?: 'DistrictSemester'; _id: string; startDate: any; endDate: any; name?: string | null }>;
    } | null;
  };
};

export type UpdateUserProfileMutationVariables = Exact<{
  getUser: GetUserDto;
  userProfile: UserProfileDto;
}>;

export type UpdateUserProfileMutation = {
  __typename?: 'Mutation';
  updateUserProfile: {
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    developer: boolean;
    completedTours: Array<string>;
    state: UserState;
    grade: Array<string>;
    analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
    surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
    profile: {
      __typename?: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      school?: string | null;
      character?: string | null;
      pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor };
    };
    verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    group?: {
      __typename?: 'Group';
      _id: string;
      address: { __typename?: 'GroupAddress'; state?: string | null };
      districtSemesters: Array<{ __typename?: 'DistrictSemester'; _id: string; startDate: any; endDate: any; name?: string | null }>;
    } | null;
    classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
    comics?: Array<{ __typename?: 'UserComic'; read: boolean; comicName: string }> | null;
    eduProfile?: { __typename?: 'UserEduProfile'; educationLevel: EduLvl; gradesTaught: Array<string>; subjectsTaught: Array<string> } | null;
  };
};

export type UpdateUserTourMutationVariables = Exact<{
  getUser: GetUserDto;
  completedToursDto: CompletedToursDto;
}>;

export type UpdateUserTourMutation = {
  __typename?: 'Mutation';
  updateUserTour: {
    __typename?: 'User';
    _id: string;
    email: string;
    role: UserRole;
    developer: boolean;
    completedTours: Array<string>;
    state: UserState;
    grade: Array<string>;
    analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
    surveyInvites: Array<{ __typename?: 'UserSurveyInvite'; surveyUniqueName: string; creationDate: any }>;
    profile: {
      __typename?: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      school?: string | null;
      character?: string | null;
      pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor };
    };
    verification: { __typename?: 'UserVerification'; email: boolean; profile: boolean };
    group?: {
      __typename?: 'Group';
      _id: string;
      address: { __typename?: 'GroupAddress'; state?: string | null };
      districtSemesters: Array<{ __typename?: 'DistrictSemester'; _id: string; startDate: any; endDate: any; name?: string | null }>;
    } | null;
    classrooms: Array<{ __typename?: 'Classroom'; _id: string }>;
    eduProfile?: { __typename?: 'UserEduProfile'; educationLevel: EduLvl; gradesTaught: Array<string>; subjectsTaught: Array<string> } | null;
  };
};

export type IsUsernameAvailableQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type IsUsernameAvailableQuery = { __typename?: 'Query'; isUsernameAvailable: boolean };

export type VerifyUserMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type VerifyUserMutation = { __typename?: 'Mutation'; verifyUser: boolean };

export type GetDaTeacherTrainingDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetDaTeacherTrainingDataQuery = {
  __typename?: 'Query';
  getAllTeacherTrainings: Array<{ __typename?: 'TeacherTraining'; hasCert: boolean; user: { __typename?: 'User'; _id: string } }>;
};

export type GetDaGroupDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetDaGroupDataQuery = {
  __typename?: 'Query';
  getMyGroup: {
    __typename?: 'Group';
    _id: string;
    name: string;
    numStudents: number;
    numTeachers: number;
    numClassrooms: number;
    address: { __typename?: 'GroupAddress'; state?: string | null };
    teachers: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      role: UserRole;
      numClassrooms: number;
      profile: { __typename?: 'UserProfile'; firstName?: string | null; lastName?: string | null; school?: string | null };
      analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
      classrooms: Array<{ __typename?: 'Classroom'; numStudents: number }>;
    }>;
  };
};

export type GetUserDetailsDistrictQueryVariables = Exact<{
  getUserDto: GetUserDto;
}>;

export type GetUserDetailsDistrictQuery = {
  __typename?: 'Query';
  getUserDistrict?: {
    __typename?: 'User';
    _id: string;
    email: string;
    classrooms: Array<{
      __typename?: 'Classroom';
      _id: string;
      classCode: string;
      color: ClassroomColor;
      creationDate: any;
      endDate?: any | null;
      invites: Array<string>;
      name: string;
      period: string;
      startDate?: any | null;
      subject: string;
      students: Array<{
        __typename?: 'User';
        _id: string;
        sciopsData: {
          __typename?: 'UserSciopsData';
          lastLogin?: any | null;
          firstPlayed?: any | null;
          numLogins?: number | null;
          totalTime?: number | null;
          gameSaveData?: {
            __typename?: 'SaveData';
            levels: {
              __typename?: 'SaveDataLevels';
              campaign: { __typename?: 'LevelGroupDetails'; levels: Array<{ __typename?: 'Level'; levelKey: string; numComplete: number }> };
            };
          } | null;
        };
      }>;
    }>;
  } | null;
};

export type GetClassroomDetailsDistrictQueryVariables = Exact<{
  getClassroom: GetClassroomDto;
}>;

export type GetClassroomDetailsDistrictQuery = {
  __typename?: 'Query';
  getClassroomDistrict?: {
    __typename?: 'Classroom';
    _id: string;
    period: string;
    subject: string;
    startDate?: any | null;
    endDate?: any | null;
    name: string;
    color: ClassroomColor;
    uuid: string;
    classCode: string;
    invites: Array<string>;
    creationDate: any;
    students: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      role: UserRole;
      analytics: { __typename?: 'UserAnalytics'; creationDate: any; lastLogin: any; numLogins: number };
      profile: {
        __typename?: 'UserProfile';
        character?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        school?: string | null;
        pictureUrl: { __typename?: 'UserProfilePictureUrl'; color: ProfilePicColor; img: ProfilePicImg };
      };
      sciopsData: {
        __typename?: 'UserSciopsData';
        lastLogin?: any | null;
        firstPlayed?: any | null;
        numLogins?: number | null;
        totalTime?: number | null;
        gameSaveData?: {
          __typename?: 'SaveData';
          levels: { __typename?: 'SaveDataLevels'; campaign: { __typename?: 'LevelGroupDetails'; levels: Array<{ __typename?: 'Level'; levelKey: string; numComplete: number }> } };
        } | null;
      };
    }>;
  } | null;
};

export type CheckForPortalNotificationsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type CheckForPortalNotificationsQuery = {
  __typename?: 'Query';
  checkForPortalNotifications: Array<{
    __typename?: 'PortalNotification';
    _id: string;
    title: string;
    sender: string;
    type: NotificationType;
    read: boolean;
    deleted: boolean;
    displayDate?: any | null;
    attachments?: Array<string> | null;
    message: { __typename?: 'Message'; text: string; key: string };
  }>;
};

export type GetMyPortalNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyPortalNotificationsQuery = {
  __typename?: 'Query';
  getMyPortalNotifications: Array<{
    __typename?: 'PortalNotification';
    _id: string;
    title: string;
    sender: string;
    type: NotificationType;
    read: boolean;
    deleted: boolean;
    displayDate?: any | null;
    attachments?: Array<string> | null;
    message: { __typename?: 'Message'; text: string; key: string };
  }>;
};

export type UpdatePortalNotificationMutationVariables = Exact<{
  portalNotificationId: Scalars['String']['input'];
  updatePortalNotificationDto: UpdatePortalNotificationDto;
}>;

export type UpdatePortalNotificationMutation = {
  __typename?: 'Mutation';
  updatePortalNotification: {
    __typename?: 'PortalNotification';
    _id: string;
    title: string;
    sender: string;
    type: NotificationType;
    read: boolean;
    deleted: boolean;
    displayDate?: any | null;
    attachments?: Array<string> | null;
    message: { __typename?: 'Message'; text: string; key: string };
  };
};

export type MarkPortalNotificationAsDeletedMutationVariables = Exact<{
  portalNotificationId: Scalars['String']['input'];
}>;

export type MarkPortalNotificationAsDeletedMutation = {
  __typename?: 'Mutation';
  markPortalNotificationAsDeleted: {
    __typename?: 'PortalNotification';
    _id: string;
    title: string;
    sender: string;
    type: NotificationType;
    read: boolean;
    deleted: boolean;
    displayDate?: any | null;
    attachments?: Array<string> | null;
    message: { __typename?: 'Message'; text: string; key: string };
  };
};

export type IsAuthenticatedQueryVariables = Exact<{ [key: string]: never }>;

export type IsAuthenticatedQuery = { __typename?: 'Query'; isAuthenticated: boolean };

export type SendContactUsEmailMutationVariables = Exact<{
  emailBody: SendContactUsDto;
}>;

export type SendContactUsEmailMutation = { __typename?: 'Mutation'; sendContactUs: boolean };

export type GetSciOpsJwtQueryVariables = Exact<{ [key: string]: never }>;

export type GetSciOpsJwtQuery = { __typename?: 'Query'; getSciOpsJWT: string };

export type LogoutCurrentUserFromSciOpsMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutCurrentUserFromSciOpsMutation = { __typename?: 'Mutation'; sciopsLogoutUser: boolean };

export type CheckStudentActivityAccessQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  submissionManagerId: Scalars['String']['input'];
}>;

export type CheckStudentActivityAccessQuery = { __typename?: 'Query'; checkStudentActivityAccess: boolean };

export type MarkSubmissionManagerAsCompleteMutationVariables = Exact<{
  submissionManagerId: Scalars['String']['input'];
}>;

export type MarkSubmissionManagerAsCompleteMutation = { __typename?: 'Mutation'; markSubmissionManagerAsComplete: { __typename?: 'SubmissionManager'; _id: string } };

export type GetSubmissionManagersForStudentQueryVariables = Exact<{
  dto: GetManySubmissionManagersDto;
}>;

export type GetSubmissionManagersForStudentQuery = {
  __typename?: 'Query';
  getManySubmissionManagers: Array<{
    __typename?: 'SubmissionManager';
    _id: string;
    isFinished: boolean;
    needsReview: boolean;
    creationDate: any;
    isOverride: boolean;
    assignment: {
      __typename?: 'Assignment';
      _id: string;
      type: AssignmentType;
      iconUrl: string;
      name: string;
      description?: string | null;
      awsLink: string;
      aId: string;
      creationDate: any;
      availableActions: Array<AssignmentAvailableAction>;
      isActive: boolean;
      specialRequirement?: AssignmentSpecialRequirement | null;
      isBrotliCompressed?: boolean | null;
      isAutoGradable: boolean;
      questions: Array<{
        __typename?: 'AssignmentQuestion';
        qId: string;
        number: number;
        text: string;
        screenshotUrl: string;
        type: AssignmentQuestionType;
        answerType?: AssignmentAnswerType | null;
        acceptedAnswers: any;
        weight: number;
        possibleAnswerChoices?: Array<string> | null;
        section: { __typename?: 'AssignmentSection'; sId: string; name: string; weight: number };
        standards: Array<{ __typename?: 'AssignmentStandard'; state: string; code: string; description: string }>;
      }>;
      version: { __typename?: 'AssignmentVersion'; major: number; minor: number; hotfix: number; releaseDate: any };
    };
    assignmentManager?: {
      __typename?: 'AssignmentManager';
      _id: string;
      classroom: { __typename?: 'Classroom'; _id: string };
    } | null;
    controls: {
      __typename?: 'AssignmentControls';
      allowLate: boolean;
      dueDate: any;
      startDate: any;
      maxAttempts: number;
    };
    submissions: Array<{
      __typename?: 'AssignmentSubmission';
      _id: string;
      isComplete: boolean;
      needsReview: boolean;
      attempt: number;
      grade: number;
      creationDate: any;
      completionDate?: any | null;
      submissionManager: { __typename?: 'SubmissionManager'; _id: string };
      assignment: { __typename?: 'Assignment'; _id: string };
      answers: Array<{
        __typename?: 'AssignmentAnswer';
        _id: string;
        grade: number;
        needsReview: boolean;
        answer: any;
        answerType?: AssignmentAnswerType | null;
        question: {
          __typename?: 'AssignmentQuestion';
          qId: string;
          number: number;
          text: string;
          screenshotUrl: string;
          type: AssignmentQuestionType;
          answerType?: AssignmentAnswerType | null;
          acceptedAnswers: any;
          weight: number;
          possibleAnswerChoices?: Array<string> | null;
          section: { __typename?: 'AssignmentSection'; sId: string; name: string; weight: number };
          standards: Array<{ __typename?: 'AssignmentStandard'; state: string; code: string; description: string }>;
        };
      }>;
    }>;
  }>;
};

export type SaveAssignmentSubmissionProgressMutationVariables = Exact<{
  submissionManagerId: Scalars['String']['input'];
  answers: Array<SaveAssignmentSubmissionAnswerDto> | SaveAssignmentSubmissionAnswerDto;
  isComplete: Scalars['Boolean']['input'];
}>;

export type SaveAssignmentSubmissionProgressMutation = { __typename?: 'Mutation'; saveAssignmentSubmissionProgress: { __typename?: 'SubmissionManager'; _id: string } };

export type UpdateComicOnUserMutationVariables = Exact<{
  getUser: GetUserDto;
  updateUserComicDto: UpdateUserComicDto;
}>;

export type UpdateComicOnUserMutation = {
  __typename?: 'Mutation';
  updateComicOnUser: { __typename?: 'User'; _id: string; comics?: Array<{ __typename?: 'UserComic'; read: boolean; comicName: string }> | null };
};

export type JoinClassroomMutationVariables = Exact<{
  classCode: Scalars['String']['input'];
}>;

export type JoinClassroomMutation = { __typename?: 'Mutation'; joinClassroom: { __typename?: 'Classroom'; _id: string; name: string } };

export type GetSciopsHighestLevelQueryVariables = Exact<{ [key: string]: never }>;

export type GetSciopsHighestLevelQuery = { __typename?: 'Query'; getSciopsHighestLevel: string };

export type CheckToAssignSurveyInviteForUserMutationVariables = Exact<{
  getUserDto: GetUserDto;
}>;

export type CheckToAssignSurveyInviteForUserMutation = { __typename?: 'Mutation'; checkToAssignSurveyInviteForUser: { __typename?: 'User'; _id: string } };

export type CreateSurveyResultMutationVariables = Exact<{
  surveyResultData: CreateSurveyResultDto;
}>;

export type CreateSurveyResultMutation = {
  __typename?: 'Mutation';
  createSurveyResult: {
    __typename?: 'SurveyResults';
    _id: string;
    surveyId: string;
    versionNumber: number;
    completed?: boolean | null;
    completedDate?: any | null;
    user: { __typename?: 'User'; _id: string };
    results: Array<{ __typename?: 'SurveyResultsData'; answer: string; questionCode: string; section: number }>;
    consent?: { __typename?: 'SurveyConsent'; date: any; agree?: boolean | null } | null;
  };
};

export type GetSurveyResultQueryVariables = Exact<{
  surveyId: GetSurveyResultDto;
}>;

export type GetSurveyResultQuery = {
  __typename?: 'Query';
  getSurveyResult?: {
    __typename?: 'SurveyResults';
    _id: string;
    surveyId: string;
    versionNumber: number;
    completed?: boolean | null;
    completedDate?: any | null;
    user: { __typename?: 'User'; _id: string };
    results: Array<{ __typename?: 'SurveyResultsData'; answer: string; questionCode: string; section: number }>;
    consent?: { __typename?: 'SurveyConsent'; date: any; agree?: boolean | null } | null;
  } | null;
};

export type GetSurveyDataQueryVariables = Exact<{
  surveyId: GetSurveyDataDto;
}>;

export type GetSurveyDataQuery = {
  __typename?: 'Query';
  getSurveyData?: {
    __typename?: 'SurveyData';
    _id: string;
    name: string;
    surveyId: string;
    version: number;
    sections: Array<{
      __typename?: 'SurveySection';
      doRandomization: boolean;
      title: string;
      instruction?: string | null;
      imgData?: { __typename?: 'SurveySectionImgData'; position: SurveyImgPosition; url: string } | null;
      questions: Array<{
        __typename?: 'SurveySectionQuestions';
        isRequired: boolean;
        text: string;
        type: QuestionType;
        choices?: Array<string> | null;
        code: string;
        questionImg?: string | null;
      }>;
    }>;
  } | null;
};

export type MarkSurveyResultCompleteMutationVariables = Exact<{
  surveyId: GetSurveyResultDto;
}>;

export type MarkSurveyResultCompleteMutation = {
  __typename?: 'Mutation';
  markSurveyResultComplete: {
    __typename?: 'SurveyResults';
    _id: string;
    surveyId: string;
    versionNumber: number;
    completed?: boolean | null;
    completedDate?: any | null;
    user: { __typename?: 'User'; _id: string };
    results: Array<{ __typename?: 'SurveyResultsData'; answer: string; questionCode: string; section: number }>;
    consent?: { __typename?: 'SurveyConsent'; date: any; agree?: boolean | null } | null;
  };
};

export type SubmitSurveyResultAnswersMutationVariables = Exact<{
  surveyId: GetSurveyResultDto;
  results: Array<SurveyResultsDataDto> | SurveyResultsDataDto;
}>;

export type SubmitSurveyResultAnswersMutation = {
  __typename?: 'Mutation';
  submitSurveyResultAnswers: {
    __typename?: 'SurveyResults';
    _id: string;
    surveyId: string;
    versionNumber: number;
    completed?: boolean | null;
    completedDate?: any | null;
    user: { __typename?: 'User'; _id: string };
    results: Array<{ __typename?: 'SurveyResultsData'; answer: string; questionCode: string; section: number }>;
    consent?: { __typename?: 'SurveyConsent'; date: any; agree?: boolean | null } | null;
  };
};

export type AddConsentToSurveyResultMutationVariables = Exact<{
  surveyId: GetSurveyResultDto;
}>;

export type AddConsentToSurveyResultMutation = {
  __typename?: 'Mutation';
  addConsentToSurveyResult: {
    __typename?: 'SurveyResults';
    _id: string;
    surveyId: string;
    versionNumber: number;
    completed?: boolean | null;
    completedDate?: any | null;
    user: { __typename?: 'User'; _id: string };
    results: Array<{ __typename?: 'SurveyResultsData'; answer: string; questionCode: string; section: number }>;
    consent?: { __typename?: 'SurveyConsent'; date: any; agree?: boolean | null } | null;
  };
};

export type AssignClassroomAssignmentMutationVariables = Exact<{
  dto: CreateAssignmentManagerDto;
}>;

export type AssignClassroomAssignmentMutation = {
  __typename?: 'Mutation';
  assignClassroomAssignment: {
    __typename?: 'AssignmentManager';
    _id: string;
    creationDate: any;
    assignment: { __typename?: 'Assignment'; _id: string; aId: string; name: string };
    classroom: { __typename?: 'Classroom'; _id: string; name: string };
  };
};

export type DeleteAssignmentManagerMutationVariables = Exact<{
  assignmentManagerId: Scalars['String']['input'];
}>;

export type DeleteAssignmentManagerMutation = { __typename?: 'Mutation'; deleteAssignmentManager: { __typename?: 'AssignmentManager'; _id: string } };

export type DeleteClassroomMutationVariables = Exact<{
  getClassroom: GetClassroomDto;
}>;

export type DeleteClassroomMutation = { __typename?: 'Mutation'; deleteClassroom: boolean };

export type GetAssignmentWidgetInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssignmentWidgetInfoQuery = {
  __typename?: 'Query';
  getMyClassroomsAssignmentManagers: Array<{
    __typename?: 'AssignmentManager';
    _id: string;
    creationDate: any;
    assignment: { __typename?: 'Assignment'; name: string; type: AssignmentType; tags?: Array<string> | null };
    classroom: { __typename?: 'Classroom'; _id: string; name: string };
    defaults: { __typename?: 'AssignmentControls'; allowLate: boolean; dueDate: any; maxAttempts: number; startDate: any };
    submissions: Array<{
      __typename?: 'SubmissionManager';
      _id: string;
      isFinished: boolean;
      submissions: Array<{ __typename?: 'AssignmentSubmission'; _id: string; isComplete: boolean }>;
    }>;
  }>;
};

export type GetClassroomsWidgetInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetClassroomsWidgetInfoQuery = {
  __typename?: 'Query';
  getMyClassrooms: Array<{ __typename?: 'Classroom'; _id: string; numStudents: number; name: string; color: ClassroomColor }>;
};

export type GetAllAssignmentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAssignmentsQuery = {
  __typename?: 'Query';
  getManyAssignmentsByDto: Array<{
    __typename?: 'Assignment';
    _id: string;
    aId: string;
    awsLink: string;
    creationDate: any;
    name: string;
    type: AssignmentType;
    specialRequirement?: AssignmentSpecialRequirement | null;
    tags?: Array<string> | null;
    questions: Array<{
      __typename?: 'AssignmentQuestion';
      qId: string;
      number: number;
      text: string;
      screenshotUrl: string;
      type: AssignmentQuestionType;
      answerType?: AssignmentAnswerType | null;
      acceptedAnswers: any;
      possibleAnswerChoices?: Array<string> | null;
      weight: number;
      section: { __typename?: 'AssignmentSection'; sId: string; sectionType?: AssignmentSectionType | null; name: string; weight: number };
      standards: Array<{ __typename?: 'AssignmentStandard'; state: string; code: string; description: string }>;
    }>;
    version: { __typename?: 'AssignmentVersion'; major: number; minor: number; hotfix: number; releaseDate: any };
  }>;
};

export type GetAssignmentManagersByClassroomQueryVariables = Exact<{
  dto: GetAssignmentManagersDto;
}>;

export type GetAssignmentManagersByClassroomQuery = {
  __typename?: 'Query';
  getManyAssignmentManagers: Array<{
    __typename?: 'AssignmentManager';
    _id: string;
    isClosed: boolean;
    creationDate: any;
    assignment: {
      __typename?: 'Assignment';
      _id: string;
      name: string;
      awsLink: string;
      iconUrl: string;
      aId: string;
      isAutoGradable: boolean;
      questions: Array<{
        __typename?: 'AssignmentQuestion';
        qId: string;
        number: number;
        text: string;
        screenshotUrl: string;
        type: AssignmentQuestionType;
        answerType?: AssignmentAnswerType | null;
        acceptedAnswers: any;
        weight: number;
        section: { __typename?: 'AssignmentSection'; sId: string; name: string; weight: number };
        standards: Array<{ __typename?: 'AssignmentStandard'; state: string; code: string; description: string }>;
      }>;
      version: { __typename?: 'AssignmentVersion'; major: number; minor: number; hotfix: number; releaseDate: any };
    };
    classroom: { __typename?: 'Classroom'; _id: string; name: string; teachers: Array<{ __typename?: 'User'; _id: string; email: string }> };
    defaults: { __typename?: 'AssignmentControls'; dueDate: any; startDate: any; maxAttempts: number; allowLate: boolean };
    submissions: Array<{
      __typename?: 'SubmissionManager';
      _id: string;
      isFinished: boolean;
      isOverride: boolean;
      needsReview: boolean;
      creationDate: any;
      completionDate?: any | null;
      user: { __typename?: 'User'; _id: string; email: string };
      controls: { __typename?: 'AssignmentControls'; allowLate: boolean; startDate: any; dueDate: any; maxAttempts: number };
      submissions: Array<{
        __typename?: 'AssignmentSubmission';
        _id: string;
        isComplete: boolean;
        needsReview: boolean;
        attempt: number;
        grade: number;
        creationDate: any;
        completionDate?: any | null;
        assignment: { __typename?: 'Assignment'; _id: string };
        answers: Array<{
          __typename?: 'AssignmentAnswer';
          _id: string;
          grade: number;
          needsReview: boolean;
          answer: any;
          answerType?: AssignmentAnswerType | null;
          question: { __typename?: 'AssignmentQuestion'; qId: string; weight: number; section: { __typename?: 'AssignmentSection'; sId: string; weight: number; name: string } };
        }>;
      }>;
    }>;
  }>;
};

export type GetClassroomSummariesQueryVariables = Exact<{ [key: string]: never }>;

export type GetClassroomSummariesQuery = {
  __typename?: 'Query';
  getMyClassrooms: Array<{ __typename?: 'Classroom'; _id: string; name: string; color: ClassroomColor; classCode: string; numStudents: number }>;
};

export type GetRecommendedStudentActivitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecommendedStudentActivitiesQuery = {
  __typename?: 'Query';
  getRecommendedStudentActivities: Array<{
    __typename?: 'StudentActivity';
    _id: string;
    name: string;
    courseName: string;
    unitName: string;
    description: string;
    resourceCategory?: ResourceCategoryType | null;
    reviewResource?: ReviewResourceType | null;
    skillConnectionTags?: Array<SkillConnectionTagType> | null;
    isActive: boolean;
    recommendationScore?: number | null;
    estimatedTime?: EstimatedTime | null;
    assignment?: { __typename?: 'Assignment'; _id: string } | null;
    links: Array<{ __typename?: 'ActivityLink'; name: string; link: string; btnType: ButtonType }>;
    units: Array<{
      __typename?: 'ActivityUnit';
      _id: string;
      code: string;
      name: string;
      courses: Array<{ __typename?: 'ActivityCourse'; _id: string; code: string; name: string }>;
    }>;
  }>;
};

export type ResendVerificationMutationVariables = Exact<{ [key: string]: never }>;

export type ResendVerificationMutation = { __typename?: 'Mutation'; resendVerification: boolean };

export type UnlockCurrentUserSciOpsLvlsMutationVariables = Exact<{
  getUserDto: GetUserDto;
  lvlKey: Scalars['String']['input'];
}>;

export type UnlockCurrentUserSciOpsLvlsMutation = { __typename?: 'Mutation'; unlockSciOpsLvls: { __typename?: 'User'; _id: string } };

export type UpdateClassroomMutationVariables = Exact<{
  getClassroom: GetClassroomDto;
  updateClassroom: UpdateClassroomDto;
  districtSemesterId: Scalars['String']['input'];
}>;

export type UpdateClassroomMutation = {
  __typename?: 'Mutation';
  updateClassroom: {
    __typename?: 'Classroom';
    _id: string;
    name: string;
    subject: string;
    period: string;
    color: ClassroomColor;
    startDate?: any | null;
    endDate?: any | null;
    classCode: string;
  };
};

export type UpdateAssignmentManagerDefaultsMutationVariables = Exact<{
  amId: Scalars['String']['input'];
  controls: AssignmentControlsDto;
  isOverride?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateAssignmentManagerDefaultsMutation = {
  __typename?: 'Mutation';
  updateAssignmentManagerDefaults: {
    __typename?: 'AssignmentManager';
    _id: string;
    classroom: { __typename?: 'Classroom'; _id: string };
    defaults: { __typename?: 'AssignmentControls'; allowLate: boolean; maxAttempts: number; startDate: any; dueDate: any };
  };
};

export type GetActivityCoursesSearchFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetActivityCoursesSearchFiltersQuery = {
  __typename?: 'Query';
  getActivityCourses: Array<{
    __typename?: 'ActivityCourse';
    _id: string;
    name: string;
    code: string;
    units: Array<{ __typename?: 'ActivityUnit'; _id: string; name: string; isActive: boolean; pacingGuide: string; inProgress: boolean }>;
  }>;
};

export type GetCourseActivitiesQueryVariables = Exact<{
  getActivityCourseDto: GetActivityCourseDto;
}>;

export type GetCourseActivitiesQuery = {
  __typename?: 'Query';
  getActivityCourse: {
    __typename?: 'ActivityCourse';
    _id: string;
    name: string;
    code: string;
    units: Array<{
      __typename?: 'ActivityUnit';
      _id: string;
      name: string;
      code: string;
      isActive: boolean;
      pacingGuide: string;
      inProgress: boolean;
      stateStandards: Array<{
        __typename?: 'StateStandard';
        abbr: string;
        name: string;
        standards?: Array<{
          __typename?: 'UnitStandard';
          code?: string | null;
          description: string;
          subStandards?: Array<{ __typename?: 'SubStandard'; code?: string | null; description: string }> | null;
        }> | null;
      }>;
      activities: Array<{
        __typename?: 'StudentActivity';
        _id: string;
        name: string;
        unitName: string;
        courseName: string;
        resourceCategory?: ResourceCategoryType | null;
        reviewResource?: ReviewResourceType | null;
        skillConnectionTags?: Array<SkillConnectionTagType> | null;
        isActive: boolean;
        description: string;
        thumbnail?: string | null;
        creationDate?: any | null;
        isAdvanced: boolean;
        links: Array<{ __typename?: 'ActivityLink'; _id: string; name: string; link: string; btnType: ButtonType }>;
        assignment?: { __typename?: 'Assignment'; _id: string; name: string } | null;
        onlineActivity?: { __typename?: 'OnlineLink'; aId: string; name: string } | null;
      }>;
    }>;
  };
};

export type GetStudentActivitySearchQueryVariables = Exact<{
  searchDto: GetStudentActivitiesSearchDto;
}>;

export type GetStudentActivitySearchQuery = {
  __typename?: 'Query';
  getStudentActivitySearch: Array<{
    __typename?: 'StudentActivity';
    score?: number | null;
    _id: string;
    name: string;
    unitName: string;
    courseName: string;
    resourceCategory?: ResourceCategoryType | null;
    reviewResource?: ReviewResourceType | null;
    skillConnectionTags?: Array<SkillConnectionTagType> | null;
    isActive: boolean;
    description: string;
    isAdvanced: boolean;
    creationDate?: any | null;
    lastUpdated?: any | null;
    unitPositionIndex?: number | null;
    estimatedTime?: EstimatedTime | null;
    links: Array<{ __typename?: 'ActivityLink'; name: string; link: string; btnType: ButtonType }>;
    assignment?: { __typename?: 'Assignment'; _id: string; name: string; type: AssignmentType; awsLink: string } | null;
    onlineActivity?: { __typename?: 'OnlineLink'; aId: string; name: string } | null;
  }>;
};

export type GetTeacherClassroomsForAssigningLessonsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeacherClassroomsForAssigningLessonsQuery = { __typename?: 'Query'; getMyClassrooms: Array<{ __typename?: 'Classroom'; _id: string; name: string }> };

export type SubmitActivityFeedbackMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  createActivityRatingDto: CreateActivityRatingDto;
  activityId: Scalars['String']['input'];
}>;

export type SubmitActivityFeedbackMutation = { __typename?: 'Mutation'; submitActivityRating: { __typename?: 'ActivityRating'; _id: string } };

export type BulkAddStudentsMutationVariables = Exact<{
  createStudentsDto: CreateStudentsDto;
  creationType: UserCreationType;
}>;

export type BulkAddStudentsMutation = { __typename?: 'Mutation'; bulkCreateStudents: Array<{ __typename?: 'User'; _id: string; email: string }> };

export type CreateNewClassroomMutationVariables = Exact<{
  createClassroom: CreateClassroomDto;
  districtSemesterId: Scalars['String']['input'];
}>;

export type CreateNewClassroomMutation = {
  __typename?: 'Mutation';
  createClassroom: {
    __typename?: 'Classroom';
    _id: string;
    period: string;
    subject: string;
    startDate?: any | null;
    endDate?: any | null;
    name: string;
    color: ClassroomColor;
    classCode: string;
    numStudents: number;
    numTeachers: number;
  };
};

export type GetTeachersClassroomsSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeachersClassroomsSummaryQuery = {
  __typename?: 'Query';
  getMyClassrooms: Array<{
    __typename?: 'Classroom';
    _id: string;
    period: string;
    subject: string;
    startDate?: any | null;
    endDate?: any | null;
    name: string;
    color: ClassroomColor;
    classCode: string;
    numStudents: number;
    numTeachers: number;
    schoolDistrictSemester?: string | null;
  }>;
};

export type GetAssignmentManagerDetailsQueryVariables = Exact<{
  assignmentManagerId: Scalars['String']['input'];
}>;

export type GetAssignmentManagerDetailsQuery = {
  __typename?: 'Query';
  getAssignmentManager?: {
    __typename?: 'AssignmentManager';
    _id: string;
    creationDate: any;
    isClosed: boolean;
    assignment: {
      __typename?: 'Assignment';
      _id: string;
      name: string;
      specialRequirement?: AssignmentSpecialRequirement | null;
      tags?: Array<string> | null;
      type: AssignmentType;
      description?: string | null;
      awsLink: string;
      isAutoGradable: boolean;
      questions: Array<{
        __typename?: 'AssignmentQuestion';
        acceptedAnswers: any;
        answerType?: AssignmentAnswerType | null;
        number: number;
        possibleAnswerChoices?: Array<string> | null;
        qId: string;
        screenshotUrl: string;
        text: string;
        type: AssignmentQuestionType;
        weight: number;
        section: { __typename?: 'AssignmentSection'; name: string; sectionType?: AssignmentSectionType | null; sId: string; weight: number };
        standards: Array<{ __typename?: 'AssignmentStandard'; code: string; description: string; state: string }>;
      }>;
    };
    defaults: { __typename?: 'AssignmentControls'; allowLate: boolean; dueDate: any; maxAttempts: number; startDate: any };
    submissions: Array<{
      __typename?: 'SubmissionManager';
      _id: string;
      completionDate?: any | null;
      creationDate: any;
      isFinished: boolean;
      isOverride: boolean;
      needsReview: boolean;
      status: SubmissionManagerStatus;
      displayStatus: SubmissionManagerDisplayStatus;
      highestGrade: number;
      isStatusGradable: boolean;
      controls: { __typename?: 'AssignmentControls'; allowLate: boolean; dueDate: any; maxAttempts: number; startDate: any };
      submissions: Array<{
        __typename?: 'AssignmentSubmission';
        _id: string;
        attempt: number;
        completionDate?: any | null;
        creationDate: any;
        grade: number;
        isComplete: boolean;
        needsReview: boolean;
        answers: Array<{
          __typename?: 'AssignmentAnswer';
          _id: string;
          answer: any;
          answerType?: AssignmentAnswerType | null;
          grade: number;
          needsReview: boolean;
          question: {
            __typename?: 'AssignmentQuestion';
            qId: string;
            type: AssignmentQuestionType;
            number: number;
            standards: Array<{ __typename?: 'AssignmentStandard'; code: string; state: string }>;
          };
        }>;
      }>;
      user: {
        __typename?: 'User';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; character?: string | null; pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor } };
      };
    }>;
    classroom: { __typename?: 'Classroom'; _id: string };
  } | null;
};

export type GetClassroomAssignmentManagersQueryVariables = Exact<{
  classroomId: Scalars['String']['input'];
}>;

export type GetClassroomAssignmentManagersQuery = {
  __typename?: 'Query';
  getManyAssignmentManagers: Array<{
    __typename?: 'AssignmentManager';
    _id: string;
    creationDate: any;
    isClosed: boolean;
    assignment: {
      __typename?: 'Assignment';
      _id: string;
      aId: string;
      awsLink: string;
      name: string;
      tags?: Array<string> | null;
      type: AssignmentType;
      iconUrl: string;
      isAutoGradable: boolean;
    };
    classroom: { __typename?: 'Classroom'; _id: string };
    defaults: { __typename?: 'AssignmentControls'; allowLate: boolean; dueDate: any; maxAttempts: number; startDate: any };
    submissions: Array<{
      __typename?: 'SubmissionManager';
      _id: string;
      isFinished: boolean;
      isOverride: boolean;
      status: SubmissionManagerStatus;
      displayStatus: SubmissionManagerDisplayStatus;
      isStatusGradable: boolean;
      highestGrade: number;
      controls: { __typename?: 'AssignmentControls'; dueDate: any; allowLate: boolean };
    }>;
  }>;
};

export type GetClassroomAssignmentSummaryQueryVariables = Exact<{
  classroomDto: GetClassroomDto;
}>;

export type GetClassroomAssignmentSummaryQuery = {
  __typename?: 'Query';
  getClassroomAssignmentsSummary: {
    __typename?: 'ClassroomAssignmentsModel';
    classroom: { __typename?: 'Classroom'; _id: string };
    userAssignmentSummary: Array<{
      __typename?: 'UserAssignmentSummary';
      averageGrade: number;
      numCompleted: number;
      totalNumOfAssigned: number;
      user: { __typename?: 'User'; _id: string };
    }>;
  };
};

export type GetClassroomStudentsSciOpsDetailsQueryVariables = Exact<{
  getClassroom: GetClassroomDto;
}>;

export type GetClassroomStudentsSciOpsDetailsQuery = {
  __typename?: 'Query';
  getClassroom?: {
    __typename?: 'Classroom';
    students: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; character?: string | null; pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor } };
      sciopsData: {
        __typename?: 'UserSciopsData';
        firstPlayed?: any | null;
        lastLogin?: any | null;
        numLogins?: number | null;
        totalTime?: number | null;
        gameSaveData?: {
          __typename?: 'SaveData';
          settings: { __typename?: 'Settings'; DifficultySetting: number };
          levels: {
            __typename?: 'SaveDataLevels';
            campaign: {
              __typename?: 'LevelGroupDetails';
              highestLevel: string;
              completionHistory: Array<{
                __typename?: 'LevelCompletion';
                dateCompleted: any;
                difficulty: number;
                levelKey: string;
                totalStarsEarned: number;
                totalTimeToCompleteSeconds: number;
                totalTurnsToComplete: number;
              }>;
              levels: Array<{
                __typename?: 'Level';
                levelKey: string;
                maxStars: number;
                numComplete: number;
                numEfficientStarsEarned: number;
                numPlays: number;
                numSurvivabilityStarsEarned: number;
                numTurnStarsEarned: number;
              }>;
            };
          };
        } | null;
      };
    }>;
  } | null;
};

export type GetClassroomSurveyResultsStatsQueryVariables = Exact<{
  classroomId: Scalars['String']['input'];
}>;

export type GetClassroomSurveyResultsStatsQuery = {
  __typename?: 'Query';
  getSurveyResults: Array<{
    __typename?: 'SurveyResults';
    _id: string;
    surveyId: string;
    completed?: boolean | null;
    completedDate?: any | null;
    user: { __typename?: 'User'; _id: string; email: string };
  }>;
};

export type GetTeacherClassroomDetailsQueryVariables = Exact<{
  getClassroom: GetClassroomDto;
}>;

export type GetTeacherClassroomDetailsQuery = {
  __typename?: 'Query';
  getClassroom?: {
    __typename?: 'Classroom';
    _id: string;
    name: string;
    subject: string;
    period: string;
    color: ClassroomColor;
    classCode: string;
    startDate?: any | null;
    endDate?: any | null;
    numStudents: number;
    numTeachers: number;
    schoolDistrictSemester?: string | null;
    group: { __typename?: 'Group'; _id: string; isSurveysOn: boolean };
    users: Array<{
      __typename?: 'UserWithRole';
      role: UserRole;
      user: {
        __typename?: 'User';
        _id: string;
        email: string;
        profile: {
          __typename?: 'UserProfile';
          firstName?: string | null;
          lastName?: string | null;
          character?: string | null;
          pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg; color: ProfilePicColor };
        };
        analytics: { __typename?: 'UserAnalytics'; lastLogin: any; numLogins: number };
        ssoProfile?: { __typename?: 'SsoProfile'; googleSub: string } | null;
        classLink?: { __typename?: 'UserClassLink'; id?: string | null } | null;
        clever?: { __typename?: 'UserClever'; id?: string | null } | null;
      };
    }>;
  } | null;
};

export type RemoveStudentFromClassroomMutationVariables = Exact<{
  getClassroom: GetClassroomDto;
  getUser: GetUserDto;
}>;

export type RemoveStudentFromClassroomMutation = { __typename?: 'Mutation'; removeStudent: { __typename?: 'Classroom'; _id: string; numStudents: number } };

export type UpdateTeacherClassroomMutationVariables = Exact<{
  getClassroomDto: GetClassroomDto;
  updateClassroomDto: UpdateClassroomDto;
  districtSemesterId: Scalars['String']['input'];
}>;

export type UpdateTeacherClassroomMutation = {
  __typename?: 'Mutation';
  updateClassroom: {
    __typename?: 'Classroom';
    _id: string;
    period: string;
    subject: string;
    startDate?: any | null;
    endDate?: any | null;
    name: string;
    color: ClassroomColor;
    classCode: string;
    numStudents: number;
    numTeachers: number;
  };
};

export type TeacherUpdateStudentPasswordMutationVariables = Exact<{
  getUser: GetUserDto;
  newPassword: Scalars['String']['input'];
}>;

export type TeacherUpdateStudentPasswordMutation = { __typename?: 'Mutation'; updateUserPassword: { __typename?: 'User'; _id: string } };

export type DeleteMyAccountMutationVariables = Exact<{
  userId: GetUserDto;
}>;

export type DeleteMyAccountMutation = { __typename?: 'Mutation'; removeUser: { __typename?: 'User'; _id: string } };

export type UpdateUserProfilePictureMutationVariables = Exact<{
  getUser: GetUserDto;
  userProfilePicture: ProfilePicImg;
}>;

export type UpdateUserProfilePictureMutation = {
  __typename?: 'Mutation';
  updateUserProfile: { __typename?: 'User'; _id: string; profile: { __typename?: 'UserProfile'; pictureUrl: { __typename?: 'UserProfilePictureUrl'; img: ProfilePicImg } } };
};

export type UpdateUserEduProfileMutationVariables = Exact<{
  getUser: GetUserDto;
  userEduProfileDto: UserEduProfileDto;
}>;

export type UpdateUserEduProfileMutation = { __typename?: 'Mutation'; updateUserEduProfile: { __typename?: 'User'; _id: string } };

export type SendTeacherPortalContactFormMutationVariables = Exact<{
  emailBody: SendTeacherPortalContactDto;
}>;

export type SendTeacherPortalContactFormMutation = { __typename?: 'Mutation'; sendTeacherPortalContactForm?: boolean | null };

export type ChangeUserRoleMutationVariables = Exact<{
  roleAccessCode: Scalars['String']['input'];
  userRole: UserRole;
  userProfileDto?: InputMaybe<UserProfileDto>;
}>;

export type ChangeUserRoleMutation = { __typename?: 'Mutation'; changeUserRole?: { __typename?: 'User'; _id: string } | null };

export const GetAssignmentByIdDocument = gql`
  query GetAssignmentById($id: String!) {
    getAssignmentById(id: $id) {
      _id
      type
      iconUrl
      name
      description
      awsLink
      aId
      creationDate
      isActive
      isBrotliCompressed
      questions {
        qId
        number
        section {
          name
          sId
          weight
        }
        text
        screenshotUrl
        standards {
          state
          code
          description
        }
        type
        answerType
        acceptedAnswers
        weight
        possibleAnswerChoices
      }
      version {
        major
        minor
        hotfix
        releaseDate
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAssignmentByIdGQL extends Apollo.Query<GetAssignmentByIdQuery, GetAssignmentByIdQueryVariables> {
  document = GetAssignmentByIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAssignmentByDtoDocument = gql`
  query GetAssignmentByDto($dto: GetSingleAssignmentDto!) {
    getAssignmentByDto(dto: $dto) {
      _id
      type
      iconUrl
      name
      awsLink
      aId
      version {
        major
        minor
        hotfix
        releaseDate
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAssignmentByDtoGQL extends Apollo.Query<GetAssignmentByDtoQuery, GetAssignmentByDtoQueryVariables> {
  document = GetAssignmentByDtoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateStudentActivityResourceCategoryTypeDocument = gql`
  mutation UpdateStudentActivityResourceCategoryType($studentActivityId: String!, $resourceCategoryType: ResourceCategoryType) {
    updateStudentActivityResourceCategoryType(studentActivityId: $studentActivityId, resourceCategoryType: $resourceCategoryType) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateStudentActivityResourceCategoryTypeGQL extends Apollo.Mutation<
  UpdateStudentActivityResourceCategoryTypeMutation,
  UpdateStudentActivityResourceCategoryTypeMutationVariables
> {
  document = UpdateStudentActivityResourceCategoryTypeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateStudentActivityReviewResourceTypeDocument = gql`
  mutation UpdateStudentActivityReviewResourceType($studentActivityId: String!, $reviewResourceType: ReviewResourceType) {
    updateStudentActivityReviewResourceType(studentActivityId: $studentActivityId, reviewResourceType: $reviewResourceType) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateStudentActivityReviewResourceTypeGQL extends Apollo.Mutation<
  UpdateStudentActivityReviewResourceTypeMutation,
  UpdateStudentActivityReviewResourceTypeMutationVariables
> {
  document = UpdateStudentActivityReviewResourceTypeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateStudentActivitySkillConnectionTagsDocument = gql`
  mutation UpdateStudentActivitySkillConnectionTags($studentActivityId: String!, $skillConnectionTags: [SkillConnectionTagType!]!) {
    updateStudentActivitySkillConnectionTags(studentActivityId: $studentActivityId, skillConnectionTags: $skillConnectionTags) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateStudentActivitySkillConnectionTagsGQL extends Apollo.Mutation<
  UpdateStudentActivitySkillConnectionTagsMutation,
  UpdateStudentActivitySkillConnectionTagsMutationVariables
> {
  document = UpdateStudentActivitySkillConnectionTagsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetManyAssignmentsDocument = gql`
  query GetManyAssignments {
    getManyAssignmentsByDto(dto: {}) {
      _id
      aId
      awsLink
      creationDate
      name
      iconUrl
      type
      questions {
        qId
        number
        section {
          sId
          name
          weight
        }
        text
        screenshotUrl
        standards {
          state
          code
          description
        }
        type
        answerType
        acceptedAnswers
        weight
      }
      version {
        major
        minor
        hotfix
        releaseDate
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetManyAssignmentsGQL extends Apollo.Query<GetManyAssignmentsQuery, GetManyAssignmentsQueryVariables> {
  document = GetManyAssignmentsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClassroomAssignedSpQsDocument = gql`
  query GetClassroomAssignedSPQs($dto: GetAssignmentManagersDto!) {
    getManyAssignmentManagers(dto: $dto) {
      _id
      assignment {
        _id
        name
        iconUrl
      }
      creationDate
      defaults {
        allowLate
        dueDate
        maxAttempts
        startDate
      }
      isClosed
      submissions {
        _id
        isFinished
        submissions {
          _id
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetClassroomAssignedSpQsGQL extends Apollo.Query<GetClassroomAssignedSpQsQuery, GetClassroomAssignedSpQsQueryVariables> {
  document = GetClassroomAssignedSpQsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetManySubmissionManagersAdminDocument = gql`
  query getManySubmissionManagersAdmin($dto: GetManySubmissionManagersDto!) {
    getManySubmissionManagers(dto: $dto) {
      _id
      assignment {
        iconUrl
        name
      }
      submissions {
        _id
      }
      isFinished
      controls {
        dueDate
        maxAttempts
        startDate
        allowLate
      }
      completionDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetManySubmissionManagersAdminGQL extends Apollo.Query<GetManySubmissionManagersAdminQuery, GetManySubmissionManagersAdminQueryVariables> {
  document = GetManySubmissionManagersAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddStudentActivityToUnitDocument = gql`
  mutation addStudentActivityToUnit($studentActivityId: String!, $unitId: String!) {
    addStudentActivityToUnit(studentActivityId: $studentActivityId, unitId: $unitId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddStudentActivityToUnitGQL extends Apollo.Mutation<AddStudentActivityToUnitMutation, AddStudentActivityToUnitMutationVariables> {
  document = AddStudentActivityToUnitDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddActivityLinkToStudentActivityDocument = gql`
  mutation addActivityLinkToStudentActivity($studentActivityId: String!, $createActivityLinkDto: CreateActivityLinkDto!) {
    addActivityLinkToStudentActivity(studentActivityId: $studentActivityId, createActivityLinkDto: $createActivityLinkDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddActivityLinkToStudentActivityGQL extends Apollo.Mutation<AddActivityLinkToStudentActivityMutation, AddActivityLinkToStudentActivityMutationVariables> {
  document = AddActivityLinkToStudentActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveActivityLinkFromStudentActivityDocument = gql`
  mutation removeActivityLinkFromStudentActivity($studentActivityId: String!, $activityLinkId: String!) {
    removeActivityLinkFromStudentActivity(studentActivityId: $studentActivityId, activityLinkId: $activityLinkId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveActivityLinkFromStudentActivityGQL extends Apollo.Mutation<
  RemoveActivityLinkFromStudentActivityMutation,
  RemoveActivityLinkFromStudentActivityMutationVariables
> {
  document = RemoveActivityLinkFromStudentActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddOnlineActivityToStudentActivityDocument = gql`
  mutation addOnlineActivityToStudentActivity($studentActivityId: String!, $createOnlineActivityDto: CreateOnlineActivityDto!) {
    addOnlineActivityToStudentActivity(studentActivityId: $studentActivityId, createOnlineActivityDto: $createOnlineActivityDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddOnlineActivityToStudentActivityGQL extends Apollo.Mutation<AddOnlineActivityToStudentActivityMutation, AddOnlineActivityToStudentActivityMutationVariables> {
  document = AddOnlineActivityToStudentActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveOnlineActivityFromStudentActivityDocument = gql`
  mutation removeOnlineActivityFromStudentActivity($studentActivityId: String!) {
    removeOnlineActivityFromStudentActivity(studentActivityId: $studentActivityId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveOnlineActivityFromStudentActivityGQL extends Apollo.Mutation<
  RemoveOnlineActivityFromStudentActivityMutation,
  RemoveOnlineActivityFromStudentActivityMutationVariables
> {
  document = RemoveOnlineActivityFromStudentActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddGroupDomainDocument = gql`
  mutation addGroupDomain($getGroup: GetGroupDto!, $domainToAdd: String!) {
    addGroupDomain(getGroup: $getGroup, domainToAdd: $domainToAdd) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddGroupDomainGQL extends Apollo.Mutation<AddGroupDomainMutation, AddGroupDomainMutationVariables> {
  document = AddGroupDomainDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddTagToStudentActivityDocument = gql`
  mutation addTagToStudentActivity($studentActivityId: String!, $activityTag: String!) {
    addTagToStudentActivity(studentActivityId: $studentActivityId, activityTag: $activityTag) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddTagToStudentActivityGQL extends Apollo.Mutation<AddTagToStudentActivityMutation, AddTagToStudentActivityMutationVariables> {
  document = AddTagToStudentActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddUserSurveyInviteDocument = gql`
  mutation addUserSurveyInvite($getUser: GetUserDto!, $surveyName: String!) {
    addUserSurveyInvite(getUser: $getUser, surveyName: $surveyName) {
      _id
      surveyInvites {
        surveyUniqueName
        creationDate
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddUserSurveyInviteGQL extends Apollo.Mutation<AddUserSurveyInviteMutation, AddUserSurveyInviteMutationVariables> {
  document = AddUserSurveyInviteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAdminHomeInfoDocument = gql`
  query GetAdminHomeInfo {
    districtCount: getUsersCount(getUsersDto: { role: DISTRICT })
    teachersCount: getUsersCount(getUsersDto: { role: TEACHER })
    studentsCount: getUsersCount(getUsersDto: { role: STUDENT })
    adminsCount: getUsersCount(getUsersDto: { role: ADMIN })
    groupsCount: getGroupsCount(getGroupsDto: {})
    classroomsCount: getClassroomsCount(getClassrooms: {})
    usersCount: getUsersCount(getUsersDto: {})
    assignmentCount: getAssignmentCount(dto: {})
    studentActivitiesCount: getStudentActivitiesCount(getStudentActivitiesDto: {})
    newslettersCount: getNewslettersCount
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAdminHomeInfoGQL extends Apollo.Query<GetAdminHomeInfoQuery, GetAdminHomeInfoQueryVariables> {
  document = GetAdminHomeInfoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChangeActivityLinkPositionInArrayDocument = gql`
  mutation changeActivityLinkPositionInArray($studentActivityId: String!, $currentIndex: Float!, $destinationIndex: Float!) {
    changeActivityLinkPositionInArray(studentActivityId: $studentActivityId, currentIndex: $currentIndex, destinationIndex: $destinationIndex) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChangeActivityLinkPositionInArrayGQL extends Apollo.Mutation<ChangeActivityLinkPositionInArrayMutation, ChangeActivityLinkPositionInArrayMutationVariables> {
  document = ChangeActivityLinkPositionInArrayDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChangeActivityPositionInArrayDocument = gql`
  mutation changeActivityPositionInArray($getActivityUnitDto: GetActivityUnitDto!, $currentIndex: Float!, $destinationIndex: Float!) {
    changeActivityPositionInArray(getActivityUnitDto: $getActivityUnitDto, currentIndex: $currentIndex, destinationIndex: $destinationIndex) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChangeActivityPositionInArrayGQL extends Apollo.Mutation<ChangeActivityPositionInArrayMutation, ChangeActivityPositionInArrayMutationVariables> {
  document = ChangeActivityPositionInArrayDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChangeUnitPositionInArrayDocument = gql`
  mutation changeUnitPositionInArray($getActivityCourseDto: GetActivityCourseDto!, $currentIndex: Float!, $destinationIndex: Float!) {
    changeUnitPositionInArray(getActivityCourseDto: $getActivityCourseDto, currentIndex: $currentIndex, destinationIndex: $destinationIndex) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChangeUnitPositionInArrayGQL extends Apollo.Mutation<ChangeUnitPositionInArrayMutation, ChangeUnitPositionInArrayMutationVariables> {
  document = ChangeUnitPositionInArrayDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateAdminUserDocument = gql`
  mutation createAdminUser($adminSignUpData: CreateAdminDto!, $creationType: UserCreationType!) {
    createAdminUser(adminSignUpData: $adminSignUpData, creationType: $creationType) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateAdminUserGQL extends Apollo.Mutation<CreateAdminUserMutation, CreateAdminUserMutationVariables> {
  document = CreateAdminUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateCourseDocument = gql`
  mutation createCourse($createActivityCourseDto: CreateActivityCourseDto!) {
    createActivityCourse(createActivityCourseDto: $createActivityCourseDto) {
      _id
      name
      code
      units {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateCourseGQL extends Apollo.Mutation<CreateCourseMutation, CreateCourseMutationVariables> {
  document = CreateCourseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateStudentActivityDocument = gql`
  mutation createStudentActivity($createStudentActivityDto: CreateStudentActivityDto!, $activityUnitId: String!) {
    createStudentActivity(createStudentActivityDto: $createStudentActivityDto, activityUnitId: $activityUnitId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateStudentActivityGQL extends Apollo.Mutation<CreateStudentActivityMutation, CreateStudentActivityMutationVariables> {
  document = CreateStudentActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateUnitDocument = gql`
  mutation createUnit($createActivityUnitDto: CreateActivityUnitDto!, $courseId: String!) {
    createActivityUnit(createActivityUnitDto: $createActivityUnitDto, courseId: $courseId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateUnitGQL extends Apollo.Mutation<CreateUnitMutation, CreateUnitMutationVariables> {
  document = CreateUnitDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteCourseDocument = gql`
  mutation deleteCourse($activityCourseId: String!) {
    deleteActivityCourse(activityCourseId: $activityCourseId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteCourseGQL extends Apollo.Mutation<DeleteCourseMutation, DeleteCourseMutationVariables> {
  document = DeleteCourseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteStudentActivityDocument = gql`
  mutation deleteStudentActivity($studentActivityId: String!) {
    deleteStudentActivity(studentActivityId: $studentActivityId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteStudentActivityGQL extends Apollo.Mutation<DeleteStudentActivityMutation, DeleteStudentActivityMutationVariables> {
  document = DeleteStudentActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteUnitDocument = gql`
  mutation deleteUnit($activityUnitId: String!) {
    deleteActivityUnit(activityUnitId: $activityUnitId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteUnitGQL extends Apollo.Mutation<DeleteUnitMutation, DeleteUnitMutationVariables> {
  document = DeleteUnitDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllCoursesAdminDocument = gql`
  query GetAllCoursesAdmin {
    getActivityCourses {
      _id
      name
      code
      units {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllCoursesAdminGQL extends Apollo.Query<GetAllCoursesAdminQuery, GetAllCoursesAdminQueryVariables> {
  document = GetAllCoursesAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetActivitiesDocument = gql`
  query getActivities($getStudentActivitiesDto: GetStudentActivitiesDto!) {
    getStudentActivities(getStudentActivitiesDto: $getStudentActivitiesDto) {
      _id
      name
      isActive
      description
      thumbnail
      creationDate
      isAdvanced
      studentPortalAssetLink
      recommendationScore
      units {
        _id
      }
      links {
        _id
        name
        link
        btnType
      }
      assignment {
        _id
        name
      }
      onlineActivity {
        name
        aId
      }
      tags
      skillConnectionTags
      resourceCategory
      courseName
      units {
        _id
        name
        courses {
          _id
          name
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetActivitiesGQL extends Apollo.Query<GetActivitiesQuery, GetActivitiesQueryVariables> {
  document = GetActivitiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetActivityDocument = gql`
  query getActivity($studentActivityId: String!) {
    getStudentActivity(studentActivityId: $studentActivityId) {
      _id
      name
      isActive
      description
      thumbnail
      creationDate
      lastUpdated
      isAdvanced
      units {
        _id
        name
        courses {
          name
          code
        }
      }
      links {
        _id
        name
        link
        btnType
      }
      assignment {
        _id
        name
      }
      onlineActivity {
        name
        aId
      }
      tags
      studentPortalAssetLink
      resourceCategory
      reviewResource
      skillConnectionTags
      recommendationScore
      unitName
      estimatedTime
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetActivityGQL extends Apollo.Query<GetActivityQuery, GetActivityQueryVariables> {
  document = GetActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetActivityRatingsDocument = gql`
  query getActivityRatings($getActivityRatingsDto: GetActivityRatingsDto!) {
    getActivityRatings(getActivityRatingsDto: $getActivityRatingsDto) {
      email
      dateSubmitted
      easeOfUse
      studentEngagement
      gradeAppropriate
      effectiveness
      description
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetActivityRatingsGQL extends Apollo.Query<GetActivityRatingsQuery, GetActivityRatingsQueryVariables> {
  document = GetActivityRatingsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllSurveyDataDocument = gql`
  query getAllSurveyData {
    getAllSurveyData {
      name
      surveyId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllSurveyDataGQL extends Apollo.Query<GetAllSurveyDataQuery, GetAllSurveyDataQueryVariables> {
  document = GetAllSurveyDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllSurveysForUserDocument = gql`
  query getAllSurveysForUser($userId: String!) {
    getAllSurveysForUser(userId: $userId) {
      _id
      surveyId
      completed
      consent {
        date
      }
      completedDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllSurveysForUserGQL extends Apollo.Query<GetAllSurveysForUserQuery, GetAllSurveysForUserQueryVariables> {
  document = GetAllSurveysForUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUnitsDocument = gql`
  query getUnits {
    getActivityUnits {
      _id
      name
      code
      inProgress
      isActive
      activities {
        _id
      }
      courses {
        _id
        name
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUnitsGQL extends Apollo.Query<GetUnitsQuery, GetUnitsQueryVariables> {
  document = GetUnitsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClassroomDetailsAdminDocument = gql`
  query getClassroomDetailsAdmin($getClassroom: GetClassroomDto!) {
    getClassroomAdmin(getClassroom: $getClassroom) {
      _id
      period
      subject
      startDate
      endDate
      schoolDistrictSemester
      group {
        _id
        address {
          county
          state
        }
        classrooms {
          _id
        }
        creationDate
        districtAdmins {
          _id
        }
        domains
        hasSciOps
        groupCodes {
          _id
        }
        isSurveysOn
        isTeacherSignupOn
        name
        students {
          _id
        }
        teachers {
          _id
        }
        uuid
        districtSemesters {
          _id
          startDate
          endDate
          postSurveyWindow {
            lengthOfTime
            measurementOfTime
          }
          postSurveyOn
          name
        }
      }
      name
      color
      uuid
      classCode
      invites
      creationDate
      students {
        _id
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        apiToken
        classrooms {
          _id
        }
        developer
        email
        group {
          _id
        }
        profile {
          character
          firstName
          lastName
          phone
          pictureUrl {
            color
            img
          }
          school
        }
        role
        sciopsData {
          lastLogin
          firstPlayed
          numLogins
          totalTime
          gameSaveData {
            career {
              careerKey
            }
            characters {
              charAki {
                appCurrency
                charKey
                currency
                items {
                  defaultData
                  gear
                  selData
                }
                scientist
                selCurrency
                unitVariant
                upgrades {
                  defaultIndex
                  gear
                  group
                  index
                  max
                  upgradeKey
                }
              }
              charNicole {
                appCurrency
                charKey
                currency
                items {
                  defaultData
                  gear
                  selData
                }
                scientist
                selCurrency
                unitVariant
                upgrades {
                  defaultIndex
                  gear
                  group
                  index
                  max
                  upgradeKey
                }
              }
              charZia {
                appCurrency
                charKey
                currency
                items {
                  defaultData
                  gear
                  selData
                }
                scientist
                selCurrency
                unitVariant
                upgrades {
                  defaultIndex
                  gear
                  group
                  index
                  max
                  upgradeKey
                }
              }
            }
            levels {
              campaign {
                category
                highestLevel
                levels {
                  levelKey
                  maxStars
                  numComplete
                  numEfficientStarsEarned
                  numPlays
                  numSurvivabilityStarsEarned
                  numTurnStarsEarned
                }
              }
              challenge {
                category
                highestLevel
                levels {
                  levelKey
                  maxStars
                  numComplete
                  numEfficientStarsEarned
                  numPlays
                  numSurvivabilityStarsEarned
                  numTurnStarsEarned
                }
              }
            }
            notifications {
              careerNotifications {
                charKey
                messageId
                notificationId
                type
                unlockKey
              }
              characterNotifications {
                charKey
                messageId
                notificationId
                type
                unlockKey
              }
              gearNotifications {
                charKey
                messageId
                notificationId
                type
                unlockKey
              }
              upgradeNotifications {
                charKey
                messageId
                notificationId
                type
                unlockKey
              }
              viewedMessages
              viewedNotifications
            }
          }
        }
        state
        surveyInvites {
          surveyUniqueName
          creationDate
        }
        completedTours
        verification {
          email
          profile
        }
      }
      teachers {
        _id
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        apiToken
        classrooms {
          _id
        }
        developer
        email
        group {
          _id
        }
        profile {
          character
          firstName
          lastName
          phone
          pictureUrl {
            color
            img
          }
          school
        }
        role
        sciopsData {
          lastLogin
          firstPlayed
          numLogins
          totalTime
        }
        state
        surveyInvites {
          surveyUniqueName
          creationDate
        }
        completedTours
        verification {
          email
          profile
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetClassroomDetailsAdminGQL extends Apollo.Query<GetClassroomDetailsAdminQuery, GetClassroomDetailsAdminQueryVariables> {
  document = GetClassroomDetailsAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllClassroomsAdminDocument = gql`
  query getAllClassroomsAdmin {
    getClassrooms(getClassrooms: {}) {
      _id
      classCode
      color
      creationDate
      endDate
      group {
        _id
      }
      invites
      name
      period
      startDate
      numStudents
      subject
      numTeachers
      uuid
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllClassroomsAdminGQL extends Apollo.Query<GetAllClassroomsAdminQuery, GetAllClassroomsAdminQueryVariables> {
  document = GetAllClassroomsAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCourseDocument = gql`
  query getCourse($getActivityCourseDto: GetActivityCourseDto!) {
    getActivityCourse(getActivityCourseDto: $getActivityCourseDto) {
      _id
      name
      code
      units {
        _id
        name
        code
        inProgress
        isActive
        activities {
          _id
          recommendationScore
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetCourseGQL extends Apollo.Query<GetCourseQuery, GetCourseQueryVariables> {
  document = GetCourseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupsDocument = gql`
  query GetGroups($getGroupsDto: GetGroupsDto!) {
    getGroups(getGroupsDto: $getGroupsDto) {
      _id
      name
      address {
        state
        county
      }
      numDistrictAdmins
      numTeachers
      numStudents
      numClassrooms
      groupCodes {
        _id
      }
      isTeacherSignupOn
      isSurveysOn
      isInPilot
      isDemo
      creationDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupsGQL extends Apollo.Query<GetGroupsQuery, GetGroupsQueryVariables> {
  document = GetGroupsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupDocument = gql`
  query GetGroup($getGroupDto: GetGroupDto!) {
    getGroup(getGroupDto: $getGroupDto) {
      _id
      name
      address {
        state
        county
      }
      numDistrictAdmins
      numTeachers
      numStudents
      numClassrooms
      groupCodes {
        _id
      }
      isTeacherSignupOn
      isSurveysOn
      creationDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupGQL extends Apollo.Query<GetGroupQuery, GetGroupQueryVariables> {
  document = GetGroupDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupAdminDocument = gql`
  query GetGroupAdmin($getGroupDto: GetGroupDto!) {
    getGroupAdmin(getGroupDto: $getGroupDto) {
      _id
      address {
        county
        state
      }
      numStudents
      numDistrictAdmins
      numTeachers
      numClassrooms
      creationDate
      domains
      hasSciOps
      groupCodes {
        _id
        code
        type
        creationDate
        expirationDate
        isActive
        numLicenses
        users {
          _id
        }
      }
      isSurveysOn
      isInPilot
      isDemo
      name
      districtSemesters {
        _id
        startDate
        endDate
        postSurveyWindow {
          lengthOfTime
          measurementOfTime
        }
        postSurveyOn
        isDefault
        name
      }
      clever {
        id
      }
      classLink {
        sourcedId
        tenantId
        appId
        lastSync
      }
      surveyInfo {
        middleSchoolPreSurvey
        middleSchoolPostSurvey
        highSchoolPreSurvey
        highSchoolPostSurvey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupAdminGQL extends Apollo.Query<GetGroupAdminQuery, GetGroupAdminQueryVariables> {
  document = GetGroupAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupNameDocument = gql`
  query GetGroupName($getGroupDto: GetGroupDto!) {
    getGroup(getGroupDto: $getGroupDto) {
      name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupNameGQL extends Apollo.Query<GetGroupNameQuery, GetGroupNameQueryVariables> {
  document = GetGroupNameDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupsNameIdDocument = gql`
  query GetGroupsNameId($getGroupsDto: GetGroupsDto!) {
    getGroups(getGroupsDto: $getGroupsDto) {
      _id
      name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupsNameIdGQL extends Apollo.Query<GetGroupsNameIdQuery, GetGroupsNameIdQueryVariables> {
  document = GetGroupsNameIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetNewslettersDocument = gql`
  query getNewsletters {
    getNewsletters {
      storageURL
      createdAt
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetNewslettersGQL extends Apollo.Query<GetNewslettersQuery, GetNewslettersQueryVariables> {
  document = GetNewslettersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetPortalNotificationsForUserDocument = gql`
  query getPortalNotificationsForUser($user: String!) {
    getPortalNotificationsForUser(user: $user) {
      _id
      title
      message {
        key
      }
      type
      read
      deleted
      updateTime
      creationDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetPortalNotificationsForUserGQL extends Apollo.Query<GetPortalNotificationsForUserQuery, GetPortalNotificationsForUserQueryVariables> {
  document = GetPortalNotificationsForUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUnitDocument = gql`
  query getUnit($getActivityUnitDto: GetActivityUnitDto!) {
    getActivityUnit(getActivityUnitDto: $getActivityUnitDto) {
      _id
      name
      isActive
      inProgress
      code
      pacingGuide
      activities {
        _id
        name
        isActive
        isAdvanced
        creationDate
        thumbnail
        recommendationScore
        assignment {
          _id
        }
        onlineActivity {
          aId
        }
      }
      stateStandards {
        _id
        abbr
        name
        standards {
          _id
          code
          description
          subStandards {
            code
            description
          }
        }
      }
      courses {
        _id
        code
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUnitGQL extends Apollo.Query<GetUnitQuery, GetUnitQueryVariables> {
  document = GetUnitDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUserDocument = gql`
  query GetUser($getUserDto: GetUserDto!) {
    getUser(getUserDto: $getUserDto) {
      _id
      email
      isDemo
      state
      role
      profile {
        firstName
        lastName
        school
      }
      analytics {
        numLogins
        lastLogin
        creationDate
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
  document = GetUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUserDetailsAdminDocument = gql`
  query getUserDetailsAdmin($getUserDto: GetUserDto!) {
    getUserAdmin(getUserDto: $getUserDto) {
      _id
      analytics {
        creationDate
        lastLogin
        numLogins
      }
      apiToken
      classrooms {
        _id
        classCode
        color
        creationDate
        endDate
        group {
          _id
        }
        invites
        name
        period
        startDate
        students {
          _id
        }
        subject
        teachers {
          _id
          email
        }
        uuid
      }
      developer
      isDemo
      email
      ssoProfile {
        googleSub
      }
      group {
        _id
        address {
          county
          state
        }
        classrooms {
          _id
        }
        creationDate
        districtAdmins {
          _id
        }
        domains
        hasSciOps
        groupCodes {
          _id
        }
        isSurveysOn
        isTeacherSignupOn
        name
        students {
          _id
        }
        teachers {
          _id
        }
        uuid
      }
      profile {
        character
        firstName
        lastName
        phone
        pictureUrl {
          color
          img
        }
        school
      }
      eduProfile {
        educationLevel
        gradesTaught
        subjectsTaught
        courseCategory
        activityType
        units
      }
      role
      sciopsData {
        lastLogin
        firstPlayed
        numLogins
        totalTime
        gameSaveData {
          career {
            careerKey
          }
          characters {
            charAki {
              appCurrency
              charKey
              currency
              items {
                defaultData
                gear
                selData
              }
              scientist
              selCurrency
              unitVariant
              upgrades {
                defaultIndex
                gear
                group
                index
                max
                upgradeKey
              }
            }
            charNicole {
              appCurrency
              charKey
              currency
              items {
                defaultData
                gear
                selData
              }
              scientist
              selCurrency
              unitVariant
              upgrades {
                defaultIndex
                gear
                group
                index
                max
                upgradeKey
              }
            }
            charZia {
              appCurrency
              charKey
              currency
              items {
                defaultData
                gear
                selData
              }
              scientist
              selCurrency
              unitVariant
              upgrades {
                defaultIndex
                gear
                group
                index
                max
                upgradeKey
              }
            }
          }
          levels {
            campaign {
              category
              highestLevel
              levels {
                levelKey
                maxStars
                numComplete
                numEfficientStarsEarned
                numPlays
                numSurvivabilityStarsEarned
                numTurnStarsEarned
              }
            }
            challenge {
              category
              highestLevel
              levels {
                levelKey
                maxStars
                numComplete
                numEfficientStarsEarned
                numPlays
                numSurvivabilityStarsEarned
                numTurnStarsEarned
              }
            }
          }
          notifications {
            careerNotifications {
              charKey
              messageId
              notificationId
              type
              unlockKey
            }
            characterNotifications {
              charKey
              messageId
              notificationId
              type
              unlockKey
            }
            gearNotifications {
              charKey
              messageId
              notificationId
              type
              unlockKey
            }
            upgradeNotifications {
              charKey
              messageId
              notificationId
              type
              unlockKey
            }
            viewedMessages
            viewedNotifications
          }
        }
      }
      state
      surveyInvites {
        surveyUniqueName
        creationDate
      }
      completedTours
      verification {
        email
        profile
      }
      comics {
        comicName
        read
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUserDetailsAdminGQL extends Apollo.Query<GetUserDetailsAdminQuery, GetUserDetailsAdminQueryVariables> {
  document = GetUserDetailsAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUsersDocument = gql`
  query GetUsers($getUsersDto: GetUsersDto!, $queryParams: FindQueryParams) {
    getUsers(getUsersDto: $getUsersDto, queryParams: $queryParams) {
      edges {
        cursor
        node {
          _id
          email
          role
          isDemo
          developer
          analytics {
            creationDate
            lastLogin
            numLogins
          }
          group {
            _id
            name
          }
          numClassrooms
          state
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        pageLimit
        nextPageLink {
          after
          size
        }
        prevPageLink {
          before
          size
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
  document = GetUsersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UsersSearchAdminDocument = gql`
  query UsersSearchAdmin($getUsersDto: GetUsersDto!) {
    getUsers(getUsersDto: $getUsersDto) {
      edges {
        node {
          _id
          email
          role
          state
          isDemo
          profile {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UsersSearchAdminGQL extends Apollo.Query<UsersSearchAdminQuery, UsersSearchAdminQueryVariables> {
  document = UsersSearchAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UsersSearchByNameAdminDocument = gql`
  query UsersSearchByNameAdmin($getUsersByNameDto: GetUsersByNameDto!) {
    getUsersByName(getUsersByNameDto: $getUsersByNameDto) {
      _id
      email
      role
      state
      isDemo
      profile {
        firstName
        lastName
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UsersSearchByNameAdminGQL extends Apollo.Query<UsersSearchByNameAdminQuery, UsersSearchByNameAdminQueryVariables> {
  document = UsersSearchByNameAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MoveUserGroupAdminDocument = gql`
  mutation moveUserGroupAdmin($getUserDto: GetUserDto!, $targetGroupDto: GetGroupDto!) {
    moveUserToGroup(getUserDto: $getUserDto, targetGroupDto: $targetGroupDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class MoveUserGroupAdminGQL extends Apollo.Mutation<MoveUserGroupAdminMutation, MoveUserGroupAdminMutationVariables> {
  document = MoveUserGroupAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteClassroomAdminDocument = gql`
  mutation deleteClassroomAdmin($getClassroom: GetClassroomDto!) {
    deleteClassroom(getClassroom: $getClassroom)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteClassroomAdminGQL extends Apollo.Mutation<DeleteClassroomAdminMutation, DeleteClassroomAdminMutationVariables> {
  document = DeleteClassroomAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveGroupDomainDocument = gql`
  mutation removeGroupDomain($getGroup: GetGroupDto!, $domainToRemove: String!) {
    removeGroupDomain(getGroup: $getGroup, domainToRemove: $domainToRemove) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveGroupDomainGQL extends Apollo.Mutation<RemoveGroupDomainMutation, RemoveGroupDomainMutationVariables> {
  document = RemoveGroupDomainDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveStudentAdminDocument = gql`
  mutation removeStudentAdmin($getClassroom: GetClassroomDto!, $getUser: GetUserDto!) {
    removeStudent(getClassroom: $getClassroom, getUser: $getUser) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveStudentAdminGQL extends Apollo.Mutation<RemoveStudentAdminMutation, RemoveStudentAdminMutationVariables> {
  document = RemoveStudentAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveTagFromStudentActivityDocument = gql`
  mutation removeTagFromStudentActivity($studentActivityId: String!, $activityTag: String!) {
    removeTagFromStudentActivity(studentActivityId: $studentActivityId, activityTag: $activityTag) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveTagFromStudentActivityGQL extends Apollo.Mutation<RemoveTagFromStudentActivityMutation, RemoveTagFromStudentActivityMutationVariables> {
  document = RemoveTagFromStudentActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveUserByAdminDocument = gql`
  mutation removeUserByAdmin($userId: GetUserDto!) {
    removeUserByAdmin(userId: $userId)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveUserByAdminGQL extends Apollo.Mutation<RemoveUserByAdminMutation, RemoveUserByAdminMutationVariables> {
  document = RemoveUserByAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveUserSurveyInviteDocument = gql`
  mutation removeUserSurveyInvite($getUser: GetUserDto!, $surveyName: String!) {
    removeUserSurveyInvite(getUser: $getUser, surveyName: $surveyName) {
      _id
      surveyInvites {
        surveyUniqueName
        creationDate
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveUserSurveyInviteGQL extends Apollo.Mutation<RemoveUserSurveyInviteMutation, RemoveUserSurveyInviteMutationVariables> {
  document = RemoveUserSurveyInviteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddStateStandardToUnitDocument = gql`
  mutation addStateStandardToUnit($getActivityUnitDto: GetActivityUnitDto!, $createStateStandardDto: CreateStateStandardDto!) {
    addStateStandardToUnit(getActivityUnitDto: $getActivityUnitDto, createStateStandardDto: $createStateStandardDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddStateStandardToUnitGQL extends Apollo.Mutation<AddStateStandardToUnitMutation, AddStateStandardToUnitMutationVariables> {
  document = AddStateStandardToUnitDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveStateStandardFromUnitDocument = gql`
  mutation removeStateStandardFromUnit($getActivityUnitDto: GetActivityUnitDto!, $stateStandardId: String!) {
    removeStateStandardFromUnit(getActivityUnitDto: $getActivityUnitDto, stateStandardId: $stateStandardId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveStateStandardFromUnitGQL extends Apollo.Mutation<RemoveStateStandardFromUnitMutation, RemoveStateStandardFromUnitMutationVariables> {
  document = RemoveStateStandardFromUnitDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateStateStandardDocument = gql`
  mutation updateStateStandard($getActivityUnitDto: GetActivityUnitDto!, $stateStandardId: String!, $updateStateStandardDto: UpdateStateStandardDto!) {
    updateStateStandard(getActivityUnitDto: $getActivityUnitDto, stateStandardId: $stateStandardId, updateStateStandardDto: $updateStateStandardDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateStateStandardGQL extends Apollo.Mutation<UpdateStateStandardMutation, UpdateStateStandardMutationVariables> {
  document = UpdateStateStandardDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddUnitStandardToStateStandardDocument = gql`
  mutation addUnitStandardToStateStandard($getActivityUnitDto: GetActivityUnitDto!, $stateStandardId: String!, $createUnitStandardDto: CreateUnitStandardDto!) {
    addUnitStandardToStateStandard(getActivityUnitDto: $getActivityUnitDto, stateStandardId: $stateStandardId, createUnitStandardDto: $createUnitStandardDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddUnitStandardToStateStandardGQL extends Apollo.Mutation<AddUnitStandardToStateStandardMutation, AddUnitStandardToStateStandardMutationVariables> {
  document = AddUnitStandardToStateStandardDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveUnitStandardFromStateStandardDocument = gql`
  mutation removeUnitStandardFromStateStandard($getActivityUnitDto: GetActivityUnitDto!, $stateStandardId: String!, $unitStandardId: String!) {
    removeUnitStandardFromStateStandard(getActivityUnitDto: $getActivityUnitDto, stateStandardId: $stateStandardId, unitStandardId: $unitStandardId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveUnitStandardFromStateStandardGQL extends Apollo.Mutation<RemoveUnitStandardFromStateStandardMutation, RemoveUnitStandardFromStateStandardMutationVariables> {
  document = RemoveUnitStandardFromStateStandardDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetStudentActivitiesInfoDocument = gql`
  query GetStudentActivitiesInfo {
    coursesCount: getActivityCoursesCount
    unitsCount: getActivityUnitsCount
    activitiesCount: getStudentActivitiesCount(getStudentActivitiesDto: {})
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetStudentActivitiesInfoGQL extends Apollo.Query<GetStudentActivitiesInfoQuery, GetStudentActivitiesInfoQueryVariables> {
  document = GetStudentActivitiesInfoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UnlockSciOpsLvlsForClassroomDocument = gql`
  mutation unlockSciOpsLvlsForClassroom($getClassroomDto: GetClassroomDto!, $lvlKey: String!) {
    unlockSciOpsLvlsForClassroom(getClassroomDto: $getClassroomDto, lvlKey: $lvlKey) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UnlockSciOpsLvlsForClassroomGQL extends Apollo.Mutation<UnlockSciOpsLvlsForClassroomMutation, UnlockSciOpsLvlsForClassroomMutationVariables> {
  document = UnlockSciOpsLvlsForClassroomDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UnlockSciOpsLvlsForClassroomsDocument = gql`
  mutation unlockSciOpsLvlsForClassrooms($classRoomDtoArray: [GetClassroomDto!]!, $lvlKey: String!) {
    unlockSciOpsLvlsForClassrooms(classRoomDtoArray: $classRoomDtoArray, lvlKey: $lvlKey)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UnlockSciOpsLvlsForClassroomsGQL extends Apollo.Mutation<UnlockSciOpsLvlsForClassroomsMutation, UnlockSciOpsLvlsForClassroomsMutationVariables> {
  document = UnlockSciOpsLvlsForClassroomsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUnitAndActivitiesIsActiveStatusDocument = gql`
  mutation updateUnitAndActivitiesIsActiveStatus($activityUnitId: String!, $isActive: Boolean!) {
    changeWholeUnitIsActiveStatus(activityUnitId: $activityUnitId, isActive: $isActive) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUnitAndActivitiesIsActiveStatusGQL extends Apollo.Mutation<
  UpdateUnitAndActivitiesIsActiveStatusMutation,
  UpdateUnitAndActivitiesIsActiveStatusMutationVariables
> {
  document = UpdateUnitAndActivitiesIsActiveStatusDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateCourseDocument = gql`
  mutation updateCourse($getActivityCourseDto: GetActivityCourseDto!, $updateActivityCourseDto: UpdateActivityCourseDto!) {
    updateActivityCourse(getActivityCourseDto: $getActivityCourseDto, updateActivityCourseDto: $updateActivityCourseDto) {
      _id
      name
      code
      units {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateCourseGQL extends Apollo.Mutation<UpdateCourseMutation, UpdateCourseMutationVariables> {
  document = UpdateCourseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateGroupSurveysDocument = gql`
  mutation updateGroupSurveys($getGroup: GetGroupDto!, $isSurveysOn: Boolean!) {
    toggleOnGroupSurveys(getGroup: $getGroup, isSurveysOn: $isSurveysOn) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateGroupSurveysGQL extends Apollo.Mutation<UpdateGroupSurveysMutation, UpdateGroupSurveysMutationVariables> {
  document = UpdateGroupSurveysDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateGroupAddressDocument = gql`
  mutation updateGroupAddress($getGroup: GetGroupDto!, $createGroupAddress: CreateGroupAddressDto!) {
    editGroupAddress(getGroup: $getGroup, createGroupAddress: $createGroupAddress) {
      _id
      address {
        county
        state
      }
      numStudents
      numDistrictAdmins
      numTeachers
      numClassrooms
      creationDate
      districtAdmins {
        _id
        analytics {
          lastLogin
          numLogins
        }
        email
        profile {
          firstName
          lastName
        }
      }
      domains
      hasSciOps
      groupCodes {
        _id
        code
        type
        creationDate
        expirationDate
        isActive
        numLicenses
        users {
          _id
        }
      }
      isSurveysOn
      isInPilot
      isDemo
      name
      students {
        _id
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        email
      }
      teachers {
        _id
        analytics {
          lastLogin
          numLogins
        }
        numClassrooms
        email
        profile {
          firstName
          lastName
        }
      }
      districtSemesters {
        _id
        startDate
        endDate
        postSurveyWindow {
          lengthOfTime
          measurementOfTime
        }
        postSurveyOn
        isDefault
        name
      }
      clever {
        id
      }
      surveyInfo {
        middleSchoolPreSurvey
        middleSchoolPostSurvey
        highSchoolPreSurvey
        highSchoolPostSurvey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateGroupAddressGQL extends Apollo.Mutation<UpdateGroupAddressMutation, UpdateGroupAddressMutationVariables> {
  document = UpdateGroupAddressDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateGroupNameDocument = gql`
  mutation updateGroupName($getGroup: GetGroupDto!, $newName: String!) {
    editGroupName(getGroup: $getGroup, newName: $newName) {
      _id
      address {
        county
        state
      }
      numStudents
      numDistrictAdmins
      numTeachers
      numClassrooms
      creationDate
      districtAdmins {
        _id
        analytics {
          lastLogin
          numLogins
        }
        email
        profile {
          firstName
          lastName
        }
      }
      domains
      hasSciOps
      groupCodes {
        _id
        code
        type
        creationDate
        expirationDate
        isActive
        numLicenses
        users {
          _id
        }
      }
      isSurveysOn
      isInPilot
      isDemo
      name
      students {
        _id
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        email
      }
      teachers {
        _id
        analytics {
          lastLogin
          numLogins
        }
        numClassrooms
        email
        profile {
          firstName
          lastName
        }
      }
      districtSemesters {
        _id
        startDate
        endDate
        postSurveyWindow {
          lengthOfTime
          measurementOfTime
        }
        postSurveyOn
        isDefault
        name
      }
      clever {
        id
      }
      surveyInfo {
        middleSchoolPreSurvey
        middleSchoolPostSurvey
        highSchoolPreSurvey
        highSchoolPostSurvey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateGroupNameGQL extends Apollo.Mutation<UpdateGroupNameMutation, UpdateGroupNameMutationVariables> {
  document = UpdateGroupNameDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateGroupSciOpsAccessDocument = gql`
  mutation updateGroupSciOpsAccess($getGroup: GetGroupDto!, $hasSciOps: Boolean!) {
    setGroupHasSciOps(getGroup: $getGroup, hasSciOps: $hasSciOps) {
      _id
      address {
        county
        state
      }
      numStudents
      numDistrictAdmins
      numTeachers
      numClassrooms
      creationDate
      districtAdmins {
        _id
        analytics {
          lastLogin
          numLogins
        }
        email
        profile {
          firstName
          lastName
        }
      }
      domains
      hasSciOps
      groupCodes {
        _id
        code
        type
        creationDate
        expirationDate
        isActive
        numLicenses
        users {
          _id
        }
      }
      isSurveysOn
      isInPilot
      isDemo
      name
      students {
        _id
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        email
      }
      teachers {
        _id
        analytics {
          lastLogin
          numLogins
        }
        numClassrooms
        email
        profile {
          firstName
          lastName
        }
      }
      districtSemesters {
        _id
        startDate
        endDate
        postSurveyWindow {
          lengthOfTime
          measurementOfTime
        }
        postSurveyOn
        isDefault
        name
      }
      clever {
        id
      }
      surveyInfo {
        middleSchoolPreSurvey
        middleSchoolPostSurvey
        highSchoolPreSurvey
        highSchoolPostSurvey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateGroupSciOpsAccessGQL extends Apollo.Mutation<UpdateGroupSciOpsAccessMutation, UpdateGroupSciOpsAccessMutationVariables> {
  document = UpdateGroupSciOpsAccessDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateGroupPilotStatusDocument = gql`
  mutation updateGroupPilotStatus($getGroupDto: GetGroupDto!, $isInPilot: Boolean!) {
    setPilotState(getGroupDto: $getGroupDto, isInPilot: $isInPilot) {
      _id
      address {
        county
        state
      }
      numStudents
      numDistrictAdmins
      numTeachers
      numClassrooms
      creationDate
      districtAdmins {
        _id
        analytics {
          lastLogin
          numLogins
        }
        email
        profile {
          firstName
          lastName
        }
      }
      domains
      hasSciOps
      groupCodes {
        _id
        code
        type
        creationDate
        expirationDate
        isActive
        numLicenses
        users {
          _id
        }
      }
      isSurveysOn
      isInPilot
      isDemo
      name
      students {
        _id
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        email
      }
      teachers {
        _id
        analytics {
          lastLogin
          numLogins
        }
        numClassrooms
        email
        profile {
          firstName
          lastName
        }
      }
      districtSemesters {
        _id
        startDate
        endDate
        postSurveyWindow {
          lengthOfTime
          measurementOfTime
        }
        postSurveyOn
        isDefault
        name
      }
      clever {
        id
      }
      surveyInfo {
        middleSchoolPreSurvey
        middleSchoolPostSurvey
        highSchoolPreSurvey
        highSchoolPostSurvey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateGroupPilotStatusGQL extends Apollo.Mutation<UpdateGroupPilotStatusMutation, UpdateGroupPilotStatusMutationVariables> {
  document = UpdateGroupPilotStatusDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateStudentActivityDocument = gql`
  mutation updateStudentActivity($studentActivityId: String!, $updateStudentActivityDto: UpdateStudentActivityDto!) {
    updateStudentActivity(studentActivityId: $studentActivityId, updateStudentActivityDto: $updateStudentActivityDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateStudentActivityGQL extends Apollo.Mutation<UpdateStudentActivityMutation, UpdateStudentActivityMutationVariables> {
  document = UpdateStudentActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateSurveyInviteInfoDocument = gql`
  mutation updateSurveyInviteInfo($getGroup: GetGroupDto!, $updateGroupSurveyInvitesDto: UpdateGroupSurveyInvitesDto!) {
    updateSurveyInviteInfo(getGroup: $getGroup, updateGroupSurveyInvitesDto: $updateGroupSurveyInvitesDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateSurveyInviteInfoGQL extends Apollo.Mutation<UpdateSurveyInviteInfoMutation, UpdateSurveyInviteInfoMutationVariables> {
  document = UpdateSurveyInviteInfoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateTeacherTrainingDatesDocument = gql`
  mutation updateTeacherTrainingDates($userIds: [String!]!, $trainingDate: DateTime!) {
    updateUserTrainingDates(userIds: $userIds, trainingDate: $trainingDate)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateTeacherTrainingDatesGQL extends Apollo.Mutation<UpdateTeacherTrainingDatesMutation, UpdateTeacherTrainingDatesMutationVariables> {
  document = UpdateTeacherTrainingDatesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUnitDocument = gql`
  mutation updateUnit($getActivityUnitDto: GetActivityUnitDto!, $updateActivityUnitDto: UpdateActivityUnitDto!) {
    updateActivityUnit(getActivityUnitDto: $getActivityUnitDto, updateActivityUnitDto: $updateActivityUnitDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUnitGQL extends Apollo.Mutation<UpdateUnitMutation, UpdateUnitMutationVariables> {
  document = UpdateUnitDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserProfileAdminDocument = gql`
  mutation updateUserProfileAdmin($getUser: GetUserDto!, $userProfile: UserProfileDto, $userProfilePicture: UserProfilePictureDto) {
    updateUserProfile(getUser: $getUser, userProfile: $userProfile, userProfilePicture: $userProfilePicture) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserProfileAdminGQL extends Apollo.Mutation<UpdateUserProfileAdminMutation, UpdateUserProfileAdminMutationVariables> {
  document = UpdateUserProfileAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SetUserDeveloperStatusAdminDocument = gql`
  mutation setUserDeveloperStatusAdmin($getUser: GetUserDto!, $isDev: Boolean!) {
    setUserDeveloperStatus(getUser: $getUser, isDev: $isDev) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SetUserDeveloperStatusAdminGQL extends Apollo.Mutation<SetUserDeveloperStatusAdminMutation, SetUserDeveloperStatusAdminMutationVariables> {
  document = SetUserDeveloperStatusAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SetUserDemoStatusAdminDocument = gql`
  mutation setUserDemoStatusAdmin($getUser: GetUserDto!, $isDemo: Boolean!) {
    setUserDemoStatus(getUser: $getUser, isDemo: $isDemo) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SetUserDemoStatusAdminGQL extends Apollo.Mutation<SetUserDemoStatusAdminMutation, SetUserDemoStatusAdminMutationVariables> {
  document = SetUserDemoStatusAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserTourAdminDocument = gql`
  mutation UpdateUserTourAdmin($getUser: GetUserDto!, $completedToursDto: CompletedToursDto!) {
    updateUserTour(getUser: $getUser, completedToursDto: $completedToursDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserTourAdminGQL extends Apollo.Mutation<UpdateUserTourAdminMutation, UpdateUserTourAdminMutationVariables> {
  document = UpdateUserTourAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const VerifyAllTeachersByGroupDocument = gql`
  mutation verifyAllTeachersByGroup($groupID: String!) {
    verifyAllTeachersByGroup(groupID: $groupID)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class VerifyAllTeachersByGroupGQL extends Apollo.Mutation<VerifyAllTeachersByGroupMutation, VerifyAllTeachersByGroupMutationVariables> {
  document = VerifyAllTeachersByGroupDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateGroupDocument = gql`
  mutation createGroup($createGroup: CreateGroupDto!, $createGroupAddress: CreateGroupAddressDto) {
    createGroup(createGroup: $createGroup, createGroupAddress: $createGroupAddress) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateGroupGQL extends Apollo.Mutation<CreateGroupMutation, CreateGroupMutationVariables> {
  document = CreateGroupDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateGroupCodeDocument = gql`
  mutation createGroupCode($groupId: String!, $isActive: Boolean!, $type: GroupCodeType!, $expirationDate: DateTime, $numLicenses: Int) {
    createGroupCode(groupId: $groupId, isActive: $isActive, type: $type, expirationDate: $expirationDate, numLicenses: $numLicenses) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateGroupCodeGQL extends Apollo.Mutation<CreateGroupCodeMutation, CreateGroupCodeMutationVariables> {
  document = CreateGroupCodeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllGroupsAdminTableDocument = gql`
  query GetAllGroupsAdminTable {
    getGroups(getGroupsDto: {}) {
      _id
      name
      address {
        state
      }
      numDistrictAdmins
      numTeachers
      numStudents
      numClassrooms
      isSurveysOn
      creationDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllGroupsAdminTableGQL extends Apollo.Query<GetAllGroupsAdminTableQuery, GetAllGroupsAdminTableQueryVariables> {
  document = GetAllGroupsAdminTableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupByNameDocument = gql`
  query GetGroupByName($name: String!) {
    getGroupAdmin(getGroupDto: { name: $name }) {
      _id
      name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupByNameGQL extends Apollo.Query<GetGroupByNameQuery, GetGroupByNameQueryVariables> {
  document = GetGroupByNameDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupClassroomsDocument = gql`
  query GetGroupClassrooms($groupId: String!) {
    getGroupAdmin(getGroupDto: { _id: $groupId }) {
      _id
      classrooms {
        _id
        name
        classCode
        teachers {
          email
        }
        numStudents
        subject
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupClassroomsGQL extends Apollo.Query<GetGroupClassroomsQuery, GetGroupClassroomsQueryVariables> {
  document = GetGroupClassroomsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupDetailsDocument = gql`
  query GetGroupDetails($groupId: String!) {
    getGroupAdmin(getGroupDto: { _id: $groupId }) {
      _id
      address {
        county
        state
      }
      numStudents
      numDistrictAdmins
      numTeachers
      numClassrooms
      creationDate
      domains
      hasSciOps
      groupCodes {
        _id
        code
        type
        creationDate
        expirationDate
        isActive
        numLicenses
        users {
          _id
        }
      }
      isSurveysOn
      isInPilot
      isDemo
      name
      districtSemesters {
        _id
        startDate
        endDate
        postSurveyWindow {
          lengthOfTime
          measurementOfTime
        }
        postSurveyOn
        isDefault
        name
      }
      clever {
        id
      }
      classLink {
        sourcedId
        tenantId
        appId
        lastSync
      }
      surveyInfo {
        middleSchoolPreSurvey
        middleSchoolPostSurvey
        highSchoolPreSurvey
        highSchoolPostSurvey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupDetailsGQL extends Apollo.Query<GetGroupDetailsQuery, GetGroupDetailsQueryVariables> {
  document = GetGroupDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupDistrictAdminsDocument = gql`
  query GetGroupDistrictAdmins($groupId: String!) {
    getGroupAdmin(getGroupDto: { _id: $groupId }) {
      _id
      districtAdmins {
        _id
        email
        profile {
          firstName
          lastName
        }
        analytics {
          lastLogin
          numLogins
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupDistrictAdminsGQL extends Apollo.Query<GetGroupDistrictAdminsQuery, GetGroupDistrictAdminsQueryVariables> {
  document = GetGroupDistrictAdminsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupStudentsDocument = gql`
  query GetGroupStudents($groupId: String!) {
    getGroupAdmin(getGroupDto: { _id: $groupId }) {
      _id
      students {
        _id
        email
        analytics {
          numLogins
          lastLogin
          creationDate
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupStudentsGQL extends Apollo.Query<GetGroupStudentsQuery, GetGroupStudentsQueryVariables> {
  document = GetGroupStudentsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupTeachersDocument = gql`
  query GetGroupTeachers($groupId: String!) {
    getGroupAdmin(getGroupDto: { _id: $groupId }) {
      _id
      teachers {
        _id
        email
        profile {
          firstName
          lastName
        }
        numClassrooms
        analytics {
          numLogins
          lastLogin
          trainingDate
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupTeachersGQL extends Apollo.Query<GetGroupTeachersQuery, GetGroupTeachersQueryVariables> {
  document = GetGroupTeachersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveClassLinkIdDocument = gql`
  mutation removeClassLinkId($getGroupDto: GetGroupDto!) {
    removeClassLinkId(getGroupDto: $getGroupDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveClassLinkIdGQL extends Apollo.Mutation<RemoveClassLinkIdMutation, RemoveClassLinkIdMutationVariables> {
  document = RemoveClassLinkIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveCleverIdDocument = gql`
  mutation removeCleverId($getGroupDto: GetGroupDto!) {
    removeCleverId(getGroupDto: $getGroupDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveCleverIdGQL extends Apollo.Mutation<RemoveCleverIdMutation, RemoveCleverIdMutationVariables> {
  document = RemoveCleverIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RunSisSyncDocument = gql`
  mutation runSisSync($getGroupDto: GetGroupDto!, $thirdPartyId: String!) {
    runSisSync(getGroupDto: $getGroupDto, thirdPartyId: $thirdPartyId)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RunSisSyncGQL extends Apollo.Mutation<RunSisSyncMutation, RunSisSyncMutationVariables> {
  document = RunSisSyncDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SetClassLinkIdDocument = gql`
  mutation setClassLinkId($getGroupDto: GetGroupDto!, $classLinkAppId: String!, $classLinkAccessToken: String!, $classLinkSourcedId: String!, $classLinkTenantId: String) {
    setClassLinkId(
      getGroupDto: $getGroupDto
      classLinkSourcedId: $classLinkSourcedId
      classLinkAccessToken: $classLinkAccessToken
      classLinkAppId: $classLinkAppId
      classLinkTenantId: $classLinkTenantId
    ) {
      _id
      classLink {
        sourcedId
        tenantId
        appId
        lastSync
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SetClassLinkIdGQL extends Apollo.Mutation<SetClassLinkIdMutation, SetClassLinkIdMutationVariables> {
  document = SetClassLinkIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SetCleverIdDocument = gql`
  mutation setCleverId($getGroupDto: GetGroupDto!, $cleverId: String!) {
    setCleverId(getGroupDto: $getGroupDto, cleverId: $cleverId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SetCleverIdGQL extends Apollo.Mutation<SetCleverIdMutation, SetCleverIdMutationVariables> {
  document = SetCleverIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateGroupInviteCodeDocument = gql`
  mutation updateGroupInviteCode($_id: String!, $update: UpdateGroupCodeDataDto!) {
    updateGroupCode(_id: $_id, update: $update) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateGroupInviteCodeGQL extends Apollo.Mutation<UpdateGroupInviteCodeMutation, UpdateGroupInviteCodeMutationVariables> {
  document = UpdateGroupInviteCodeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateGroupDemoStatusDocument = gql`
  mutation UpdateGroupDemoStatus($getGroupDto: GetGroupDto!, $isDemo: Boolean!) {
    setDemoState(getGroupDto: $getGroupDto, isDemo: $isDemo) {
      _id
      address {
        county
        state
      }
      numStudents
      numDistrictAdmins
      numTeachers
      numClassrooms
      creationDate
      districtAdmins {
        _id
        analytics {
          lastLogin
          numLogins
        }
        email
        profile {
          firstName
          lastName
        }
      }
      domains
      hasSciOps
      groupCodes {
        _id
        code
        type
        creationDate
        expirationDate
        isActive
        numLicenses
        users {
          _id
        }
      }
      isSurveysOn
      isInPilot
      isDemo
      name
      students {
        _id
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        email
      }
      teachers {
        _id
        analytics {
          lastLogin
          numLogins
        }
        numClassrooms
        email
        profile {
          firstName
          lastName
        }
      }
      districtSemesters {
        _id
        startDate
        endDate
        postSurveyWindow {
          lengthOfTime
          measurementOfTime
        }
        postSurveyOn
        isDefault
        name
      }
      clever {
        id
      }
      surveyInfo {
        middleSchoolPreSurvey
        middleSchoolPostSurvey
        highSchoolPreSurvey
        highSchoolPostSurvey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateGroupDemoStatusGQL extends Apollo.Mutation<UpdateGroupDemoStatusMutation, UpdateGroupDemoStatusMutationVariables> {
  document = UpdateGroupDemoStatusDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddAssignmentLinkToResourceDocument = gql`
  mutation AddAssignmentLinkToResource($resourceId: String!, $assignmentId: String!) {
    addAssignmentToStudentActivity(studentActivityId: $resourceId, assignmentId: $assignmentId) {
      _id
      assignment {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddAssignmentLinkToResourceGQL extends Apollo.Mutation<AddAssignmentLinkToResourceMutation, AddAssignmentLinkToResourceMutationVariables> {
  document = AddAssignmentLinkToResourceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateNewAssignmentFromResourceDocument = gql`
  mutation CreateNewAssignmentFromResource($createAssignmentDto: CreateAssignmentDto!) {
    createAssignment(dto: $createAssignmentDto) {
      _id
      type
      specialRequirement
      tags
      iconUrl
      name
      description
      version {
        major
        minor
        hotfix
        releaseDate
      }
      isActive
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateNewAssignmentFromResourceGQL extends Apollo.Mutation<CreateNewAssignmentFromResourceMutation, CreateNewAssignmentFromResourceMutationVariables> {
  document = CreateNewAssignmentFromResourceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAssignmentCountDocument = gql`
  query GetAssignmentCount($dto: GetManyAssignmentsDto!) {
    getAssignmentCount(dto: $dto)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAssignmentCountGQL extends Apollo.Query<GetAssignmentCountQuery, GetAssignmentCountQueryVariables> {
  document = GetAssignmentCountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAssignmentIdByDtoDocument = gql`
  query GetAssignmentIdByDto($dto: GetSingleAssignmentDto!) {
    getAssignmentByDto(dto: $dto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAssignmentIdByDtoGQL extends Apollo.Query<GetAssignmentIdByDtoQuery, GetAssignmentIdByDtoQueryVariables> {
  document = GetAssignmentIdByDtoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAssignmentLinkedToResourceDocument = gql`
  query GetAssignmentLinkedToResource($assignmentId: String!) {
    getAssignmentById(id: $assignmentId) {
      _id
      name
      iconUrl
      type
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAssignmentLinkedToResourceGQL extends Apollo.Query<GetAssignmentLinkedToResourceQuery, GetAssignmentLinkedToResourceQueryVariables> {
  document = GetAssignmentLinkedToResourceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveAssignmentLinkFromResourceDocument = gql`
  mutation RemoveAssignmentLinkFromResource($resourceId: String!) {
    removeAssignmentFromStudentActivity(studentActivityId: $resourceId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveAssignmentLinkFromResourceGQL extends Apollo.Mutation<RemoveAssignmentLinkFromResourceMutation, RemoveAssignmentLinkFromResourceMutationVariables> {
  document = RemoveAssignmentLinkFromResourceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUserDetailsDocument = gql`
  query getUserDetails($getUserDto: GetUserDto!) {
    getUserAdmin(getUserDto: $getUserDto) {
      _id
      analytics {
        creationDate
        lastLogin
        numLogins
      }
      classrooms {
        _id
      }
      developer
      isDemo
      email
      ssoProfile {
        googleSub
      }
      group {
        _id
      }
      profile {
        character
        firstName
        lastName
        phone
        pictureUrl {
          color
          img
        }
        school
      }
      eduProfile {
        educationLevel
        gradesTaught
        subjectsTaught
      }
      role
      sciopsData {
        lastLogin
        firstPlayed
        numLogins
        totalTime
        gameSaveData {
          _id
        }
      }
      state
      surveyInvites {
        surveyUniqueName
        creationDate
      }
      completedTours
      verification {
        email
        profile
      }
      comics {
        comicName
        read
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUserDetailsGQL extends Apollo.Query<GetUserDetailsQuery, GetUserDetailsQueryVariables> {
  document = GetUserDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetSalesTeamMembersDocument = gql`
  query GetSalesTeamMembers {
    getSalesTeamMembers {
      id
      team_member_name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetSalesTeamMembersGQL extends Apollo.Query<GetSalesTeamMembersQuery, GetSalesTeamMembersQueryVariables> {
  document = GetSalesTeamMembersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetSendGridTemplateListDocument = gql`
  query GetSendGridTemplateList {
    getSendGridTemplates {
      id
      name
      updated_at
      versions {
        name
        active
        editor
        subject
        thumbnail_url
        updated_at
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetSendGridTemplateListGQL extends Apollo.Query<GetSendGridTemplateListQuery, GetSendGridTemplateListQueryVariables> {
  document = GetSendGridTemplateListDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SendSalesEmailBlastDocument = gql`
  mutation SendSalesEmailBlast($dynamicData: [SalesDynamicData!]!, $emailData: SalesSendgridEmailDto!) {
    sendSalesEmailBlast(dynamicData: $dynamicData, emailData: $emailData)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SendSalesEmailBlastGQL extends Apollo.Mutation<SendSalesEmailBlastMutation, SendSalesEmailBlastMutationVariables> {
  document = SendSalesEmailBlastDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateDistrictAccountAdminPortalDocument = gql`
  mutation CreateDistrictAccountAdminPortal($districtCreationDTO: TeacherSignupDto!, $creationType: UserCreationType!) {
    createDistrictAdminPortal(districtCreationDTO: $districtCreationDTO, creationType: $creationType) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateDistrictAccountAdminPortalGQL extends Apollo.Mutation<CreateDistrictAccountAdminPortalMutation, CreateDistrictAccountAdminPortalMutationVariables> {
  document = CreateDistrictAccountAdminPortalDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateDistrictSemesterDocument = gql`
  mutation CreateDistrictSemester($getGroup: GetGroupDto!, $createDistrictSemesterDto: CreateDistrictSemesterDto!) {
    createDistrictSemester(getGroup: $getGroup, createDistrictSemesterDto: $createDistrictSemesterDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateDistrictSemesterGQL extends Apollo.Mutation<CreateDistrictSemesterMutation, CreateDistrictSemesterMutationVariables> {
  document = CreateDistrictSemesterDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateTeacherAccountAdminPortalDocument = gql`
  mutation CreateTeacherAccountAdminPortal($teacherCreationDTO: TeacherSignupDto!, $creationType: UserCreationType!) {
    createTeacherAdminPortal(teacherCreationDTO: $teacherCreationDTO, creationType: $creationType) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateTeacherAccountAdminPortalGQL extends Apollo.Mutation<CreateTeacherAccountAdminPortalMutation, CreateTeacherAccountAdminPortalMutationVariables> {
  document = CreateTeacherAccountAdminPortalDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreatTeacherAccountsDocument = gql`
  mutation CreatTeacherAccounts($teacherSignupDataArray: [TeacherSignupDto!]!, $password: String!, $creationType: UserCreationType!) {
    createTeachers(teacherSignupDataArray: $teacherSignupDataArray, password: $password, creationType: $creationType)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreatTeacherAccountsGQL extends Apollo.Mutation<CreatTeacherAccountsMutation, CreatTeacherAccountsMutationVariables> {
  document = CreatTeacherAccountsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateDistrictSemesterDocument = gql`
  mutation UpdateDistrictSemester($getGroup: GetGroupDto!, $districtSemesterId: String!, $updateDistrictSemesterDto: UpdateDistrictSemesterDto!) {
    updateDistrictSemester(getGroup: $getGroup, districtSemesterId: $districtSemesterId, updateDistrictSemesterDto: $updateDistrictSemesterDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateDistrictSemesterGQL extends Apollo.Mutation<UpdateDistrictSemesterMutation, UpdateDistrictSemesterMutationVariables> {
  document = UpdateDistrictSemesterDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ComparePasswordDocument = gql`
  query comparePassword($getUser: GetUserDto!, $password: String!) {
    compareUserPassword(getUser: $getUser, password: $password)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ComparePasswordGQL extends Apollo.Query<ComparePasswordQuery, ComparePasswordQueryVariables> {
  document = ComparePasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateClickTrackerDocument = gql`
  mutation CreateClickTracker($createClickTrackerDto: CreateClickTrackerDto!) {
    createClickTracker(createClickTrackerDto: $createClickTrackerDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateClickTrackerGQL extends Apollo.Mutation<CreateClickTrackerMutation, CreateClickTrackerMutationVariables> {
  document = CreateClickTrackerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ClassroomExistDocument = gql`
  query classroomExist($getClassroom: GetClassroomDto!) {
    classroomExist(getClassroom: $getClassroom)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ClassroomExistGQL extends Apollo.Query<ClassroomExistQuery, ClassroomExistQueryVariables> {
  document = ClassroomExistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateStudentDocument = gql`
  mutation createStudent($createStudent: CreateUserDto!, $classCode: String!, $creationType: UserCreationType!) {
    createStudent(createStudent: $createStudent, classCode: $classCode, creationType: $creationType) {
      _id
      email
      role
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateStudentGQL extends Apollo.Mutation<CreateStudentMutation, CreateStudentMutationVariables> {
  document = CreateStudentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateDistrictUserDocument = gql`
  mutation CreateDistrictUser($districtSignupData: TeacherSignupDto!, $creationType: UserCreationType!) {
    createDistrictUser(districtSignupData: $districtSignupData, creationType: $creationType) {
      _id
      email
      role
      profile {
        firstName
        lastName
        school
      }
      group {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateDistrictUserGQL extends Apollo.Mutation<CreateDistrictUserMutation, CreateDistrictUserMutationVariables> {
  document = CreateDistrictUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SendEmailForgotPasswordDocument = gql`
  mutation SendEmailForgotPassword($email: String!) {
    sendEmailForgotPassword(email: $email)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SendEmailForgotPasswordGQL extends Apollo.Mutation<SendEmailForgotPasswordMutation, SendEmailForgotPasswordMutationVariables> {
  document = SendEmailForgotPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetForgotPassDocument = gql`
  query GetForgotPass($uuid: String!) {
    getForgotPass(uuid: $uuid) {
      email
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetForgotPassGQL extends Apollo.Query<GetForgotPassQuery, GetForgotPassQueryVariables> {
  document = GetForgotPassDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProcessForgotPassDocument = gql`
  mutation ProcessForgotPass($uuid: String!, $newPassword: String!) {
    processForgotPass(uuid: $uuid, newPassword: $newPassword) {
      email
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ProcessForgotPassGQL extends Apollo.Mutation<ProcessForgotPassMutation, ProcessForgotPassMutationVariables> {
  document = ProcessForgotPassDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetMyGroupDocument = gql`
  query GetMyGroup {
    getMyGroup {
      _id
      students {
        _id
        email
        analytics {
          creationDate
          lastLogin
          numLogins
        }
      }
      teachers {
        _id
        role
        email
        profile {
          school
          firstName
          lastName
        }
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        numClassrooms
        classrooms {
          _id
          students {
            _id
          }
        }
      }
      classrooms {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetMyGroupGQL extends Apollo.Query<GetMyGroupQuery, GetMyGroupQueryVariables> {
  document = GetMyGroupDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetMyselfDocument = gql`
  query GetMyself {
    getMe {
      _id
      email
      role
      developer
      analytics {
        creationDate
        lastLogin
        numLogins
      }
      surveyInvites {
        surveyUniqueName
        creationDate
      }
      profile {
        firstName
        lastName
        phone
        school
        pictureUrl {
          img
          color
        }
        character
      }
      verification {
        email
        profile
      }
      completedTours
      state
      group {
        _id
        name
        address {
          state
          county
        }
        districtSemesters {
          _id
          startDate
          endDate
          name
        }
      }
      classrooms {
        _id
      }
      numClassrooms
      comics {
        read
        comicName
      }
      eduProfile {
        educationLevel
        gradesTaught
        subjectsTaught
        units
        courseCategory
        activityType
      }
      grade
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetMyselfGQL extends Apollo.Query<GetMyselfQuery, GetMyselfQueryVariables> {
  document = GetMyselfDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetGroupCodeDocument = gql`
  query getGroupCode($code: String!, $type: GroupCodeType!) {
    getGroupCode(code: $code, type: $type) {
      _id
      code
      creationDate
      expirationDate
      group {
        name
        domains
      }
      isActive
      numLicenses
      users {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetGroupCodeGQL extends Apollo.Query<GetGroupCodeQuery, GetGroupCodeQueryVariables> {
  document = GetGroupCodeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      _id
      email
      role
      developer
      analytics {
        creationDate
        lastLogin
        numLogins
      }
      surveyInvites {
        surveyUniqueName
        creationDate
      }
      profile {
        firstName
        lastName
        phone
        school
        pictureUrl {
          img
          color
        }
        character
      }
      verification {
        email
        profile
      }
      completedTours
      state
      group {
        _id
        name
        address {
          state
          county
        }
        districtSemesters {
          _id
          startDate
          endDate
          name
        }
      }
      numClassrooms
      comics {
        read
        comicName
      }
      eduProfile {
        educationLevel
        gradesTaught
        subjectsTaught
        courseCategory
        activityType
        units
      }
      grade
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
  document = LoginDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`;

@Injectable({
  providedIn: 'root',
})
export class LogoutGQL extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
  document = LogoutDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateTeacherUserDocument = gql`
  mutation CreateTeacherUser($teacherSignupData: TeacherSignupDto!, $userEduProfileDto: UserEduProfileDto, $creationType: UserCreationType!) {
    createTeacherUser(teacherSignupData: $teacherSignupData, userEduProfileDto: $userEduProfileDto, creationType: $creationType) {
      _id
      email
      role
      profile {
        firstName
        lastName
        school
      }
      group {
        _id
      }
      eduProfile {
        educationLevel
        gradesTaught
        subjectsTaught
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateTeacherUserGQL extends Apollo.Mutation<CreateTeacherUserMutation, CreateTeacherUserMutationVariables> {
  document = CreateTeacherUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TokenAuthenticationDocument = gql`
  mutation TokenAuthentication($token: String!) {
    tokenAuthentication(token: $token) {
      _id
      email
      role
      developer
      analytics {
        creationDate
        lastLogin
        numLogins
      }
      surveyInvites {
        surveyUniqueName
        creationDate
      }
      profile {
        firstName
        lastName
        phone
        school
        pictureUrl {
          img
          color
        }
        character
      }
      verification {
        email
        profile
      }
      completedTours
      state
      group {
        _id
        address {
          state
        }
        districtSemesters {
          _id
          startDate
          endDate
          name
        }
      }
      numClassrooms
      eduProfile {
        educationLevel
        gradesTaught
        subjectsTaught
      }
      grade
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TokenAuthenticationGQL extends Apollo.Mutation<TokenAuthenticationMutation, TokenAuthenticationMutationVariables> {
  document = TokenAuthenticationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserDocument = gql`
  mutation UpdateUser($getUser: GetUserDto!, $updateUser: UpdateUserDto!) {
    updateUser(getUser: $getUser, updateUser: $updateUser) {
      _id
      email
      role
      developer
      isDemo
      analytics {
        creationDate
        lastLogin
        numLogins
      }
      surveyInvites {
        surveyUniqueName
        creationDate
      }
      profile {
        firstName
        lastName
        phone
        school
        pictureUrl {
          img
          color
        }
        character
      }
      verification {
        email
        profile
      }
      completedTours
      state
      group {
        _id
        address {
          state
        }
        districtSemesters {
          _id
          startDate
          endDate
          name
        }
      }
      classrooms {
        _id
      }
      comics {
        read
        comicName
      }
      eduProfile {
        educationLevel
        gradesTaught
        subjectsTaught
      }
      grade
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
  document = UpdateUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserPasswordDocument = gql`
  mutation UpdateUserPassword($getUser: GetUserDto!, $newPassword: String!) {
    updateUserPassword(getUser: $getUser, newPassword: $newPassword) {
      _id
      email
      role
      developer
      analytics {
        creationDate
        lastLogin
        numLogins
      }
      surveyInvites {
        surveyUniqueName
        creationDate
      }
      profile {
        firstName
        lastName
        phone
        school
        pictureUrl {
          img
          color
        }
        character
      }
      verification {
        email
        profile
      }
      state
      group {
        _id
        districtSemesters {
          _id
          startDate
          endDate
          name
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserPasswordGQL extends Apollo.Mutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables> {
  document = UpdateUserPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile($getUser: GetUserDto!, $userProfile: UserProfileDto!) {
    updateUserProfile(getUser: $getUser, userProfile: $userProfile) {
      _id
      email
      role
      developer
      analytics {
        creationDate
        lastLogin
        numLogins
      }
      surveyInvites {
        surveyUniqueName
        creationDate
      }
      profile {
        firstName
        lastName
        phone
        school
        pictureUrl {
          img
          color
        }
        character
      }
      verification {
        email
        profile
      }
      completedTours
      state
      group {
        _id
        address {
          state
        }
        districtSemesters {
          _id
          startDate
          endDate
          name
        }
      }
      classrooms {
        _id
      }
      comics {
        read
        comicName
      }
      eduProfile {
        educationLevel
        gradesTaught
        subjectsTaught
      }
      grade
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserProfileGQL extends Apollo.Mutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables> {
  document = UpdateUserProfileDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserTourDocument = gql`
  mutation UpdateUserTour($getUser: GetUserDto!, $completedToursDto: CompletedToursDto!) {
    updateUserTour(getUser: $getUser, completedToursDto: $completedToursDto) {
      _id
      email
      role
      developer
      analytics {
        creationDate
        lastLogin
        numLogins
      }
      surveyInvites {
        surveyUniqueName
        creationDate
      }
      profile {
        firstName
        lastName
        phone
        school
        pictureUrl {
          img
          color
        }
        character
      }
      verification {
        email
        profile
      }
      completedTours
      state
      group {
        _id
        address {
          state
        }
        districtSemesters {
          _id
          startDate
          endDate
          name
        }
      }
      classrooms {
        _id
      }
      eduProfile {
        educationLevel
        gradesTaught
        subjectsTaught
      }
      grade
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserTourGQL extends Apollo.Mutation<UpdateUserTourMutation, UpdateUserTourMutationVariables> {
  document = UpdateUserTourDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IsUsernameAvailableDocument = gql`
  query isUsernameAvailable($username: String!) {
    isUsernameAvailable(username: $username)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IsUsernameAvailableGQL extends Apollo.Query<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables> {
  document = IsUsernameAvailableDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const VerifyUserDocument = gql`
  mutation VerifyUser($uuid: String!) {
    verifyUser(uuid: $uuid)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class VerifyUserGQL extends Apollo.Mutation<VerifyUserMutation, VerifyUserMutationVariables> {
  document = VerifyUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetDaTeacherTrainingDataDocument = gql`
  query GetDATeacherTrainingData {
    getAllTeacherTrainings {
      hasCert
      user {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetDaTeacherTrainingDataGQL extends Apollo.Query<GetDaTeacherTrainingDataQuery, GetDaTeacherTrainingDataQueryVariables> {
  document = GetDaTeacherTrainingDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetDaGroupDataDocument = gql`
  query GetDAGroupData {
    getMyGroup {
      _id
      name
      address {
        state
      }
      numStudents
      numTeachers
      numClassrooms
      teachers {
        _id
        email
        role
        profile {
          firstName
          lastName
          school
        }
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        numClassrooms
        classrooms {
          numStudents
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetDaGroupDataGQL extends Apollo.Query<GetDaGroupDataQuery, GetDaGroupDataQueryVariables> {
  document = GetDaGroupDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetUserDetailsDistrictDocument = gql`
  query getUserDetailsDistrict($getUserDto: GetUserDto!) {
    getUserDistrict(getUserDto: $getUserDto) {
      _id
      classrooms {
        _id
        classCode
        color
        creationDate
        endDate
        invites
        name
        period
        startDate
        students {
          _id
          sciopsData {
            lastLogin
            firstPlayed
            numLogins
            totalTime
            gameSaveData {
              levels {
                campaign {
                  levels {
                    levelKey
                    numComplete
                  }
                }
              }
            }
          }
        }
        subject
      }
      email
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetUserDetailsDistrictGQL extends Apollo.Query<GetUserDetailsDistrictQuery, GetUserDetailsDistrictQueryVariables> {
  document = GetUserDetailsDistrictDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClassroomDetailsDistrictDocument = gql`
  query getClassroomDetailsDistrict($getClassroom: GetClassroomDto!) {
    getClassroomDistrict(getClassroom: $getClassroom) {
      _id
      period
      subject
      startDate
      endDate
      name
      color
      uuid
      classCode
      invites
      creationDate
      students {
        _id
        analytics {
          creationDate
          lastLogin
          numLogins
        }
        email
        profile {
          character
          firstName
          lastName
          phone
          pictureUrl {
            color
            img
          }
          school
        }
        role
        sciopsData {
          lastLogin
          firstPlayed
          numLogins
          totalTime
          gameSaveData {
            levels {
              campaign {
                levels {
                  levelKey
                  numComplete
                }
              }
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetClassroomDetailsDistrictGQL extends Apollo.Query<GetClassroomDetailsDistrictQuery, GetClassroomDetailsDistrictQueryVariables> {
  document = GetClassroomDetailsDistrictDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CheckForPortalNotificationsDocument = gql`
  query CheckForPortalNotifications($userId: String!) {
    checkForPortalNotifications(userId: $userId) {
      _id
      title
      sender
      message {
        text
        key
      }
      type
      read
      deleted
      displayDate
      attachments
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CheckForPortalNotificationsGQL extends Apollo.Query<CheckForPortalNotificationsQuery, CheckForPortalNotificationsQueryVariables> {
  document = CheckForPortalNotificationsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetMyPortalNotificationsDocument = gql`
  query GetMyPortalNotifications {
    getMyPortalNotifications {
      _id
      title
      sender
      message {
        text
        key
      }
      type
      read
      deleted
      displayDate
      attachments
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetMyPortalNotificationsGQL extends Apollo.Query<GetMyPortalNotificationsQuery, GetMyPortalNotificationsQueryVariables> {
  document = GetMyPortalNotificationsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdatePortalNotificationDocument = gql`
  mutation UpdatePortalNotification($portalNotificationId: String!, $updatePortalNotificationDto: UpdatePortalNotificationDto!) {
    updatePortalNotification(portalNotificationId: $portalNotificationId, updatePortalNotificationDto: $updatePortalNotificationDto) {
      _id
      title
      sender
      message {
        text
        key
      }
      type
      read
      deleted
      displayDate
      attachments
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdatePortalNotificationGQL extends Apollo.Mutation<UpdatePortalNotificationMutation, UpdatePortalNotificationMutationVariables> {
  document = UpdatePortalNotificationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MarkPortalNotificationAsDeletedDocument = gql`
  mutation MarkPortalNotificationAsDeleted($portalNotificationId: String!) {
    markPortalNotificationAsDeleted(portalNotificationId: $portalNotificationId) {
      _id
      title
      sender
      message {
        text
        key
      }
      type
      read
      deleted
      displayDate
      attachments
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class MarkPortalNotificationAsDeletedGQL extends Apollo.Mutation<MarkPortalNotificationAsDeletedMutation, MarkPortalNotificationAsDeletedMutationVariables> {
  document = MarkPortalNotificationAsDeletedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IsAuthenticatedDocument = gql`
  query isAuthenticated {
    isAuthenticated
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGQL extends Apollo.Query<IsAuthenticatedQuery, IsAuthenticatedQueryVariables> {
  document = IsAuthenticatedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SendContactUsEmailDocument = gql`
  mutation SendContactUsEmail($emailBody: SendContactUsDto!) {
    sendContactUs(emailBody: $emailBody)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SendContactUsEmailGQL extends Apollo.Mutation<SendContactUsEmailMutation, SendContactUsEmailMutationVariables> {
  document = SendContactUsEmailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetSciOpsJwtDocument = gql`
  query getSciOpsJWT {
    getSciOpsJWT
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetSciOpsJwtGQL extends Apollo.Query<GetSciOpsJwtQuery, GetSciOpsJwtQueryVariables> {
  document = GetSciOpsJwtDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LogoutCurrentUserFromSciOpsDocument = gql`
  mutation LogoutCurrentUserFromSciOps {
    sciopsLogoutUser
  }
`;

@Injectable({
  providedIn: 'root',
})
export class LogoutCurrentUserFromSciOpsGQL extends Apollo.Mutation<LogoutCurrentUserFromSciOpsMutation, LogoutCurrentUserFromSciOpsMutationVariables> {
  document = LogoutCurrentUserFromSciOpsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CheckStudentActivityAccessDocument = gql`
  query CheckStudentActivityAccess($userId: String!, $submissionManagerId: String!) {
    checkStudentActivityAccess(userId: $userId, submissionManagerId: $submissionManagerId)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CheckStudentActivityAccessGQL extends Apollo.Query<CheckStudentActivityAccessQuery, CheckStudentActivityAccessQueryVariables> {
  document = CheckStudentActivityAccessDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MarkSubmissionManagerAsCompleteDocument = gql`
  mutation MarkSubmissionManagerAsComplete($submissionManagerId: String!) {
    markSubmissionManagerAsComplete(submissionManagerId: $submissionManagerId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class MarkSubmissionManagerAsCompleteGQL extends Apollo.Mutation<MarkSubmissionManagerAsCompleteMutation, MarkSubmissionManagerAsCompleteMutationVariables> {
  document = MarkSubmissionManagerAsCompleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetSubmissionManagersForStudentDocument = gql`
  query GetSubmissionManagersForStudent($dto: GetManySubmissionManagersDto!) {
    getManySubmissionManagers(dto: $dto) {
      _id
      assignment {
        _id
        type
        iconUrl
        name
        description
        awsLink
        aId
        creationDate
        availableActions
        questions {
          qId
          number
          section {
            sId
            name
            weight
          }
          text
          screenshotUrl
          standards {
            state
            code
            description
          }
          type
          answerType
          acceptedAnswers
          weight
          possibleAnswerChoices
        }
        version {
          major
          minor
          hotfix
          releaseDate
        }
        isActive
        specialRequirement
        isBrotliCompressed
        isAutoGradable
      }
      assignmentManager {
        _id
        classroom {
          _id
        }
      }
      controls {
        allowLate
        dueDate
        startDate
        maxAttempts
      }
      isFinished
      needsReview
      creationDate
      isOverride
      submissions {
        _id
        isComplete
        needsReview
        attempt
        grade
        creationDate
        completionDate
        submissionManager {
          _id
        }
        assignment {
          _id
        }
        answers {
          _id
          question {
            qId
            number
            section {
              sId
              name
              weight
            }
            text
            screenshotUrl
            standards {
              state
              code
              description
            }
            type
            answerType
            acceptedAnswers
            weight
            possibleAnswerChoices
          }
          grade
          needsReview
          answer
          answerType
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetSubmissionManagersForStudentGQL extends Apollo.Query<GetSubmissionManagersForStudentQuery, GetSubmissionManagersForStudentQueryVariables> {
  document = GetSubmissionManagersForStudentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SaveAssignmentSubmissionProgressDocument = gql`
  mutation SaveAssignmentSubmissionProgress($submissionManagerId: String!, $answers: [SaveAssignmentSubmissionAnswerDto!]!, $isComplete: Boolean!) {
    saveAssignmentSubmissionProgress(submissionManagerId: $submissionManagerId, answers: $answers, isComplete: $isComplete) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SaveAssignmentSubmissionProgressGQL extends Apollo.Mutation<SaveAssignmentSubmissionProgressMutation, SaveAssignmentSubmissionProgressMutationVariables> {
  document = SaveAssignmentSubmissionProgressDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateComicOnUserDocument = gql`
  mutation UpdateComicOnUser($getUser: GetUserDto!, $updateUserComicDto: UpdateUserComicDto!) {
    updateComicOnUser(getUser: $getUser, updateUserComicDto: $updateUserComicDto) {
      _id
      comics {
        read
        comicName
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateComicOnUserGQL extends Apollo.Mutation<UpdateComicOnUserMutation, UpdateComicOnUserMutationVariables> {
  document = UpdateComicOnUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const JoinClassroomDocument = gql`
  mutation JoinClassroom($classCode: String!) {
    joinClassroom(classCode: $classCode) {
      _id
      name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class JoinClassroomGQL extends Apollo.Mutation<JoinClassroomMutation, JoinClassroomMutationVariables> {
  document = JoinClassroomDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetSciopsHighestLevelDocument = gql`
  query GetSciopsHighestLevel {
    getSciopsHighestLevel
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetSciopsHighestLevelGQL extends Apollo.Query<GetSciopsHighestLevelQuery, GetSciopsHighestLevelQueryVariables> {
  document = GetSciopsHighestLevelDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CheckToAssignSurveyInviteForUserDocument = gql`
  mutation CheckToAssignSurveyInviteForUser($getUserDto: GetUserDto!) {
    checkToAssignSurveyInviteForUser(getUserDto: $getUserDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CheckToAssignSurveyInviteForUserGQL extends Apollo.Mutation<CheckToAssignSurveyInviteForUserMutation, CheckToAssignSurveyInviteForUserMutationVariables> {
  document = CheckToAssignSurveyInviteForUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateSurveyResultDocument = gql`
  mutation createSurveyResult($surveyResultData: CreateSurveyResultDto!) {
    createSurveyResult(surveyResultData: $surveyResultData) {
      _id
      surveyId
      versionNumber
      user {
        _id
      }
      results {
        answer
        questionCode
        section
      }
      consent {
        date
        agree
      }
      completed
      completedDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateSurveyResultGQL extends Apollo.Mutation<CreateSurveyResultMutation, CreateSurveyResultMutationVariables> {
  document = CreateSurveyResultDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetSurveyResultDocument = gql`
  query GetSurveyResult($surveyId: GetSurveyResultDto!) {
    getSurveyResult(surveyId: $surveyId) {
      _id
      surveyId
      versionNumber
      user {
        _id
      }
      results {
        answer
        questionCode
        section
      }
      consent {
        date
        agree
      }
      completed
      completedDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetSurveyResultGQL extends Apollo.Query<GetSurveyResultQuery, GetSurveyResultQueryVariables> {
  document = GetSurveyResultDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetSurveyDataDocument = gql`
  query GetSurveyData($surveyId: GetSurveyDataDto!) {
    getSurveyData(surveyId: $surveyId) {
      _id
      name
      surveyId
      version
      sections {
        doRandomization
        title
        instruction
        imgData {
          position
          url
        }
        questions {
          isRequired
          text
          type
          choices
          code
          questionImg
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetSurveyDataGQL extends Apollo.Query<GetSurveyDataQuery, GetSurveyDataQueryVariables> {
  document = GetSurveyDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MarkSurveyResultCompleteDocument = gql`
  mutation MarkSurveyResultComplete($surveyId: GetSurveyResultDto!) {
    markSurveyResultComplete(surveyId: $surveyId) {
      _id
      surveyId
      versionNumber
      user {
        _id
      }
      results {
        answer
        questionCode
        section
      }
      consent {
        date
        agree
      }
      completed
      completedDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class MarkSurveyResultCompleteGQL extends Apollo.Mutation<MarkSurveyResultCompleteMutation, MarkSurveyResultCompleteMutationVariables> {
  document = MarkSurveyResultCompleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SubmitSurveyResultAnswersDocument = gql`
  mutation submitSurveyResultAnswers($surveyId: GetSurveyResultDto!, $results: [SurveyResultsDataDto!]!) {
    submitSurveyResultAnswers(surveyId: $surveyId, results: $results) {
      _id
      surveyId
      versionNumber
      user {
        _id
      }
      results {
        answer
        questionCode
        section
      }
      consent {
        date
        agree
      }
      completed
      completedDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SubmitSurveyResultAnswersGQL extends Apollo.Mutation<SubmitSurveyResultAnswersMutation, SubmitSurveyResultAnswersMutationVariables> {
  document = SubmitSurveyResultAnswersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddConsentToSurveyResultDocument = gql`
  mutation addConsentToSurveyResult($surveyId: GetSurveyResultDto!) {
    addConsentToSurveyResult(surveyId: $surveyId) {
      _id
      surveyId
      versionNumber
      user {
        _id
      }
      results {
        answer
        questionCode
        section
      }
      consent {
        date
        agree
      }
      completed
      completedDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddConsentToSurveyResultGQL extends Apollo.Mutation<AddConsentToSurveyResultMutation, AddConsentToSurveyResultMutationVariables> {
  document = AddConsentToSurveyResultDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AssignClassroomAssignmentDocument = gql`
  mutation AssignClassroomAssignment($dto: CreateAssignmentManagerDto!) {
    assignClassroomAssignment(dto: $dto) {
      _id
      assignment {
        _id
        aId
        name
      }
      classroom {
        _id
        name
      }
      creationDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AssignClassroomAssignmentGQL extends Apollo.Mutation<AssignClassroomAssignmentMutation, AssignClassroomAssignmentMutationVariables> {
  document = AssignClassroomAssignmentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteAssignmentManagerDocument = gql`
  mutation deleteAssignmentManager($assignmentManagerId: String!) {
    deleteAssignmentManager(assignmentManagerId: $assignmentManagerId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteAssignmentManagerGQL extends Apollo.Mutation<DeleteAssignmentManagerMutation, DeleteAssignmentManagerMutationVariables> {
  document = DeleteAssignmentManagerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteClassroomDocument = gql`
  mutation DeleteClassroom($getClassroom: GetClassroomDto!) {
    deleteClassroom(getClassroom: $getClassroom)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteClassroomGQL extends Apollo.Mutation<DeleteClassroomMutation, DeleteClassroomMutationVariables> {
  document = DeleteClassroomDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAssignmentWidgetInfoDocument = gql`
  query GetAssignmentWidgetInfo {
    getMyClassroomsAssignmentManagers {
      _id
      assignment {
        name
        type
        tags
      }
      classroom {
        _id
        name
      }
      creationDate
      defaults {
        allowLate
        dueDate
        maxAttempts
        startDate
      }
      submissions {
        _id
        isFinished
        submissions {
          _id
          isComplete
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAssignmentWidgetInfoGQL extends Apollo.Query<GetAssignmentWidgetInfoQuery, GetAssignmentWidgetInfoQueryVariables> {
  document = GetAssignmentWidgetInfoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClassroomsWidgetInfoDocument = gql`
  query GetClassroomsWidgetInfo {
    getMyClassrooms {
      _id
      numStudents
      name
      color
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetClassroomsWidgetInfoGQL extends Apollo.Query<GetClassroomsWidgetInfoQuery, GetClassroomsWidgetInfoQueryVariables> {
  document = GetClassroomsWidgetInfoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllAssignmentsDocument = gql`
  query GetAllAssignments {
    getManyAssignmentsByDto(dto: { isActive: true }) {
      _id
      aId
      awsLink
      creationDate
      name
      type
      specialRequirement
      tags
      questions {
        qId
        number
        section {
          sId
          sectionType
          name
          weight
        }
        text
        screenshotUrl
        standards {
          state
          code
          description
        }
        type
        answerType
        acceptedAnswers
        possibleAnswerChoices
        weight
      }
      version {
        major
        minor
        hotfix
        releaseDate
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllAssignmentsGQL extends Apollo.Query<GetAllAssignmentsQuery, GetAllAssignmentsQueryVariables> {
  document = GetAllAssignmentsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAssignmentManagersByClassroomDocument = gql`
  query GetAssignmentManagersByClassroom($dto: GetAssignmentManagersDto!) {
    getManyAssignmentManagers(dto: $dto) {
      _id
      assignment {
        _id
        name
        awsLink
        iconUrl
        aId
        isAutoGradable
        questions {
          qId
          number
          section {
            sId
            name
            weight
          }
          text
          screenshotUrl
          standards {
            state
            code
            description
          }
          type
          answerType
          acceptedAnswers
          weight
        }
        version {
          major
          minor
          hotfix
          releaseDate
        }
      }
      classroom {
        _id
        name
        teachers {
          _id
          email
        }
      }
      defaults {
        dueDate
        startDate
        maxAttempts
        allowLate
      }
      isClosed
      creationDate
      submissions {
        _id
        user {
          _id
          email
        }
        controls {
          allowLate
          startDate
          dueDate
          maxAttempts
        }
        isFinished
        isOverride
        needsReview
        creationDate
        completionDate
        submissions {
          _id
          isComplete
          needsReview
          attempt
          grade
          creationDate
          completionDate
          assignment {
            _id
          }
          answers {
            _id
            question {
              qId
              weight
              section {
                sId
                weight
                name
              }
            }
            grade
            needsReview
            answer
            answerType
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAssignmentManagersByClassroomGQL extends Apollo.Query<GetAssignmentManagersByClassroomQuery, GetAssignmentManagersByClassroomQueryVariables> {
  document = GetAssignmentManagersByClassroomDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClassroomSummariesDocument = gql`
  query GetClassroomSummaries {
    getMyClassrooms {
      _id
      name
      color
      classCode
      numStudents
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetClassroomSummariesGQL extends Apollo.Query<GetClassroomSummariesQuery, GetClassroomSummariesQueryVariables> {
  document = GetClassroomSummariesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetRecommendedStudentActivitiesDocument = gql`
  query GetRecommendedStudentActivities {
    getRecommendedStudentActivities {
      _id
      name
      assignment {
        _id
      }
      courseName
      unitName
      description
      resourceCategory
      reviewResource
      skillConnectionTags
      isActive
      links {
        name
        link
        btnType
      }
      units {
        _id
        code
        courses {
          _id
          code
          name
        }
        name
      }
      recommendationScore
      estimatedTime
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetRecommendedStudentActivitiesGQL extends Apollo.Query<GetRecommendedStudentActivitiesQuery, GetRecommendedStudentActivitiesQueryVariables> {
  document = GetRecommendedStudentActivitiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ResendVerificationDocument = gql`
  mutation ResendVerification {
    resendVerification
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ResendVerificationGQL extends Apollo.Mutation<ResendVerificationMutation, ResendVerificationMutationVariables> {
  document = ResendVerificationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UnlockCurrentUserSciOpsLvlsDocument = gql`
  mutation UnlockCurrentUserSciOpsLvls($getUserDto: GetUserDto!, $lvlKey: String!) {
    unlockSciOpsLvls(getUserDto: $getUserDto, lvlKey: $lvlKey) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UnlockCurrentUserSciOpsLvlsGQL extends Apollo.Mutation<UnlockCurrentUserSciOpsLvlsMutation, UnlockCurrentUserSciOpsLvlsMutationVariables> {
  document = UnlockCurrentUserSciOpsLvlsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateClassroomDocument = gql`
  mutation UpdateClassroom($getClassroom: GetClassroomDto!, $updateClassroom: UpdateClassroomDto!, $districtSemesterId: String!) {
    updateClassroom(getClassroom: $getClassroom, updateClassroom: $updateClassroom, districtSemesterId: $districtSemesterId) {
      _id
      name
      subject
      period
      color
      startDate
      endDate
      classCode
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateClassroomGQL extends Apollo.Mutation<UpdateClassroomMutation, UpdateClassroomMutationVariables> {
  document = UpdateClassroomDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAssignmentManagerDefaultsDocument = gql`
  mutation updateAssignmentManagerDefaults($amId: String!, $controls: AssignmentControlsDto!, $isOverride: Boolean) {
    updateAssignmentManagerDefaults(assignmentManagerId: $amId, controls: $controls, isOverride: $isOverride) {
      _id
      classroom {
        _id
      }
      defaults {
        allowLate
        maxAttempts
        startDate
        dueDate
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAssignmentManagerDefaultsGQL extends Apollo.Mutation<UpdateAssignmentManagerDefaultsMutation, UpdateAssignmentManagerDefaultsMutationVariables> {
  document = UpdateAssignmentManagerDefaultsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetActivityCoursesSearchFiltersDocument = gql`
  query GetActivityCoursesSearchFilters {
    getActivityCourses {
      _id
      name
      code
      units {
        _id
        name
        isActive
        pacingGuide
        inProgress
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetActivityCoursesSearchFiltersGQL extends Apollo.Query<GetActivityCoursesSearchFiltersQuery, GetActivityCoursesSearchFiltersQueryVariables> {
  document = GetActivityCoursesSearchFiltersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCourseActivitiesDocument = gql`
  query GetCourseActivities($getActivityCourseDto: GetActivityCourseDto!) {
    getActivityCourse(getActivityCourseDto: $getActivityCourseDto) {
      _id
      name
      code
      units {
        _id
        name
        code
        isActive
        pacingGuide
        inProgress
        stateStandards {
          abbr
          name
          standards {
            code
            description
            subStandards {
              code
              description
            }
          }
        }
        activities {
          _id
          name
          unitName
          courseName
          resourceCategory
          reviewResource
          skillConnectionTags
          isActive
          description
          thumbnail
          creationDate
          isAdvanced
          links {
            _id
            name
            link
            btnType
          }
          assignment {
            _id
            name
          }
          onlineActivity {
            aId
            name
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetCourseActivitiesGQL extends Apollo.Query<GetCourseActivitiesQuery, GetCourseActivitiesQueryVariables> {
  document = GetCourseActivitiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetStudentActivitySearchDocument = gql`
  query GetStudentActivitySearch($searchDto: GetStudentActivitiesSearchDto!) {
    getStudentActivitySearch(searchDto: $searchDto) {
      score
      _id
      name
      unitName
      courseName
      resourceCategory
      reviewResource
      skillConnectionTags
      isActive
      description
      isAdvanced
      creationDate
      lastUpdated
      links {
        name
        link
        btnType
      }
      assignment {
        _id
        name
        type
        awsLink
      }
      onlineActivity {
        aId
        name
      }
      unitPositionIndex
      estimatedTime
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetStudentActivitySearchGQL extends Apollo.Query<GetStudentActivitySearchQuery, GetStudentActivitySearchQueryVariables> {
  document = GetStudentActivitySearchDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetTeacherClassroomsForAssigningLessonsDocument = gql`
  query GetTeacherClassroomsForAssigningLessons {
    getMyClassrooms {
      _id
      name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTeacherClassroomsForAssigningLessonsGQL extends Apollo.Query<GetTeacherClassroomsForAssigningLessonsQuery, GetTeacherClassroomsForAssigningLessonsQueryVariables> {
  document = GetTeacherClassroomsForAssigningLessonsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SubmitActivityFeedbackDocument = gql`
  mutation SubmitActivityFeedback($userId: String!, $createActivityRatingDto: CreateActivityRatingDto!, $activityId: String!) {
    submitActivityRating(userId: $userId, createActivityRatingDto: $createActivityRatingDto, activityId: $activityId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SubmitActivityFeedbackGQL extends Apollo.Mutation<SubmitActivityFeedbackMutation, SubmitActivityFeedbackMutationVariables> {
  document = SubmitActivityFeedbackDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BulkAddStudentsDocument = gql`
  mutation BulkAddStudents($createStudentsDto: CreateStudentsDto!, $creationType: UserCreationType!) {
    bulkCreateStudents(createStudentsDto: $createStudentsDto, creationType: $creationType) {
      _id
      email
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BulkAddStudentsGQL extends Apollo.Mutation<BulkAddStudentsMutation, BulkAddStudentsMutationVariables> {
  document = BulkAddStudentsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateNewClassroomDocument = gql`
  mutation CreateNewClassroom($createClassroom: CreateClassroomDto!, $districtSemesterId: String!) {
    createClassroom(createClassroom: $createClassroom, districtSemesterId: $districtSemesterId) {
      _id
      period
      subject
      startDate
      endDate
      name
      color
      classCode
      numStudents
      numTeachers
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateNewClassroomGQL extends Apollo.Mutation<CreateNewClassroomMutation, CreateNewClassroomMutationVariables> {
  document = CreateNewClassroomDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetTeachersClassroomsSummaryDocument = gql`
  query GetTeachersClassroomsSummary {
    getMyClassrooms {
      _id
      period
      subject
      startDate
      endDate
      name
      color
      classCode
      numStudents
      numTeachers
      schoolDistrictSemester
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTeachersClassroomsSummaryGQL extends Apollo.Query<GetTeachersClassroomsSummaryQuery, GetTeachersClassroomsSummaryQueryVariables> {
  document = GetTeachersClassroomsSummaryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAssignmentManagerDetailsDocument = gql`
  query GetAssignmentManagerDetails($assignmentManagerId: String!) {
    getAssignmentManager(id: $assignmentManagerId) {
      _id
      assignment {
        _id
        name
        specialRequirement
        tags
        type
        description
        awsLink
        isAutoGradable
        questions {
          acceptedAnswers
          answerType
          number
          possibleAnswerChoices
          qId
          screenshotUrl
          section {
            name
            sectionType
            sId
            weight
          }
          standards {
            code
            description
            state
          }
          text
          type
          weight
        }
      }
      creationDate
      defaults {
        allowLate
        dueDate
        maxAttempts
        startDate
      }
      isClosed
      submissions {
        _id
        completionDate
        controls {
          allowLate
          dueDate
          maxAttempts
          startDate
        }
        creationDate
        isFinished
        isOverride
        needsReview
        status
        displayStatus
        highestGrade
        isStatusGradable
        submissions {
          _id
          attempt
          completionDate
          creationDate
          grade
          isComplete
          needsReview
          answers {
            _id
            answer
            answerType
            grade
            needsReview
            question {
              qId
              type
              number
              standards {
                code
                state
              }
            }
          }
        }
        user {
          _id
          email
          profile {
            character
            pictureUrl {
              img
              color
            }
          }
        }
      }
      classroom {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAssignmentManagerDetailsGQL extends Apollo.Query<GetAssignmentManagerDetailsQuery, GetAssignmentManagerDetailsQueryVariables> {
  document = GetAssignmentManagerDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClassroomAssignmentManagersDocument = gql`
  query GetClassroomAssignmentManagers($classroomId: String!) {
    getManyAssignmentManagers(dto: { classroom: $classroomId }) {
      _id
      assignment {
        _id
        aId
        awsLink
        name
        tags
        type
        iconUrl
        isAutoGradable
      }
      classroom {
        _id
      }
      creationDate
      defaults {
        allowLate
        dueDate
        maxAttempts
        startDate
      }
      isClosed
      submissions {
        _id
        isFinished
        isOverride
        status
        displayStatus
        isStatusGradable
        highestGrade
        controls {
          dueDate
          allowLate
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetClassroomAssignmentManagersGQL extends Apollo.Query<GetClassroomAssignmentManagersQuery, GetClassroomAssignmentManagersQueryVariables> {
  document = GetClassroomAssignmentManagersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClassroomAssignmentSummaryDocument = gql`
  query GetClassroomAssignmentSummary($classroomDto: GetClassroomDto!) {
    getClassroomAssignmentsSummary(classroomDto: $classroomDto) {
      classroom {
        _id
      }
      userAssignmentSummary {
        averageGrade
        numCompleted
        totalNumOfAssigned
        user {
          _id
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetClassroomAssignmentSummaryGQL extends Apollo.Query<GetClassroomAssignmentSummaryQuery, GetClassroomAssignmentSummaryQueryVariables> {
  document = GetClassroomAssignmentSummaryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClassroomStudentsSciOpsDetailsDocument = gql`
  query GetClassroomStudentsSciOpsDetails($getClassroom: GetClassroomDto!) {
    getClassroom(getClassroom: $getClassroom) {
      students {
        _id
        email
        profile {
          character
          pictureUrl {
            img
            color
          }
        }
        sciopsData {
          firstPlayed
          lastLogin
          numLogins
          totalTime
          gameSaveData {
            settings {
              DifficultySetting
            }
            levels {
              campaign {
                completionHistory {
                  dateCompleted
                  difficulty
                  levelKey
                  totalStarsEarned
                  totalTimeToCompleteSeconds
                  totalTurnsToComplete
                }
                highestLevel
                levels {
                  levelKey
                  maxStars
                  numComplete
                  numEfficientStarsEarned
                  numPlays
                  numSurvivabilityStarsEarned
                  numTurnStarsEarned
                }
              }
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetClassroomStudentsSciOpsDetailsGQL extends Apollo.Query<GetClassroomStudentsSciOpsDetailsQuery, GetClassroomStudentsSciOpsDetailsQueryVariables> {
  document = GetClassroomStudentsSciOpsDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetClassroomSurveyResultsStatsDocument = gql`
  query GetClassroomSurveyResultsStats($classroomId: String!) {
    getSurveyResults(getSurveyResultsDto: { classroomId: $classroomId }) {
      _id
      surveyId
      completed
      completedDate
      user {
        _id
        email
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetClassroomSurveyResultsStatsGQL extends Apollo.Query<GetClassroomSurveyResultsStatsQuery, GetClassroomSurveyResultsStatsQueryVariables> {
  document = GetClassroomSurveyResultsStatsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetTeacherClassroomDetailsDocument = gql`
  query GetTeacherClassroomDetails($getClassroom: GetClassroomDto!) {
    getClassroom(getClassroom: $getClassroom) {
      _id
      name
      subject
      period
      color
      classCode
      startDate
      endDate
      numStudents
      numTeachers
      schoolDistrictSemester
      group {
        _id
        isSurveysOn
      }
      users {
        role
        user {
          _id
          email
          profile {
            firstName
            lastName
            character
            pictureUrl {
              img
              color
            }
          }
          analytics {
            lastLogin
            numLogins
          }
          ssoProfile {
            googleSub
          }
          classLink {
            id
          }
          clever {
            id
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTeacherClassroomDetailsGQL extends Apollo.Query<GetTeacherClassroomDetailsQuery, GetTeacherClassroomDetailsQueryVariables> {
  document = GetTeacherClassroomDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveStudentFromClassroomDocument = gql`
  mutation RemoveStudentFromClassroom($getClassroom: GetClassroomDto!, $getUser: GetUserDto!) {
    removeStudent(getClassroom: $getClassroom, getUser: $getUser) {
      _id
      numStudents
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveStudentFromClassroomGQL extends Apollo.Mutation<RemoveStudentFromClassroomMutation, RemoveStudentFromClassroomMutationVariables> {
  document = RemoveStudentFromClassroomDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateTeacherClassroomDocument = gql`
  mutation UpdateTeacherClassroom($getClassroomDto: GetClassroomDto!, $updateClassroomDto: UpdateClassroomDto!, $districtSemesterId: String!) {
    updateClassroom(getClassroom: $getClassroomDto, updateClassroom: $updateClassroomDto, districtSemesterId: $districtSemesterId) {
      _id
      period
      subject
      startDate
      endDate
      name
      color
      classCode
      numStudents
      numTeachers
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateTeacherClassroomGQL extends Apollo.Mutation<UpdateTeacherClassroomMutation, UpdateTeacherClassroomMutationVariables> {
  document = UpdateTeacherClassroomDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TeacherUpdateStudentPasswordDocument = gql`
  mutation TeacherUpdateStudentPassword($getUser: GetUserDto!, $newPassword: String!) {
    updateUserPassword(getUser: $getUser, newPassword: $newPassword) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TeacherUpdateStudentPasswordGQL extends Apollo.Mutation<TeacherUpdateStudentPasswordMutation, TeacherUpdateStudentPasswordMutationVariables> {
  document = TeacherUpdateStudentPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteMyAccountDocument = gql`
  mutation DeleteMyAccount($userId: GetUserDto!) {
    removeUser(userId: $userId) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteMyAccountGQL extends Apollo.Mutation<DeleteMyAccountMutation, DeleteMyAccountMutationVariables> {
  document = DeleteMyAccountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserProfilePictureDocument = gql`
  mutation UpdateUserProfilePicture($getUser: GetUserDto!, $userProfilePicture: ProfilePicImg!) {
    updateUserProfile(getUser: $getUser, userProfilePicture: { color: BLUE, img: $userProfilePicture }) {
      _id
      profile {
        pictureUrl {
          img
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserProfilePictureGQL extends Apollo.Mutation<UpdateUserProfilePictureMutation, UpdateUserProfilePictureMutationVariables> {
  document = UpdateUserProfilePictureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserEduProfileDocument = gql`
  mutation UpdateUserEduProfile($getUser: GetUserDto!, $userEduProfileDto: UserEduProfileDto!) {
    updateUserEduProfile(getUser: $getUser, userEduProfileDto: $userEduProfileDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserEduProfileGQL extends Apollo.Mutation<UpdateUserEduProfileMutation, UpdateUserEduProfileMutationVariables> {
  document = UpdateUserEduProfileDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SendTeacherPortalContactFormDocument = gql`
  mutation sendTeacherPortalContactForm($emailBody: SendTeacherPortalContactDto!) {
    sendTeacherPortalContactForm(emailBody: $emailBody)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SendTeacherPortalContactFormGQL extends Apollo.Mutation<SendTeacherPortalContactFormMutation, SendTeacherPortalContactFormMutationVariables> {
  document = SendTeacherPortalContactFormDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChangeUserRoleDocument = gql`
  mutation changeUserRole($roleAccessCode: String!, $userRole: UserRole!, $userProfileDto: UserProfileDto) {
    changeUserRole(roleAccessCode: $roleAccessCode, userRole: $userRole, userProfileDto: $userProfileDto) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ChangeUserRoleGQL extends Apollo.Mutation<ChangeUserRoleMutation, ChangeUserRoleMutationVariables> {
  document = ChangeUserRoleDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
